import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { imageFileProcessing } from "src/app/utils/image-processing-utils";
import Swal from "sweetalert2";
import { VehicleService } from "../service/vehicle.service";
import { CompanyService } from "../../companies/service/company.service";

@Component({
  selector: "app-add-vehicle",
  templateUrl: "./add-vehicle.component.html",
  styleUrls: ["./add-vehicle.component.css"],
})
export class AddVehicleComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  vehicleId;
  vehicleImageFile;
  vehicleImageUrl;
  
  companies;
  selectedCompanies;
  dropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'displayName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  vehicle;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private vehicleService: VehicleService,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
  ) {}

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.paramMap.get("id");

    this.formGroup = this.fb.group({
      name: ["", Validators.required],
      capacity: ["", Validators.required],
      status: ["active", Validators.required],
      newProfilePicture: [null, Validators.required],
      type: ["", Validators.required]
    });

    if (this.vehicleId) {
      this.getVehicle();
    } else {
      this.getCompanies();
    }
  }

  getCompanies() {
    const queryParams = {
      status: 'active'
    }

    this.companyService
      .getCompanies(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.companies = data;
          if (this.vehicle && this.vehicle.companies && this.vehicle.companies.length) {
            this.loadCompanies(this.vehicle.companies);
          }
        }
      });
  }

  getVehicle() {
    this.vehicleService.getVehicleById(this.vehicleId).subscribe((data) => {
      if (data) {
        this.vehicle = data;
        this.getCompanies();
        this.vehicleImageUrl = data.profileImageURL;
        this.formGroup.patchValue(data);
        this.formGroup.controls.newProfilePicture.clearValidators();
        this.formGroup.controls.newProfilePicture.updateValueAndValidity();
      }
    });
  }

  submit() {
    this.isSubmitted = true;

    console.log("Form Values: ", this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    if (this.vehicleId) {
      if (!this.formGroup.value.newProfilePicture) {
        delete this.formGroup.value.newProfilePicture;
      }
    }

    const payload = this.formGroup.value;
    const companies = this.selectedCompanies ? this.selectedCompanies.map(company => company._id) : undefined;
    if (companies && companies.length) {
      payload['companies'] = companies;
    } else {
      if (this.vehicleId) {
        payload['companies'] = undefined;
      }
    }

    console.log("Valid Form: ", payload);
    this.vehicleService
      .saveVehicle(this.vehicleId, payload)
      .subscribe((data) => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then((data) => {
            this.router.navigateByUrl("/vehicles");
          });
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  processImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.vehicleImageFile = result.file;
        this.vehicleImageUrl = result.src;
        this.formGroup.patchValue({
          newProfilePicture: this.vehicleImageFile,
        });
      } else {
        this.clearImage();
      }
    });
  }

  clearImage() {
    this.vehicleImageFile = null;
    this.vehicleImageUrl = "";
    this.formGroup.patchValue({
      newProfilePicture: null,
    });

    if (this.vehicleId) {
      this.formGroup.controls.newProfilePicture.setValidators([
        Validators.required,
      ]);
      this.formGroup.controls.newProfilePicture.updateValueAndValidity();
    }
  }

  loadCompanies(companies) {
    this.selectedCompanies = this.companies.filter(company => companies.includes(company._id));
    console.log('selected companies: ', this.selectedCompanies);
  }

  get form() {
    return this.formGroup.controls;
  }
}
