import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';
import { DriverService } from '../../drivers/service/driver.service';
import { FleetService } from '../../fleets/service/fleet.service';
import { RideService } from '../../rides/service/ride.service';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-assign-ride-modal',
  templateUrl: './assign-ride-modal.component.html',
  styleUrls: ['./assign-ride-modal.component.css']
})
export class AssignRideModalComponent implements OnInit {
  @Input() data: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  search;
  drivers;
  rideDetails;
  lat = 0;
  lng = 0;
  user;
  selectedDriver;
  rideId;
  idx;
  ride;
  isSubmitted = false;
  assignedDriver;
  cancelRideModalObj;
  previousDriver;
  currentDriver;
  currentPassenger;
  timeZone;

  constructor(
    private driverService: DriverService,
    private rideService: RideService,
    private sharedDataService: SharedDataService,
    private fleetService: FleetService
  ) { }

  ngOnInit() {
    console.log('Modal Data: ', this.data);
    this.ride = this.data.ride;
    this.rideId = this.ride._id;
    this.idx = this.data.idx;

    this.getUser();
    this.getDrivers();
    this.getRideDetails();
  }

  getUser() {
    this.sharedDataService.getUser().subscribe((data) => {
      console.log("getUser ---> ", data);
      if (data) {
        this.user = data;
        this.timeZone = data.company && data.company.timeZone ? data.company.timeZone : '';
      } else {
        this.user = null;
      }
    });
  }

  getDrivers() {
    const queryParams = {
      status: 'active'
    }

    this.driverService
      .getDrivers(queryParams).
      subscribe(data => {
        this.drivers = data;
      });
  }

  getFleetDrivers(vehicle) {
    const payload = {
      status: "active",
      vehicle,
      purpose: "fleetwithdriver",
    };

    this.fleetService
    .getFleets(payload)
    .subscribe((data) => {
      console.log("data", data);
      if (data) {
        // this.fleets = data;
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }

  getRideDetails() {
    this.rideService
      .getRideDetails(this.rideId)
      .subscribe(data => {
        if (data) {
          this.rideDetails = data;
          if (this.rideDetails.driver) {
            this.selectedDriver = this.rideDetails.driver;
            this.assignedDriver = this.rideDetails.driver;

            this.previousDriver = {
              _id: this.rideDetails.driver._id,
              displayName: this.rideDetails.driver.displayName,
              contactNumber: this.rideDetails.driver.contactNumber
            }
          }

          if (this.rideDetails.passenger) {
            this.currentPassenger = {
              _id: this.rideDetails.passenger._id,
              displayName: this.rideDetails.passenger.displayName,
              profileImageURL: this.rideDetails.passenger.profileImageURL,
              contactNumber: this.rideDetails.passenger.contactNumber
            }
          }
        }
      });
  }

  onSelectDriver(driver) {
    this.selectedDriver = driver;
    this.currentDriver = {
      _id: driver._id,
      displayName: driver.displayName,
      contactNumber: driver.contactNumber
    }
  }

  assignDriver() {
    this.isSubmitted = true;

    if (!this.selectedDriver) {
      return;
    }

    let payload = {
      driver: this.selectedDriver._id,
      fleet: this.selectedDriver.fleet,
      status: 'assigned',

      prevDriver: this.previousDriver,
      currDriver: this.currentDriver,
      currPassenger: this.currentPassenger
    }

    if (this.data.section && this.data.section === 'missedcall') {
      payload['scheduleTime'] = moment().tz(this.timeZone).toISOString();
    }

    this.rideService
    .assignDriver(this.rideId, payload)
    .subscribe(data => {
      if (data) {
        let obj;
        if (this.assignedDriver) {
          obj = {
            driver: this.selectedDriver,
            idx: this.idx
          }
        } else {
          obj = {
            driver: null,
            idx: this.idx
          }
        }
        this.onClose.emit(obj);
        Swal.fire({
          title: "Alert",
          html: "Operation successful",
          icon: "success",
          confirmButtonColor: '#F1A303'
        }).then(data => {
        });
      }
    });
  }

  unassignDriver() {
    const payload = {
      driver: null,
      fleet: null,
      status: 'hanging',

      prevDriver: this.previousDriver,
      currDriver: null,
      currPassenger: this.currentPassenger
    }

    this.rideService
    .assignDriver(this.rideId, payload)
    .subscribe(data => {
      if (data) {
        const obj = {
          driver: null,
          idx: this.idx
        }
        this.onClose.emit(obj);
        Swal.fire({
          title: "Alert",
          html: "Operation successful",
          icon: "success",
          confirmButtonColor: '#F1A303'
        }).then(data => {
        });
      }
    });
  }

  cancelRide() {
    this.cancelRideModalObj = {
      rideId: this.rideId
    }

    // const payload = {
    //   status: 'cancelled'
    // }

    // this.rideService
    // .assignDriver(this.rideId, payload)
    // .subscribe(data => {
    //   if (data) {
    //     const obj = {
    //       driver: null,
    //       idx: this.idx
    //     }
    //     this.onClose.emit(obj);
    //     Swal.fire({
    //       title: "Alert",
    //       html: "Operation successful",
    //       icon: "success",
    //       confirmButtonColor: '#F1A303'
    //     }).then(data => {
    //       this.onClose.emit(null);
    //     });
    //   }
    // });
  }

  onRideCancel() {
    const obj = {
      driver: null,
      idx: this.idx
    }
    this.onClose.emit(obj);
    Swal.fire({
      title: "Alert",
      html: "Operation successful",
      icon: "success",
      confirmButtonColor: '#F1A303'
    }).then(data => {
      this.onClose.emit(null);
      this.cancelRideModalObj = null
    });
  }

  close() {
    console.log('Close Ride Assign');
    if (this.cancelRideModalObj) return;
    this.onClose.emit(null);
  }
}
