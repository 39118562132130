import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private user = new BehaviorSubject(null);
  private showMenu = new BehaviorSubject<boolean>(false);
  private supportUpdate = new BehaviorSubject<boolean>(false);

  constructor() { }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }

  saveUser(user) {
    this.user.next(user);
  }

  getMenuState() {
    return this.showMenu;
  }

  toggleMenu() {
    this.showMenu.next(!this.showMenu.value);
  }

  sendSupportUpdate() {
    this.supportUpdate.next(true);
  }

  getSupportUpdate() {
    return this.supportUpdate;
  }
}
