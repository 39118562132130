import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { urls } from "../../utils/url-utils";
import { errorHandl, setFormData } from "../../utils/network-utils";
import * as moment from "moment-timezone";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private user;
  roles = ["admin", "cuser", "cadmin", "rsa"];

  constructor(private http: HttpClient) { }

  staffComponentRoles = {
    faccess: "Facility Access",
    // fdispatcher: 'Dispatcher',
    // fcompany: 'Full Access',
    // fcredential: 'Credential'
  };

  accountStatus = {
    active: "Active",
    inactive: "InActive",
    pending: "Pending",
  };

  fleetAccountStatus = {
    active: "Active",
    inactive: "InActive",
  };

  driverComponentProfileRoles = {
    driver: "Driver",
    dispatcher: "Dispatcher",
  };

  driverAccountStatus = {
    active: "Active",
    inactive: "InActive",
  };

  signIn(payload): Observable<any> {
    return this.http
      .post<any>(urls.SERVER_API_URL + urls.SIGNIN_URL, payload)
      .pipe(catchError(errorHandl));
  }

  forgetPassword(payload): Observable<any> {
    return this.http
      .post<any>(urls.SERVER_API_URL + urls.FORGOT_URL, payload)
      .pipe(catchError(errorHandl));
  }

  verifyToken(): Observable<any> {
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.PROFILE_URL)
      .pipe(catchError(errorHandl));
  }

  verifyPassword(payload): Observable<any> {
    return this.http
      .post<any>(urls.SERVER_API_URL + urls.VERIFY_PASSWORD, payload)
      .pipe(catchError(errorHandl));
  }

  setToken(data: any): void {
    try {
      localStorage.setItem("token", JSON.stringify(data));
    } catch (e) {
      console.error("Error saving to localStorage", e);
    }
  }

  getToken(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error("Error getting data from localStorage", e);
      return null;
    }
  }

  removeToken(): void {
    try {
      localStorage.removeItem("token");
    } catch (e) {
      console.error("Error saving to localStorage", e);
    }
  }

  changePassword(payload) {
    return this.http
      .post<any>(urls.SERVER_API_URL + urls.PASSWORD, payload)
      .pipe(catchError(errorHandl));
  }

  updateProfile(payload) {
    return this.http
      .put<any>(urls.SERVER_API_URL + urls.USERS, payload)
      .pipe(catchError(errorHandl));
  }

  updateProfileImage(payload) {
    const formData = setFormData(payload);
    return this.http
      .post<any>(urls.SERVER_API_URL + urls.USER_PICTURE, formData)
      .pipe(catchError(errorHandl));
  }

  // getUserData() {
  //   return this.user;
  // }

  // CheckLoginValidation(user) {
  //   if (user == null) {
  //     this.user = null;
  //   } else {
  //     this.user = user;
  //     moment.tz.setDefault(user.timeZone);
  //   }
  // }

  getAllPanelRoles() {
    return this.roles;
  }

  fetchNotifications(queryParams?) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.NOTIFICATIONS_URL, { params })
      .pipe(catchError(errorHandl));
  }

  fetchSOSNotifications() {
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.SOS_NOTIFICATIONS_URL)
      .pipe(catchError(errorHandl));
  }

  updateSOSNotification(id) {
    return this.http
      .put<any>(urls.SERVER_API_URL + urls.SOS_NOTIFICATIONS_URL + '/' + id, { isRead: true })
      .pipe(catchError(errorHandl));
  }

  validateResetPassword(token, type): Observable<any> {
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.AUTH_RESET_URL + `${token}/${type}`)
      .pipe(catchError(errorHandl));
  }

  resetPassword(payload, token, type): Observable<any> {
    return this.http
      .post<any>(urls.SERVER_API_URL + urls.AUTH_RESET_URL + `${token}/${type}`, payload)
      .pipe(catchError(errorHandl));
  }
}
