import { Injectable } from "@angular/core";
import { Message } from "../model/message";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ToastService {
  messages = [];
  private subject: BehaviorSubject<any> = new BehaviorSubject(null);
  private ackSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor() {}

  getMessages(): Observable<any>  {
    return this.subject.asObservable();
  }

  sendMessage(content, style?, isSticky?,extras?) {
    let message: Message; 
    if(style){
       message =  new Message(content,style,isSticky,extras);
    }else{
       message =  new Message(content,style,isSticky,extras);
    }
    this.messages.push(message);

    this.subject.next(this.messages);
  }

  dismissMessage(messageKey) {
     this.messages.splice(messageKey, 1);
    this.subject.next(this.messages);
  }

  getAcknowledgement(): Observable<any>  {
    return this.ackSubject.asObservable();
  }

  sendAcknowledgement(notification) {
    this.ackSubject.next(notification);
  }
}
