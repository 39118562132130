import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { capitalize } from 'src/app/utils/common-utils';
import Swal from 'sweetalert2';
import { SupportService } from '../service/support.service';

@Component({
  selector: 'app-support-list',
  templateUrl: './support-list.component.html',
  styleUrls: ['./support-list.component.css']
})
export class SupportListComponent implements OnInit {
  search;
  queries;
  statusFilter = 'open';
  type;
  user;
  constructor(
    private supportService: SupportService,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
      }
    });
    this.type = this.activatedRoute.snapshot.url[1].path;
    this.getSupportQueries();
  }

  getSupportQueries() {
    const queryParams = {
      status: this.statusFilter,
      type: capitalize(this.type)
    }
    this.supportService
    .getSupportQueries(queryParams)
    .subscribe(data => {
      if (data) {
        this.queries = data;
      } else {
        this.queries = null;
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }

  onStatusChange() {
    this.getSupportQueries();
  }

  closeTicket(query) {
    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to close?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
    }).then((data) => {
      if (data.isConfirmed) {
        this.updateTicketStatus();
      }
    });
  }

  updateTicketStatus() {
    this.getSupportQueries();
  }
}
