export class Message {
  content: string;
  style: string;
  dismissed: boolean = false;
  isSticky: boolean = false;
  extras: Object

  constructor(content, style?, isSticky?, extras?) {
    this.content = content
    this.style = style || 'info'
    this.isSticky = isSticky
    this.extras = extras
  }

}