import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import * as moment from "moment-timezone";
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  search;
  notifications;
  timeZone = "America/New_York";
  today = moment().tz(this.timeZone).startOf("day");
  searchDate = this.today.toISOString();
  user;

  constructor(
    private authService: AuthService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.sharedDataService
      .getUser()
      .subscribe((data) => {
        if (data) {
          this.user = data;
          this.timeZone = data.company.timeZone;
          this.today = moment().tz(this.timeZone).startOf("day");
          this.searchDate = this.today.toISOString();
        }
      });
    this.getNotifications();
  }

  getNotifications() {
    const queryParams = {
      date: this.searchDate
    }

    this.authService
      .fetchNotifications(queryParams)
      .subscribe(data => {
        if (data) {
          this.notifications = data;
        }
      });
  }

  onSearchDateChange(evt) {
    this.searchDate = moment(evt).tz(this.timeZone, true).toISOString();
    this.getNotifications();
  }

}
