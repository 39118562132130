import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PassengerService } from '../service/passenger.service';

@Component({
  selector: 'app-add-guest',
  templateUrl: './add-guest.component.html',
  styleUrls: ['./add-guest.component.css']
})
export class AddGuestComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;

  @Output() onClose: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private passengerService: PassengerService,
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      displayName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      type: ['guest', Validators.required]
    });
  }

  submit() {
    this.isSubmitted = true;

    console.log('Form :', this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    console.log('Valid Form: ', this.formGroup.value);



    this.passengerService
      .savePassenger(null, this.formGroup.value)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(res =>{
            this.onClose.emit(data);
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  close() {
    this.onClose.emit(null);
  }

  get form() {
    return this.formGroup.controls;
  }
}
