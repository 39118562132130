import {
  Component,
  OnInit,
  Renderer2,
} from "@angular/core";
import {  NavigationStart, Router } from "@angular/router";
import { AuthService } from "./services/auth-service/auth.service";
import { SharedDataService } from "./services/shared-service/shared-data.service";
import { SocketService } from "./services/socket-service/socket-service.service";
import { urlToBase64 } from "./utils/common-utils";

declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  isLogIn = false;
  forgotPasswordFlag = false;
  isResetPassword = false;
  token;
  type;

  constructor(
    private auth: AuthService,
    private sharedData: SharedDataService,
    private renderer: Renderer2,
    private socketService: SocketService,
    private router: Router
  ) {
    this.routerListener();
  }

  ngOnInit() {
    // this.verifyToken();
    // urlToBase64('https://angelridenetwork.s3.amazonaws.com/driveradditionalinfo/1646201815191-37f7c3e9-f2b3-4bf2-9253-3667ada8049d.jpg').then(data => {
    //   console.log(data);
    // });
    // urlToBase64('https://images.pexels.com/photos/11141607/pexels-photo-11141607.jpeg?cs=srgb&dl=pexels-maria-luiza-melo-11141607.jpg&fm=jpg').then(data => {
    //   console.log(data);
    // });
 
  }

 

  // verifyToken() {
  //   if (!this.auth.getToken("token")) return;
  //   this.auth.verifyToken().subscribe((data) => {
  //     if (!data && !this.auth.getToken("token")) {
  //       this.socketService.disconnectSocket();
  //       this.isLogIn = false;
  //       this.sharedData.saveUser(null);
  //       this.router.navigateByUrl("signin");
  //     } else {
  //       this.isLogIn = true;
  //       if (data != null) {
  //         this.socketService.connectSocket();
  //         this.sharedData.saveUser(data);
  //       }
  //     }
  //   });
  // }

  routerListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log(">>>>>> Navi", event.url);
        if (this.auth.getToken("token")) {
          this.isLogIn = true;
          if (
            event.url === "/signin" ||
            event.url === "/forgot-password" ||
            event.url.startsWith("/reset-password")
          ) {
            this.router.navigateByUrl("/");
          }
        } else if (!this.auth.getToken("token")) {
          this.isLogIn = false;
          if ( event.url.startsWith("/reset-password")) {
            this.isResetPassword = true;
            this.forgotPasswordFlag = false;
          } else if (event.url === "/forgot-password") {
            this.forgotPasswordFlag = true;
            this.isResetPassword = false;
          } else if (event.url === "/signin") {
            this.forgotPasswordFlag = false;
            this.isResetPassword = false;
          } else {
            this.forgotPasswordFlag = false;
            this.isResetPassword = false;
            this.router.navigateByUrl("/signin");
          }
        } else {
          this.isLogIn = true;
        }
      }
    });
  }
}


