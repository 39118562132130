import { Injectable } from "@angular/core";
import { MainMenuModel, SubMenuModel } from "./menu-model";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  menu = {};
  roles = "*";
  company = "company";

  constructor() { }

  addMenuItem(menuObj: MainMenuModel): void {
    if (menuObj.roles.includes(this.roles) || menuObj.roles.includes("*")) {
      menuObj.subMenu = [];
      this.menu[menuObj.state] = menuObj;
    }
  }

  addSubMenuItem(state: string, subMenuObj: SubMenuModel) {
    if (
      (this.menu[state] && subMenuObj.roles.includes(this.roles)) ||
      subMenuObj.roles.includes("*")
    ) {
      this.menu[state].subMenu.push(subMenuObj);
    }
  }

  getMenu(role): object {
    this.menu = {};
    // console.log(role);
    this.generateMenu(role);
    return this.menu;
  }

  generateMenu(role) {
    this.roles = role;

    // Admin Dashboard
    this.addMenuItem({
      url: "Dashboard",
      icon: this.getIcon("dashboard"),
      position: 1,
      title: "Dashboard",
      state: "dashboard",
      roles: ["admin"],
      subMenu: [],
      isActive: false,
    });

    // Dashboard
    this.addMenuItem({
      url: "home",
      icon: this.getIcon("dashboard"),
      position: 1,
      title: "Dashboard",
      state: "dashboard",
      roles: ["admin", "cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    // Rides
    this.addMenuItem({
      url: "/rides/dashboard",
      icon: this.getIcon("ride"),
      position: 2,
      title: "Rides",
      state: "rides",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    // Operations
    this.addMenuItem({
      url: "/operations",
      icon: this.getIcon("ride"),
      position: 2.1,
      title: "Operations",
      state: "operations",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    // Companies
    this.addMenuItem({
      url: "/companies",
      icon: this.getIcon("companies"),
      position: 2,
      title: "Companies",
      state: "companies",
      roles: ["admin"],
      subMenu: [],
      isActive: false,
    });

    // Drivers
    this.addMenuItem({
      url: "/drivers",
      icon: this.getIcon("drivers"),
      position: 3,
      title: "Drivers",
      state: "drivers",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    // Fleets
    this.addMenuItem({
      url: "/fleet",
      icon: this.getIcon("fleets"),
      position: 4,
      title: "Fleet",
      state: "fleets",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });
    this.addMenuItem({
      url: "/passengers",
      icon: this.getIcon("passenger"),
      position: 5,
      title: "Passengers",
      state: "passengers",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    // Vehicles
    this.addMenuItem({
      url: "/staff",
      icon: this.getIcon("staff"),
      position: 6,
      title: "Staff",
      state: "staff",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    // Vehicles
    this.addMenuItem({
      url: "/vehicles",
      icon: this.getIcon("vehicles"),
      position: 7,
      title: "Vehicle Types",
      state: "vehicles",
      roles: ["admin", "cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

       // Brokers
       this.addMenuItem({
        url: "/brokers",
        icon: this.getIcon("brokers"),
        position: 7,
        title: "Brokers",
        state: "brokers",
        roles: ["admin"],
        subMenu: [],
        isActive: false,
      });

    // Dynamic Fares
    this.addMenuItem({
      url: "/dynamic-fares",
      icon: this.getIcon("dynamic-fares"),
      position: 8,
      title: "Fares",
      state: "dynamic-fares",
      roles: ["cadmin", "cuser", "rsa"],
      subMenu: [],
      isActive: false,
    });

    // Dynamic Fares
    this.addMenuItem({
      url: "/zones",
      icon: this.getIcon("dynamic-fares"),
      position: 8.1,
      title: "Zones",
      state: "zones",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    // Vouchers
    this.addMenuItem({
      url: "/vouchers",
      icon: this.getIcon("voucher"),
      position: 8.2,
      title: "Vouchers",
      state: "vouchers",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });


    // Broadcast
    // this.addMenuItem({
    //   url: "/broadcast",
    //   icon: this.getIcon("broadcast"),
    //   position: 8,
    //   title: "Broadcast",
    //   state: "broadcast",
    //   roles: ["cadmin", "cuser"],
    //   subMenu: [],
    //   isActive: false,
    // });

    // Support
    // this.addMenuItem({
    //   url: "/support",
    //   icon: this.getIcon("support"),
    //   position: 9,
    //   title: "Support",
    //   state: "support",
    //   roles: ["cadmin", "cuser"],
    //   subMenu: [],
    //   isActive: false,
    // });

    // Earnings & KPIs
    this.addMenuItem({
      url: "/kpis/overview",
      icon: this.getIcon("kpis"),
      position: 9,
      title: "KPIs",
      state: "kpis",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    this.addMenuItem({
      url: "/support/drivers",
      icon: this.getIcon("support"),
      position: 9.5,
      title: "Support",
      state: "support",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    this.addMenuItem({
      url: "/notifications",
      icon: this.getIcon("notifications"),
      position: 10,
      title: "Notifications",
      state: "notifications",
      roles: ["cadmin", "cuser"],
      subMenu: [],
      isActive: false,
    });

    // this.addMenuItem({
    //   url: "/wallets",
    //   icon: this.getIcon("kpis"),
    //   position: 10,
    //   title: "Wallets",
    //   state: "wallets",
    //   roles: ["cadmin", "cuser"],
    //   subMenu: [],
    //   isActive: false,
    // });

    // Helping Material
    // this.addMenuItem({
    //   url: "/helping-material",
    //   icon: this.getIcon("helping-material"),
    //   position: 11,
    //   title: "Helping Material",
    //   state: "helping-material",
    //   roles: ["cadmin", "cuser"],
    //   subMenu: [],
    //   isActive: false,
    // });

    // Companies
    this.addMenuItem({
      url: "/quarters",
      icon: this.getIcon("companies"),
      position: 11,
      title: "Quarters",
      state: "quarters",
      roles: ["admin"],
      subMenu: [],
      isActive: false,
    });

    //SubMenues
    // assign
    this.addSubMenuItem("rides", {
      title: "Dashboard",
      roles: ["cadmin", "cuser"],
      url: "/rides/dashboard",
    });
    // this.addSubMenuItem("rides", {
    //   title: "Offered",
    //   roles: ["cadmin", "cuser"],
    //   url: "/rides/offered",
    // });
    this.addSubMenuItem("rides", {
      title: "Hanging",
      roles: ["cadmin", "cuser"],
      url: "/rides/hanging",
    });
    this.addSubMenuItem("rides", {
      title: "Hanging Stack",
      roles: ["cadmin", "cuser"],
      url: "/rides/hanging-stack",
    });
    this.addSubMenuItem("rides", {
      title: "Assigned",
      roles: ["cadmin", "cuser"],
      url: "/rides/assigned",
    });
    this.addSubMenuItem("rides", {
      title: "Upcoming",
      roles: ["cadmin", "cuser"],
      url: "/rides/upcoming",
    });
    // this.addSubMenuItem("rides", {
    //   title: "All Rides",
    //   roles: ["cadmin", "cuser"],
    //   url: "/rides/all",
    // });
    this.addSubMenuItem("rides", {
      title: "Inprogress",
      roles: ["cadmin", "cuser"],
      url: "/rides/inprogress",
    });
    this.addSubMenuItem("rides", {
      title: "Cancelled",
      roles: ["cadmin", "cuser"],
      url: "/rides/cancelled",
    });

    this.addSubMenuItem("rides", {
      title: "Finished",
      roles: ["cadmin", "cuser"],
      url: "/rides/finished",
    });
    this.addSubMenuItem("rides", {
      title: "New Ride",
      roles: ["cadmin", "cuser"],
      url: "/rides/new-ride",
    });

    // KPIs
    this.addSubMenuItem("kpis", {
      title: "Overview",
      roles: ["cadmin", "cuser"],
      url: "/kpis/overview",
    });
    this.addSubMenuItem("kpis", {
      title: "Drivers Earnings",
      roles: ["cadmin", "cuser"],
      url: "/kpis/earnings",
    });
    this.addSubMenuItem("kpis", {
      title: "Drivers Settlements",
      roles: ["cadmin", "cuser"],
      url: "/kpis/billings",
    });
    this.addSubMenuItem("kpis", {
      title: "Business Report",
      roles: ["cadmin", "cuser"],
      url: "/kpis/business-report",
    });
    // this.addSubMenuItem("kpis", {
    //   title: "Drivers Wallets",
    //   roles: ["cadmin", "cuser"],
    //   url: "/kpis/wallets",
    // });

    this.addSubMenuItem("support", {
      title: "Driver",
      roles: ["cadmin", "cuser"],
      url: "/support/driver",
    });

    this.addSubMenuItem("support", {
      title: "Passenger",
      roles: ["cadmin", "cuser"],
      url: "/support/passenger",
    });
  }

  getIcon(menu) {
    switch (menu) {
      case "dashboard":
        return `<svg class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>`;

      case "ride":
        return `<svg
        class="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-300"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
        />
      </svg>`;

      case "companies":
        return `<svg
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
        />
      </svg>`;
      case "drivers":
        return `<svg
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>`;
      case "passenger":
        return `<svg
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>`;
      case "fleets":
        return `<svg
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
        />
      </svg>`;
      case "dynamic-fares":
        return `<svg 
        xmlns="http://www.w3.org/2000/svg" 
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5"
        fill="none" 
        viewBox="0 0 24 24" 
        stroke="currentColor"
      >
        <path 
          stroke-linecap="round" 
          stroke-linejoin="round" 
          stroke-width="2" 
          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
      </svg>`;
      case "vehicles":
        return `<svg
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
        />
      </svg>`;
      case "staff":
        return `<svg xmlns="http://www.w3.org/2000/svg"
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5"
        fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
      </svg>`;
      case "broadcast":
        return `<svg
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
        />
      </svg>`;
      // case "support":
      //   return `<svg xmlns="http://www.w3.org/2000/svg"   class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      //   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
      // </svg>`;
      case "kpis":
        return `<svg xmlns="http://www.w3.org/2000/svg" 
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5" 
        fill="none" 
        viewBox="0 0 24 24" 
        stroke="currentColor" 
        stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>`;
      case "helping-material":
        return `<svg
        class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
        />
      </svg>`;
      case "notifications":
        return `<svg class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24" 
          xmlns="http://www.w3.org/2000/svg">
          <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
          </path>
        </svg>`
        case "support":
          return `<svg class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5" 
          xmlns="http://www.w3.org/2000/svg" 
          xmlns:xlink="http://www.w3.org/1999/xlink" 
          aria-hidden="true" role="img" 
          viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4c1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z"></path></svg>`

      default:
        return '<svg class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg>';
    }
  }
}
