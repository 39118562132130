import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
  name: "timezone"
})
export class TimezonePipe extends DatePipe implements PipeTransform {
  transform(
    value: string | Date,
    format: string = "MM/DD/YYYY HHmm",
    timezone: string = "America/New_York"
  ): string {
    const finalTimeFormat = moment(value)
    .tz(timezone)
    .format(format);
    return finalTimeFormat;
  }
}
