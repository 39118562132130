import { Component, OnInit } from '@angular/core';
import { RideService } from '../service/ride.service';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import * as moment from "moment-timezone";
import * as _ from "lodash";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hanging-stack',
  templateUrl: './hanging-stack.component.html',
  styleUrls: ['./hanging-stack.component.css']
})
export class HangingStackComponent implements OnInit {
  hangingRides = {};
  user;
  timeZone = "America/New_York";
  today = moment().tz(this.timeZone).startOf("day");
  scheduleTime = this.today.toISOString();
  selectedRideId;
  assignDriverModalObj;

  constructor(
    private rideService: RideService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe((data) => {
        if (data) {
          this.user = data;
          this.timeZone = data.company.timeZone;
          this.today = moment().tz(this.timeZone).startOf("day");
          this.scheduleTime = this.today.toISOString();
          this.getRides();
        }
      });
  }

  getRides() {
    const queryParams = {
      status: 'upcommingbuckets',
      scheduleTime: this.scheduleTime,
    };
    this.rideService.getHangingStackRides(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.hangingRides = _.groupBy(data, date => moment(date.scheduleTime).tz(this.timeZone).format('MM/DD/YYYY'));
          console.log(this.hangingRides);
        }
      });
  }

  openModal(rideId) {
    this.selectedRideId = rideId;
  }

  assignRide(ride, section?) {
    this.assignDriverModalObj = {
      ride: ride,
      idx: -1
    };
    if (section) {
      this.assignDriverModalObj['section'] = section;
    }
  }

  onCloseAssignModal(evt) {
    this.assignDriverModalObj = null;
    this.getRides();
  }

  isTodayOrTomorrow(date) {
    const today = moment().tz(this.timeZone).startOf('day');
    const inputDate = moment(date).tz(this.timeZone, true).startOf('day');
    
    if (inputDate.isSame(today, 'day')) {
        return "Today"; 
    } else if (inputDate.isSame(today.clone().add(1, 'day'), 'day')) {
        return "Tomorrow";
    } else {
        return inputDate.format('DD MMMM'); // Return formatted date if not today or tomorrow
    }
}
}
