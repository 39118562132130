import { Observable, Subject } from 'rxjs';
import Swal from 'sweetalert2';
export class FileSnippet {
  constructor(public src: string, public file: File) {}
}
export function imageFileProcessing(imageInput): Observable<any> {
  const file: File = imageInput.files[0];
  const reader = new FileReader();

  const selectedFileObserver = new Subject<any>();

  if (file && file.type && file.type.indexOf('image') !== -1) {
    reader.readAsDataURL(file);
  }else{
    showMessage('Your file is not supported.');
  }
  reader.addEventListener('load', (event: any) => {
    const selectedFile = new FileSnippet(event.target.result, file);
    if (
      selectedFile.file.type === 'image/jpeg' ||
      selectedFile.file.type === 'image/jpg' ||
      selectedFile.file.type === 'image/gif' ||
      selectedFile.file.type === 'image/png'
    ) {
      if (selectedFile.file.size <= 10 * 1000000) {
        console.log('zero');
        selectedFileObserver.next(selectedFile);

      } else {
        imageInput.value = '';
        selectedFileObserver.next(null);
        console.log('one');
        showMessage('Your file size exceeds the maximum limit.');
        // sweetAlert('Error', "Image size must be less than 10 MB", 'error', 'Ok');
      }
    } else {
      imageInput.value = '';
      selectedFileObserver.next(null);
      showMessage('Your file is not supported.');

      // sweetAlert('Error', "Image type unsupported", 'error', 'Ok');
    }
  });
  return selectedFileObserver;
}

const showMessage = (message) => {
  Swal.fire({
    title: "Alert",
    html: message,
    icon: "error",
    confirmButtonColor: '#F1A303'
  }).then((data) => {
  });
}

export function fileProcessing(imageInput): Observable<any> {
  const file: File = imageInput.files[0];
  const reader = new FileReader();

  const selectedFileObserver = new Subject<any>();
  reader.addEventListener('load', (event: any) => {
    const selectedFile = new FileSnippet(event.target.result, file);
    selectedFileObserver.next(selectedFile);
  });
  reader.readAsDataURL(file);
  return selectedFileObserver;
}
