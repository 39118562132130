import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient) { }

  getQueriesCount() {
    return this.http.get<any>(urls.SERVER_API_URL + urls.COUNT_QUERIES)
      .pipe(
        catchError(errorHandl)
      );
  }



  getSupportQueries(queryParams) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.SERVER_API_URL + urls.SUPPORT, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  saveSupportQuery(queryId, payload) {
    if (queryId) {
      // Edit
      return this.http.put<any>(urls.SERVER_API_URL + urls.SUPPORT +`/${queryId}`, payload)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      // Add
      return this.http.post<any>(urls.SERVER_API_URL + urls.SUPPORT, payload)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  getQueryById(queryId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.SUPPORT + `/${queryId}`)
      .pipe(
        catchError(errorHandl)
      );
  }

}
