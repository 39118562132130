import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { SocketService } from 'src/app/services/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import Swal from 'sweetalert2';
import { SupportService } from '../service/support.service';

@Component({
  selector: 'app-support-chat',
  templateUrl: './support-chat.component.html',
  styleUrls: ['./support-chat.component.css']
})
export class SupportChatComponent implements OnInit,OnDestroy {
  search;
  formGroup: FormGroup;
  isSubmitted = false;
  supportId;
  messages;
  profileImageUrl;
  ticketUser;
  ticket;
  user;
  private subscriptionA;
  
  @ViewChild("last", null) lastElement: ElementRef;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private socketService: SocketService,
    private sharedService: SharedDataService,
    private supportService: SupportService
  ) { }


  ngOnInit() {
    this.supportId = this.activatedRoute.snapshot.paramMap.get('id');

    this.formGroup = this.fb.group({
      message: ['', Validators.required],
    });

    this.socketService
      .addListener(Events.GET_QUERY_MESSAGES)
      .subscribe((data) => {
        console.log('Query Messages: ', data);
        if (data && data.queryMessages) {
          this.messages = data.queryMessages;
        }
      });

      this.subscriptionA =    this.socketService
      .addListener(Events.NEW_QUERY_MESSAGE)
      .subscribe((data) => {
        console.log('New Query Message: ', data);
        if (data && data.queryMessage && data.queryMessage.query === this.supportId) {
          this.messages.push(data.queryMessage);
          this.formGroup.patchValue({
            message: ''
          });
          this.lastElement.nativeElement.scrollIntoView({behavior: 'smooth'});
        }
      });

    this.getAllMessages();
    this.getUser();
    this.getTicketDetails();
  }

  getTicketDetails() {
    this.supportService
    .getQueryById(this.supportId)
    .subscribe(data => {
      if (data) {
        console.log('BYID', data);
        this.ticket = data;
        this.ticketUser = data.user;
        this.changeReadStatus();
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }

  getUser() {
    this.sharedService
    .getUser()
    .subscribe(data => {
      if (data) {
        this.user = data;
        this.profileImageUrl = data.profileImageURL;
      }
      console.log('Profile Image Url ', this.profileImageUrl);
    });
  }

  submit() {
    this.isSubmitted = true;
    console.log('Form: ', this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    console.log('Valid Form: ', this.formGroup.value);

    this.newQueryMessage(this.formGroup.controls.message.value);
  }

  getAllMessages() {
    this.socketService.emit(Events.GET_QUERY_MESSAGES, { queryId: this.supportId });
  }

  newQueryMessage(message) {
    this.socketService.emit(Events.NEW_QUERY_MESSAGE, { queryId: this.supportId, message });
  }

  get form() {
    return this.formGroup.controls;
  }

  closeTicket(ticket, status) {
    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to " + status +"?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: '#F1A303'
    }).then((data) => {
      if (data.isConfirmed) {
        let payload = {
          status: status
        }
        if (status === 'reopen') {
          payload['isRead'] = false; 
        }
        this.supportService
          .saveSupportQuery(ticket._id,payload)
          .subscribe(data => {
            console.log(data);
            if (data) {
              this.ticket = data;
              if (status === 'reopen') {
                this.updateSupportMenu();
              }
            }
          });
      }
    });
  }

  changeReadStatus() {
    console.log('changeReadStatus');
    if (this.ticket.isRead) return;

    const payload = {
      isRead: true
    }

    this.supportService
      .saveSupportQuery(this.ticket._id,payload)
      .subscribe(data => {
        if (data) {
          this.updateSupportMenu();
        }
      });
  }

  updateSupportMenu() {
    this.sharedService.sendSupportUpdate();
  }

  ngOnDestroy(): void {
    this.subscriptionA.unsubscribe();
  }
}




