import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { imageFileProcessing } from "src/app/utils/image-processing-utils";
import Swal from "sweetalert2";
import { CompanyService } from "../service/company.service";

@Component({
  selector: "app-add-company",
  templateUrl: "./add-company.component.html",
  styleUrls: ["./add-company.component.css"],
})
export class AddCompanyComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  companyId;
  profileImageUrl;
  profileImageFile;
  tz = "Asia/Karachi";
  isPasswordShow = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.companyId = this.activatedRoute.snapshot.paramMap.get('id');

    this.formGroup = this.fb.group({
      displayName: ["", Validators.required],
      contactNumber: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      extention: [""],
      address: ["", Validators.required],
      coords: [null, Validators.required],
      timeZone: ["", Validators.required],
      status: ["active", Validators.required],
      newProfilePicture: [null, Validators.required],
      isSmsAllowed: [false],
      isCompanyAlive: [false],
      dateFormat: ["", Validators.required],
      timeFormat: ["", Validators.required],
      focalPerson: this.fb.group({
        displayName: ['', Validators.required],
        contactNumber: ['', Validators.required]
      }),
    });

    if (this.companyId) {
      this.getCompany();
    }
  }

  getCompany() {
    this.companyService
      .getCompanyById(this.companyId)
      .subscribe(data => {
        if (data) {
          delete data.password;
          this.tz = data.timeZone;
          this.profileImageUrl = data.profileImageURL;
          this.formGroup.patchValue({
            ...data,
            coords: [0, 0]
          });

          // Password
          this.formGroup.controls.password.clearValidators();
          this.formGroup.controls.password.updateValueAndValidity();

          // Profile Image
          this.formGroup.controls.newProfilePicture.clearValidators();
          this.formGroup.controls.newProfilePicture.updateValueAndValidity();
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  submit() {
    this.isSubmitted = true;

    if (this.profileImageFile) {
      this.formGroup.patchValue({
        newProfilePicture: this.profileImageFile,
      });
    }

    if (this.formGroup.invalid) {
      console.log("Invalid Form: ", this.formGroup.value);
      return;
    }

    console.log("Form: ", this.formGroup.value);

    // Edit case checks
    if (this.companyId) {
      if (!this.formGroup.value.password) {
        delete this.formGroup.value.password;
      }

      if (!this.formGroup.value.newProfilePicture) {
        delete this.formGroup.value.newProfilePicture;
      }
    }

    this.companyService
      .saveCompany(this.companyId, this.formGroup.value)
      .subscribe((data) => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then((data) => {
            this.router.navigateByUrl("/companies");
          });
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  getAddress(place) {
    this.formGroup.patchValue({
      address: place.formatted_address,
      coords: [place.geometry.location.lng(), place.geometry.location.lat()],
    });

    console.log(this.formGroup);
  }

  onChangeTimezone(tz) {
    this.formGroup.patchValue({
      timeZone: tz,
    });
  }

  toggleSms() {
    this.formGroup.patchValue({
      isSmsAllowed: !this.formGroup.value.isSmsAllowed,
    });
  }

  processImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.profileImageFile = result.file;
        this.profileImageUrl = result.src;
      } else {
        this.profileImageFile = null;
        this.profileImageUrl = "";
      }
    });
  }

  get form() {
    return this.formGroup.controls;
  }
}
