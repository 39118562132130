import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { getRideStatusLabel } from "src/app/utils/common-utils";
import Swal from "sweetalert2";
import { FleetService } from "../../fleets/service/fleet.service";
import { PassengerService } from "../../passengers/service/passenger.service";
import { RideService } from "../../rides/service/ride.service";

@Component({
  selector: "app-fleet-modal",
  templateUrl: "./fleet-modal.component.html",
  styleUrls: ["./fleet-modal.component.css"],
})
export class FleetModalComponent implements OnInit {
  @Input() fleetId: string;
  @Input() rideId: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  lat = 0;
  lng = 0;
  fleet;
  ride;
  passenger;
  imgModalPayload;
  user;

  constructor(
    private fleetService: FleetService,
    private rideService: RideService,
    private passengerService: PassengerService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    console.log("rideId --> ", this.rideId);
    this.getFleetDetails();
    if (this.rideId) {
      this.getRideById();
    }

    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(data => {
        if (data) {
          this.user = data;
        }
      });
  }

  getFleetDetails() {
    this.fleetService.getFleetDetails(this.fleetId).subscribe((data) => {
      if (data) {
        console.log("Fleet: ", data);
        this.fleet = data;
      }
    }, err => {
      // Swal.fire({
      //   title: "Alert",
      //   html: 'Operation failed',
      //   icon: "error",
      //   confirmButtonColor: '#F1A303'
      // }).then((data) => {
      // });
    });
  }

  getRideById() {
    this.rideService.getRideById(this.rideId).subscribe((data) => {
      if (data) {
        console.log("Ride Details: ", data);
        this.ride = data;
        if (this.ride.passenger) {
          this.getPassenger(this.ride.passenger);
        }
      }
    }, err => {
      // Swal.fire({
      //   title: "Alert",
      //   html: 'Operation failed',
      //   icon: "error",
      //   confirmButtonColor: '#F1A303'
      // }).then((data) => {
      // });
    });
  }

  getPassenger(passengerId) {
    this.passengerService
      .getPassengerById(passengerId)
      .subscribe(data => {
        if (data) {
          this.passenger = data;
        }
      });
  }

  close() {
    this.onClose.emit(null);
  }
  
  getStatusLabel(status) {
    return getRideStatusLabel(status)
  }
}
