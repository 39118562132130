import { Component, OnInit } from '@angular/core';
import { EarningsService } from '../service/earnings.service';
import * as moment from "moment-timezone";
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';
import { DriverService } from '../../drivers/service/driver.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { singleDriverEarning } from 'src/app/utils/report/driver-earning';
import { generatePdf, getRideStatusLabel, splitString } from "src/app/utils/common-utils";

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: ['./earnings.component.css']
})
export class EarningsComponent implements OnInit {
  earnings;
  rides;
  timeZone = "America/New_York";
  today = moment().tz(this.timeZone).startOf("day");
  startDate = this.today.toISOString();
  user;
  drivers = [];
  selectedDriver;
  preDateRange = [
    moment().tz(this.timeZone).startOf('day').toISOString(),
    moment().tz(this.timeZone).endOf('day').toISOString()
  ];
  dateRange = [
    moment().tz(this.timeZone).startOf('day').toISOString(),
    moment().tz(this.timeZone).endOf('day').toISOString()
  ];
  search;

  constructor(
    private earningService: EarningsService,
    private driverService: DriverService,
    private sharedDataService: SharedDataService,
    private ngxLoader: NgxUiLoaderService,
  ) {}

  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
        this.timeZone = data.company.timeZone;
        this.today = moment().tz(this.timeZone).startOf("day");
        this.startDate = this.today.toISOString();
      }
    });

    // this.getEarnings();
    this.getDrivers();
  }

  getEarnings() {
    this.earnings = null;
    this.rides = null;

    let queryParams = {
      startDate: this.dateRange[0],
      endDate: this.dateRange[1]
    };

    if (this.selectedDriver) {
      queryParams["driver"] = this.selectedDriver._id;
    }

    this.earningService.getBusinesReport(queryParams).subscribe(
      (data) => {
        if (data && data.length) {
          console.log("Earnings: ", data);
          this.earnings = data[0];
          if (this.earnings.rides && this.earnings.rides.length) {
            this.rides = this.earnings.rides;
            this.rides.sort((a, b) => <any>new Date(b.scheduleTime) - <any>new Date(a.scheduleTime));
          }
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => {});
      }
    );
  }

  getDrivers() {
    const queryParams = {
      status: 'active'
    }

    this.driverService
      .getDrivers(queryParams).
      subscribe(data => {
        this.drivers = data;
      });
  }

  onSearchDateChange(evt) {
    console.log(evt);
    console.log(evt[0].toISOString(), evt[1].toISOString());
    // this.startDate = moment(evt).tz(this.timeZone, true).toISOString();
    this.dateRange = [moment(evt[0]).tz(this.timeZone,true).toISOString(), moment(evt[1]).tz(this.timeZone, true).toISOString()];
    console.log(this.dateRange);
    if (this.selectedDriver) {
      this.getEarnings();
    }
  }

  onDriverChange(driver) {
    if (driver) {
      this.selectedDriver = driver;
      this.getEarnings();
    }
  }

  async  onReportClick() {
    this.ngxLoader.start();
    console.log(this.selectedDriver);
    console.log(this.startDate);
    console.log(this.earnings );

    const detailsObject = await singleDriverEarning(this.selectedDriver,this.startDate, this.earnings  ).catch(
      data=>{
        this.ngxLoader.stop();
      }
    );
    console.log("data -----> ",JSON.stringify(detailsObject));
    generatePdf(detailsObject);
    this.ngxLoader.stop();
  }

  getSplittedString(str) {
    return splitString(str, '-');
  }
  getStatusLabel(status) {
    return getRideStatusLabel(status)
  }
}
