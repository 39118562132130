import { Component, OnInit } from '@angular/core';
import * as moment from "moment-timezone";
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { splitString } from 'src/app/utils/common-utils';
import Swal from 'sweetalert2';
import { EarningsService } from '../service/earnings.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  earnings;
  rides;
  timeZone = "America/New_York";
  today = moment().tz(this.timeZone).startOf("day");
  startDate = this.today.toISOString();
  user;
  search;

  constructor(
    private earningService: EarningsService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
        this.timeZone = data.company.timeZone;
        this.today = moment().tz(this.timeZone).startOf("day");
        this.startDate = this.today.toISOString();
      }
    });

    this.getEarnings();
  }

  getEarnings() {
    this.earnings = null;
    this.rides = null;

    const queryParams = {
      startDate: this.startDate,
    };
    this.earningService.getEarnings(queryParams).subscribe(
      (data) => {
        if (data && data.length) {
          console.log("Earnings: ", data);
          this.earnings = data[0];
          if (this.earnings.details && this.earnings.details.length) {
            this.rides = this.earnings.details;
            this.rides.sort((a, b) => <any>new Date(b.scheduleTime) - <any>new Date(a.scheduleTime));
          }
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => {});
      }
    );
  }

  onSearchDateChange(evt) {
    this.startDate = moment(evt).tz(this.timeZone, true).toISOString();
    this.getEarnings();
  }

  getSplittedString(str) {
    return splitString(str, '-');
  }
}
