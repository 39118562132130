import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { RideService } from '../../rides/service/ride.service';

@Component({
  selector: 'app-ride-cancel-modal',
  templateUrl: './ride-cancel-modal.component.html',
  styleUrls: ['./ride-cancel-modal.component.css']
})
export class RideCancelModalComponent implements OnInit {
  @Input() data: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onRideCancel: EventEmitter<any> = new EventEmitter();
  formGroup;
  isOtherSelected = false;
  isSubmitted = false;
  otherReason = '';
  rideId;

  constructor(
    private fb: FormBuilder,
    private rideService: RideService
  ) { }

  ngOnInit() {
    this.rideId = this.data.rideId;
    this.formGroup = this.fb.group({
      cancelReason: ["", Validators.required]
    });

    this.formGroup.get("cancelReason").valueChanges.subscribe(value => {
      if (value !== 'Other') {
        this.isOtherSelected = false;
      }
    })
  }

  submit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (this.isOtherSelected && !this.otherReason) {
      return;
    }

    if (this.formGroup.value.cancelReason === 'Other') {
      this.formGroup.patchValue({
        cancelReason: this.otherReason
      });
    }

    const payload = {
      status: 'cancelled',
      cancelReason: this.formGroup.value.cancelReason
    }

    this.rideService
    .assignDriver(this.rideId, payload)
    .subscribe(data => {
      if (data) {
        this.onRideCancel.emit(null);
      }
    });
  }

  handleChange(evt) {
    var target = evt.target;
    if (target.checked) {
      this.isOtherSelected = true;
    }
  }

  close() {
    console.log('Close Ride Cancel');
    this.onClose.emit(null);
  }

  get form() {
    return this.formGroup.controls;
  }
}
