import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../companies/service/company.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  totalCompanies = 0;
  totalActiveCompanies = 0;
  totalInactiveCompanies = 0;

  constructor(
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.getActiveCompanies();
    this.getInactivCompanies();
  }

  getActiveCompanies() {
    const queryParams = {
      status: 'active'
    }
    
    this.companyService
      .getCompanies(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.totalActiveCompanies = data.length
        }
      });
  }

  getInactivCompanies() {
    const queryParams = {
      status: 'inactive'
    }
    
    this.companyService
      .getCompanies(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.totalInactiveCompanies = data.length
        }
        this.totalCompanies = this.totalActiveCompanies + this.totalInactiveCompanies;
      });
  }
}
