import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { ConfirmedValidator } from "src/app/utils/common-utils";
import Swal from "sweetalert2";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  isNewPasswordShow = false;
  isCMPasswordShow = false;
  token;
  type;
  validationData = undefined;
  isPasswordReset = false;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    const urlArray = this.router.url.split("/");
    if (urlArray.length === 4) {
      this.token = urlArray[2];
      this.type = urlArray[3];
      this.getValidatePasswordToken();
    } else {
      this.router.navigateByUrl("/signin");
    }

    this.formGroup = this.fb.group({
      newPassword: ["", [Validators.required]],
      verifyPassword: ["", Validators.required],
    }, {
      validator: ConfirmedValidator('newPassword', 'verifyPassword')
    });
  }

  get form() {
    return this.formGroup.controls;
  }

  submit() {
    this.isSubmitted = true;
    if (this.formGroup.invalid) {
      return;
    }

    this.authService.resetPassword(this.formGroup.value,this.token, this.type).subscribe(
      (data) => {
        if (data) {
          this.isPasswordReset = true;
          Swal.fire({
            title: "Alert",
            html: "Your password has been reset successfully",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data =>{
            this.router.navigateByUrl("/signin");
          })
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation Fail",
          icon: "error",
        }).then(data =>{
        })
      }
    );
  }

  getValidatePasswordToken() {
    this.authService.validateResetPassword(this.token, this.type).subscribe(
      (data) => {
        console.log(data);
        this.validationData = data;
      },
      (err) => {
        this.validationData = null;
        Swal.fire({
          title: "Alert",
          html: "Invalid Token",
          icon: "error",
        }).then((data) => {});
      }
    );
  }

}
