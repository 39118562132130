import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import {
  getFleetIcons,
  getFleetStatusColor,
  getFleetStatusColorRipple,
  mapStyles,
} from "src/app/utils/common-utils";
import { FleetService } from "../fleets/service/fleet.service";
import { VehicleService } from "../vehicles/service/vehicle.service";
import { LatLngBounds, MapsAPILoader, AgmInfoWindow, AgmMap, MapTypeControlOptions, MapTypeId, ControlPosition } from "@agm/core";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";
declare var google: any;
import { getFleetStatusImage } from "src/app/utils/common-utils";
import Swal from "sweetalert2";
import { ZonesService } from "../zones/service/zones.service";
import { DriverService } from "../drivers/service/driver.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild("AgmMap", { static: true }) agmElement: AgmMap;

  agmMap;
  isZoomEnable = true;
  isZonesEnable = false;
  isTrafficEnable = false;
  origin = { lat: 0.0, lng: 0.0 };
  destination = { lat: 0.0, lng: 0.0 };
  fleetsByLocations;
  vehicles;
  drivers;
  styles = mapStyles();
  selectedFleet;
  rideId;
  selectedFleetMarker;
  search;
  zones = [];
  searchStatus;
  sortingArr = ["busy", "online", "offline"];
  color = [];
  mapTypeControlOptions: MapTypeControlOptions = {
    mapTypeIds: ['roadmap', 'satellite','hybrid','terrain'],
    // mapTypeIds: [MapTypeId.ROADMAP, MapTypeId.HYBRID],
    position: ControlPosition.TOP_LEFT,
    style: 2
  };
  trafficLayer;

  constructor(
    private vehicleService: VehicleService,
    private driverService: DriverService,
    private socketService: SocketService,
    private fleetService: FleetService,
    private zonesService: ZonesService
  ) { }

  ngOnInit() {
    this.socketService
      .addListener(Events.UPDATE_FLEET_LOCATION)
      .subscribe((data) => {
        if (this.fleetsByLocations) {
          const index = this.fleetsByLocations.findIndex(
            (x) => x._id === data.fleetLocation._id
          );
          if (index > -1) {
            this.fleetsByLocations[index].status = data.fleetLocation.status;
            this.fleetsByLocations[index].ride = data.fleetLocation.ride;
            this.fleetsByLocations[index].location.coordinates[0] =
              data.fleetLocation.location.coordinates[0];
            this.fleetsByLocations[index].location.coordinates[1] =
              data.fleetLocation.location.coordinates[1];

            if (data.fleetLocation.zone) {
              this.fleetsByLocations[index].zone = data.fleetLocation.zone;
            } else {
              this.fleetsByLocations[index].zone = null;
            }

            this.setMapZoom();
          } else {
            this.getVehicles();
          }
        }
      });

    this.zonesService.getZones().subscribe(data => {
      this.zones = [];

      for (let zone of data) {
        const tempZone = [];
        zone.geo.coordinates[0].forEach(element => {
          tempZone.push({ lat: element[1], lng: element[0] })
        });

        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += Math.floor(Math.random() * 10);
        }
        this.zones.push({ _id: zone._id, name: zone.name, path: tempZone, color: color })
      }

    })
    // testing
    // this.socketService.emit(Events.DASHBOARD_NOTIFICTION);
  }

  getColorOfZone(zoneId) {
    let color = '#ffffff';
    for (let zone of this.zones) {
      if (zone._id === zoneId) {
        color = zone.color;
        break;
      }
    }
    // console.log({color});
    return color;
  }
  ngAfterViewInit() {
    // console.log(this.agmElement);
    this.agmElement.mapReady.subscribe((map) => {
      this.agmMap = map;
      this.getVehicles();
      this.getDrivers();
      
    });
  }

  getDrivers() {
    const queryParams = {
      status: "active",
    };
    this.driverService.getDrivers(queryParams).subscribe(
      (data) => {
        if (data && data.length) {
          this.drivers = data;
        } else {
          this.drivers = [];
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }
  getVehicles() {
    const queryParams = {
      status: "active",
    };
    this.vehicleService.getVehicles(queryParams).subscribe(
      (data) => {
        if (data && data.length) {
          this.vehicles = data;
        } else {
          this.vehicles = [];
        }
        this.getFleets();
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  getVehicleDetails(id) {
    const profile = this.vehicles.find((x) => x._id === id);
    // console.log("getVehicleDetails",profile);
    return profile;
  }

  getDriverDetails(id) {
    const profile = this.drivers.find((x) => x._id === id);
    // console.log("getVehicleDetails",profile);
    return profile;
  }

  getFleets() {
    this.fleetService.getFleetsByLocations().subscribe(
      (data) => {
        if (data && data.length) {
          this.fleetsByLocations = data;
        } else {
          this.fleetsByLocations = [];
        }
        this.setMapZoom();
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  changeMapZoom() {
    this.isZoomEnable = !this.isZoomEnable;
    this.setMapZoom();
  }

  setMapZoom() {
    if (!this.isZoomEnable) return;
    if (this.agmMap) {
      let bounds = new google.maps.LatLngBounds();

      for (const fleet of this.fleetsByLocations) {
        bounds.extend(
          new google.maps.LatLng(
            fleet.location ? fleet.location.coordinates[1] : 0,
            fleet.location ? fleet.location.coordinates[0] : 0
          )
        );
      }
      this.agmMap.setCenter(bounds.getCenter());
      this.agmMap.fitBounds(bounds);
    }
  }

  selectFleetMarker(data) {
    if (
      this.selectedFleetMarker &&
      this.selectedFleetMarker._id === data.fleet._id
    ) {
      this.selectedFleetMarker = null;
    } else {
      this.selectedFleetMarker = data.fleet;
    }
  }

  openFleetModal(data) {
    this.rideId = this.getRideId(data.fleet._id);
    this.selectedFleet = data.fleet;
    console.log(data);
  }

  onFleetModalClose() {
    this.selectedFleet = null;
  }

  getStatusImage(status) {
    return getFleetStatusImage(status);
  }

  getRideId(id) {
    const result = this.fleetsByLocations.find(
      (x) => x.fleet && x.fleet._id === id
    );
    console.log("getRideId", result);
    return result && result.ride;
  }

  getStatusCounter() {
    const statusCount = {
      online: 0,
      offline: 0,
      busy: 0,
    };
    this.fleetsByLocations &&
      this.fleetsByLocations.forEach((data) => {
        if (data.status === "online") {
          statusCount.online += 1;
        } else if (data.status === "busy") {
          statusCount.busy += 1;
        } else {
          statusCount.offline += 1;
        }
      });
    return statusCount;
  }
  sortFleetStatus() {
    return (
      this.fleetsByLocations &&
      this.fleetsByLocations.sort(
        (a, b) =>
          this.sortingArr.indexOf(a.status) - this.sortingArr.indexOf(b.status)
      )
    );
  }

  getFleetMarker(name: string) {
    return getFleetIcons(name.toLowerCase());
  }

  getStatuColor(status) {
    return getFleetStatusColorRipple(status);
  }

  getStatusColorRipple(status) {
    return getFleetStatusColorRipple(status);
  }

  changeSearchStatus(status) {
    if (this.searchStatus !== status) {
      this.searchStatus = status;
    } else {
      this.searchStatus = null;
    }
  }

  getZoneName(zoneId) {
    let zoneName;
    for (let zone of this.zones) {
      if (zone._id === zoneId) {
        zoneName = zone.name;
        break;
      }
    }
    return zoneName;
  }

  enableTrafficMode() {
    if (!this.agmMap) return;
    this.isTrafficEnable = !this.isTrafficEnable;
    if (this.isTrafficEnable) {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.agmMap);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

}
