import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GooglePlaceDirective } from './directives/google-places-directive/google-place.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
// we are using this in edit profile for all time zone list
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

import { AgmDirectionModule } from 'agm-direction';
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { AgmOverlays } from 'agm-overlays';
import {
  NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
  SPINNER, POSITION, PB_DIRECTION
} from 'ngx-ui-loader';
import { AuthorizationGuard } from './services/auth-guard/authorization-guard.service';
import { SocketService } from './services/socket-service/socket-service.service';
import { MySocketWrapper } from './services/socket-service/socket-wrapper';
import { TokenInterceptorService } from './services/token-interceptor/token-interceptor.service';
import { DashboardComponent } from './components/rides/dashboard/dashboard.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { DriversListComponent } from './components/drivers/drivers-list/drivers-list.component';
import { AddDriverComponent } from './components/drivers/add-driver/add-driver.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SideNavComponent } from './components/shared/side-nav/side-nav.component';
import { CompaniesListComponent } from './components/companies/companies-list/companies-list.component';
import { AddCompanyComponent } from './components/companies/add-company/add-company.component';
import { StaffListComponent } from './components/staff/staff-list/staff-list.component';
import { AddStaffComponent } from './components/staff/add-staff/add-staff.component';
import { SigninComponent } from './components/shared/signin/signin.component';
import { ForgotPasswordComponent } from './components/shared/forgot-password/forgot-password.component';
import { ProfileComponent } from './components/shared/profile/profile.component';
import {
  OwlMomentDateTimeModule,
  OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS
} from 'ng-pick-datetime-moment';
import { DriverDetailsComponent } from './components/drivers/driver-details/driver-details.component';
import { FleetsListComponent } from './components/fleets/fleets-list/fleets-list.component';
import { AddFleetComponent } from './components/fleets/add-fleet/add-fleet.component';
import { AddVehicleComponent } from './components/vehicles/add-vehicle/add-vehicle.component';
import { VehiclesListComponent } from './components/vehicles/vehicles-list/vehicles-list.component';
import { FleetDetailsComponent } from './components/fleets/fleet-details/fleet-details.component';
import { CompanyDetailsComponent } from './components/companies/company-details/company-details.component';
import { HelpingMaterialComponent } from './components/helping-material/helping-material.component';
import { KpisComponent } from './components/kpis/kpis.component';
import { SupportComponent } from './components/support/support.component';
import { DriverBroadcastComponent } from './components/driver-broadcast/driver-broadcast.component';
import { DynamicFaresComponent } from './components/fares/dynamic-fares/dynamic-fares.component';
import { HomeComponent } from './components/home/home.component';
import { AddDynamicFareComponent } from './components/fares/add-dynamic-fare/add-dynamic-fare.component';
import { DynamicFareDetailsComponent } from './components/fares/dynamic-fare-details/dynamic-fare-details.component';
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { AddDriverDocumentsComponent } from './components/drivers/add-driver-documents/add-driver-documents.component';
import { NewRideComponent } from './components/rides/new-ride/new-ride.component';
import { UnassignedComponent } from './components/rides/unassigned/unassigned.component';
import { TimezonePipe } from './pipes/timezone-pipe/timezone.pipe';
import { AddPassengerComponent } from './components/passengers/add-passenger/add-passenger.component';
import { PassengerListComponent } from './components/passengers/passenger-list/passenger-list.component';
import { AddGuestComponent } from './components/passengers/add-guest/add-guest.component';
import { OutsideClickDirective } from './directives/click-outside/click-outside.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageModalComponent } from './components/shared/image-modal/image-modal.component';
import { FleetModalComponent } from './components/shared/fleet-modal/fleet-modal.component';
import { RideDetailsModalComponent } from './components/shared/ride-details-modal/ride-details-modal.component';
import { DriverDropdownComponent } from './components/shared/driver-dropdown/driver-dropdown.component';
import { PassengerDropdownComponent } from './components/shared/passenger-dropdown/passenger-dropdown.component';
import { FilterByKeyPipe } from './pipes/filter-by-key-pipe/filter-by-key-pipe';
import { AddSupportComponent } from './components/support/add-support/add-support.component';
import { SupportListComponent } from './components/support/support-list/support-list.component';
import { SupportChatComponent } from './components/support/support-chat/support-chat.component';
import { DriverRatingModalComponent } from './components/shared/driver-rating-modal/driver-rating-modal.component';
import { NgxStarsModule } from 'ngx-stars';
import { ToastMessagesComponent } from './services/toast-messages/toast-messages.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DriverModalComponent } from './components/shared/driver-modal/driver-modal.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AssignRideModalComponent } from './components/shared/assign-ride-modal/assign-ride-modal.component';
import { ClockPipe } from './pipes/clock-timer-pipe/clock.pipe';
import { OverviewComponent } from './components/kpis/overview/overview.component';
import { EarningsComponent } from './components/kpis/earnings/earnings.component';
import { RideCancelModalComponent } from './components/shared/ride-cancel-modal/ride-cancel-modal.component';
import { WalletsComponent } from './components/wallets/wallets/wallets.component';
import { WalletHistoryModalComponent } from './components/shared/wallet-history-modal/wallet-history-modal.component';
import { DriverWalletComponent } from './components/kpis/driver-wallet/driver-wallet.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { DriverFiltersModalComponent } from './components/shared/driver-filters-modal/driver-filters-modal.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { TimeagoClock, TimeagoFormatter, TimeagoModule } from 'ngx-timeago';
import { CustomClock } from './utils/timeago-clock';
import { CustomFormatter } from './utils/timeago-formatter';
import { TransactionDetailsModalComponent } from './components/shared/transaction-details-modal/transaction-details-modal.component';
import { SubscriptionsComponent } from './components/drivers/subscriptions/subscriptions.component';
import { QuartersListComponent } from './components/quarters/quarters-list/quarters-list.component';
import { AddQuarterComponent } from './components/quarters/add-quarter/add-quarter.component';
import { OperationsComponent } from './components/operations/operations/operations.component';
import { RideTrailModalComponent } from './components/shared/ride-trail-modal/ride-trail-modal.component';
import { FareEditPermissionModalComponent } from './components/shared/fare-edit-permission-modal/fare-edit-permission-modal.component';
import { AddCompanyConfigurationsComponent } from './components/companies/add-company-configurations/add-company-configurations.component';
import { ZonesComponent } from './components/zones/zones.component';
import { AgmDrawingModule } from '@agm/drawing';
import { BillingsComponent } from './components/kpis/billings/billings.component';
import { BrokersComponent } from './components/brokers/brokers-list/brokers.component';
import { AddBrokerComponent } from './components/brokers/add-broker/add-broker.component';
import { BankTransactionModalComponent } from './components/shared/bank-transaction-modal/bank-transaction-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { VouchersListComponent } from './components/vouchers/vouchers-list/vouchers-list.component';
import { AddVoucherComponent } from './components/vouchers/add-voucher/add-voucher.component';
import { HangingStackComponent } from './components/rides/hanging-stack/hanging-stack.component';
import { BusinessReportComponent } from './components/kpis/business-report/business-report.component';
import { DecimalPipe, TitleCasePipe } from '@angular/common';

export const MY_MOMENT_FORMATS = {
  fullPickerInput: 'MM/DD/YYYY HHmm',
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};


const ngxUiLoaderConfig: NgxUiLoaderConfig  = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 80,
  bgsType: 'ball-spin-clockwise',
  blur: 0,
  fgsColor: '#727cf5',
  fgsPosition: 'center-center',
  fgsSize: 40,
  fgsType: 'ball-spin',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 150,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40,40,40,0)',
  pbColor: '#727cf5',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    GooglePlaceDirective,
    DriversListComponent,
    AddDriverComponent,
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    CompaniesListComponent,
    AddCompanyComponent,
    StaffListComponent,
    AddStaffComponent,
    SigninComponent,
    ForgotPasswordComponent,
    ProfileComponent,
    DriverDetailsComponent,
    FleetsListComponent,
    AddFleetComponent,
    AddVehicleComponent,
    VehiclesListComponent,
    FleetDetailsComponent,
    CompanyDetailsComponent,
    HelpingMaterialComponent,
    KpisComponent,
    SupportComponent,
    DriverBroadcastComponent,
    DynamicFaresComponent,
    HomeComponent,
    AddDynamicFareComponent,
    DynamicFareDetailsComponent,
    AddDriverDocumentsComponent,
    NewRideComponent,
    UnassignedComponent,
    TimezonePipe,
    ClockPipe,
    OutsideClickDirective,
    AddPassengerComponent,
    PassengerListComponent,
    AddGuestComponent,
    ImageModalComponent,
    FleetModalComponent,
    RideDetailsModalComponent,
    DriverDropdownComponent,
    PassengerDropdownComponent,
    FilterByKeyPipe,
    AddSupportComponent,
    SupportListComponent,
    SupportChatComponent,
    ToastMessagesComponent,
    DriverRatingModalComponent,
    ResetPasswordComponent,
    DriverModalComponent,
    AdminDashboardComponent,
    AssignRideModalComponent,
    OverviewComponent,
    EarningsComponent,
    RideCancelModalComponent,
    WalletsComponent,
    WalletHistoryModalComponent,
    DriverWalletComponent,
    DriverFiltersModalComponent,
    NotificationsComponent,
    TransactionDetailsModalComponent,
    SubscriptionsComponent,
    QuartersListComponent,
    AddQuarterComponent,
    OperationsComponent,
    RideTrailModalComponent,
    FareEditPermissionModalComponent,
    AddCompanyConfigurationsComponent,
    ZonesComponent,
    BillingsComponent,
    BrokersComponent,
    AddBrokerComponent,
    BankTransactionModalComponent,
    VouchersListComponent,
    AddVoucherComponent,
    HangingStackComponent,
    BusinessReportComponent
    ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgHttpLoaderModule, 

    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ exclude: [],
     showForeground: true }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAnncU14QKEUpJoXqguI6T9kEDD4ULxhco',
      libraries: ['geometry','places','drawing']
    }),
    AgmDrawingModule,
    SocketIoModule,
    AgmDirectionModule,
    BrowserAnimationsModule,

    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    AgmOverlays,
    TimezonePickerModule,
    NgSelectModule,
    NgxStarsModule,
    Ng2SearchPipeModule,
    TooltipModule,
    TimeagoModule.forRoot({
      clock: {provide: TimeagoClock, useClass: CustomClock},
      formatter: { provide: TimeagoFormatter, useClass: CustomFormatter }
    }),
    NgMultiSelectDropDownModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: AuthorizationGuard, useClass: AuthorizationGuard },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    {
      provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
      useValue: { useUtc: false }
    },
    MySocketWrapper,
    SocketService,
    TimezonePipe,
    TitleCasePipe,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
