import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fare-edit-permission-modal',
  templateUrl: './fare-edit-permission-modal.component.html',
  styleUrls: ['./fare-edit-permission-modal.component.css']
})
export class FareEditPermissionModalComponent implements OnInit {
  @Input() data: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  formGroup;
  isSubmitted = false;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      currentPassword: ["", Validators.required],
      type: ["companystaff"],
      isVerifyPassword: [true]
    });
  }

  submit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    this.authService
      .verifyPassword(this.formGroup.value)
      .subscribe(data => {
        if (data) {
          const obj = {
            isCloseModal: true,
            isAuthorized: true,
            url: this.data.url
          }
          this.onClose.emit(obj);
        }
      }, err => {

        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
        }).then((data) => {});
      });
  }

  close() {   
    const obj = {
      isCloseModal: true,
      isAuthorized: false
    }
    this.onClose.emit(obj);
  }

  outsideClickClose() {
    this.onClose.emit(null);
  }

  get form() {
    return this.formGroup.controls;
  }

}
