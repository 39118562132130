import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from 'src/app/utils/url-utils';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  constructor(private http: HttpClient) { }

  getOperationTrips(queryParams) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.SERVER_API_URL + urls.OPERATIONS, { params })
      .pipe(
        catchError(errorHandl)
      );
  }
}
