export enum Events {

    DASHBOARD_NOTIFICTION= 'broadcastFacilityTripFacilityPanel',
    UPDATE_FLEET_LOCATION= 'updateFleetLocation',
    GET_ALL_QUERIES= 'getAllQueries',
    NEW_QUERY= 'newQuery',
    GET_QUERY_MESSAGES= 'getQueryMessages',
    NEW_QUERY_MESSAGE= 'newQueryMessage',
    GET_NOTIFICATIONS= 'notifications',
}
