import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { QuarterService } from '../service/quarter.service';

@Component({
  selector: 'app-quarters-list',
  templateUrl: './quarters-list.component.html',
  styleUrls: ['./quarters-list.component.css']
})
export class QuartersListComponent implements OnInit {
  quarters;

  constructor(private quarterService: QuarterService) { }

  ngOnInit() {
    this.getQuarters();
  }

  getQuarters() {
    const queryParams = {
      status: 'active'
    }
    this.quarterService
      .getQuarters(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.quarters = data;
        }
      });
  }
}
