import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from '../../drivers/service/driver.service';
import { VehicleService } from '../../vehicles/service/vehicle.service';
import { FleetService } from '../service/fleet.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-add-fleet',
  templateUrl: './add-fleet.component.html',
  styleUrls: ['./add-fleet.component.css']
})
export class AddFleetComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  vehicles;
  drivers;
  fleetId;

  constructor(
    private fb: FormBuilder,
    private fleetService: FleetService,
    private vehicleService: VehicleService,
    private driverService: DriverService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.fleetId = this.activatedRoute.snapshot.paramMap.get('id');

    this.formGroup = this.fb.group({
      vehicle: ['', Validators.required],
      make: ['', Validators.required],
      year: ['', Validators.required],
      color: ['', Validators.required],
      vin: ['', Validators.required],
      setiDecal: ['', Validators.required],
      businessName: ['', Validators.required],
      plateNumber: ['', Validators.required],
      plateState: ['', Validators.required],
      status: ['active', Validators.required],
      model: ['', Validators.required],
      // driver: ['', Validators.required],
      
    });

    this.getVehicles();
    // this.getDrivers();
    
    if (this.fleetId) {
      this.getFleet();
    }
  }

  getVehicles() {
    const queryParams = {
      status: 'active'
    }
    this.vehicleService
      .getVehicles(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.vehicles = data;
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  getDrivers() {
    const payload = {
      status: 'active',
      noFleet:true
    }
    this.driverService
      .getDrivers(payload)
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  getFleet() {
    this.fleetService
      .getFleetById(this.fleetId)
      .subscribe(data => {
        if (data) {
          this.formGroup.patchValue(data);
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  submit() {
    this.isSubmitted = true;

    console.log('Form: ', this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    console.log('Valid Form: ', this.formGroup.value);

    this.fleetService
      .saveFleet(this.fleetId, this.formGroup.value)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data =>{
            this.router.navigateByUrl('/fleet');
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  get form() {
    return this.formGroup.controls;
  }
}
