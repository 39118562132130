import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import Swal from "sweetalert2";
import { FleetService } from "../../fleets/service/fleet.service";
import { PassengerService } from "../../passengers/service/passenger.service";
import { RideService } from "../service/ride.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  search='';
  rides;
  status;
  fleets;
  passengers;
  selectedRideId;

  fleet;
  passenger;
  user;

  constructor(
    private rideService: RideService,
    private activatedRoute: ActivatedRoute,
    private fleetService: FleetService,
    private passengerService: PassengerService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.getFleets();
    this.getPassengers();
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(data => {
        if (data) {
          this.user = data;
        }
      })
  }

  getFleets() {
    const payload = {
      status: "active",
    };
    this.fleetService.getFleets(payload).subscribe((data) => {
      console.log("data", data);
      if (data) {
        this.fleets = data;
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }

  getPassengers() {
    const queryParams = {
      status: "active",
    };
    this.passengerService.getPassengers(queryParams).subscribe((data) => {
      if (data) {
        this.passengers = data;
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }

  getRides() {
    this.rides = null;
    let queryParams = {
      search: this.search,
      fleet: this.fleet,
      passenger: this.passenger,
    };

    if (!this.passenger) {
      delete queryParams.passenger;
    }

    if (!this.fleet) {
      delete queryParams.fleet;
    }

 
    if (JSON.stringify(queryParams) === "{}") return;
    this.rideService.search(queryParams).subscribe((data) => {
      if (data && data.length) {
        this.rides = data;
      } else {
        this.rides = [];
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }

  openModal(rideId) {
    this.selectedRideId = rideId;
  }
}
