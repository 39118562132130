import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DriverService } from '../../drivers/service/driver.service';
import { PassengerService } from '../../passengers/service/passenger.service';
import { StaffService } from '../../staff/service/staff.service';
import { SupportService } from '../service/support.service';

@Component({
  selector: 'app-add-support',
  templateUrl: './add-support.component.html',
  styleUrls: ['./add-support.component.css']
})
export class AddSupportComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  queryId;
  queryFor = 'passenger';
  passengers;
  drivers;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private supportService: SupportService,
    private passengerService: PassengerService,
    private driverService: DriverService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      subject: ['', Validators.required],
      query: ['', Validators.required],
      user: [null, Validators.required],
      type: ['Passenger', Validators.required]
    });

    this.getPassengers();
    this.getDrivers();
  }

  submit() {
    this.isSubmitted = true;
    console.log('Form: ', this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    console.log('Valid Form: ', this.formGroup.value);

    this.supportService
      .saveSupportQuery(this.queryId, this.formGroup.value)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data => {
            const type = this.formGroup.value.type.toLowerCase();
            this.router.navigateByUrl(`/support/${type}`);
          });
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  selectUser(user) {
    this.formGroup.patchValue({
      user: null,
      type: user === 'passenger' ? 'Passenger' : 'Driver'
    });
    this.queryFor = user;
  }

  getPassengers() {
    const queryParams = {
      status: "active",
    };
    this.passengerService
      .getPassengers(queryParams)
      .subscribe((data) => {
        if (data) {
          this.passengers = data;
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  getDrivers() {
    const payload = {
      status: "active",
    };
    this.driverService
      .getDrivers(payload)
      .subscribe((data) => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  get form() {
    return this.formGroup.controls;
  }
}
