import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';
import { FareService } from '../service/fare.service';

@Component({
  selector: 'app-dynamic-fares',
  templateUrl: './dynamic-fares.component.html',
  styleUrls: ['./dynamic-fares.component.css']
})
export class DynamicFaresComponent implements OnInit {
  search;
  fares;
  user;
  permissionObj;
  dropDownFilter = "active";

  constructor(
    private fareService: FareService,
    private sharedDataService: SharedDataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
      }
    });
    this.getFares();
  }

  dropDownFilterChanged(event) {
    this.getFares();
  }
  getFares() {
    const queryParams = {
      status: this.dropDownFilter
    }
    this.fareService
      .getFares(queryParams)
      .subscribe(data => {
        console.log('getFares ',data);
        if (data && data.length) {
          this.fares = data;
          this.fares.sort((a,b) => a.vehicle.name.localeCompare(b.vehicle.name));
        }else{
          this.fares = [];
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  verification(id?) {
    this.permissionObj = { url: id ? `/dynamic-fares/${id}/edit` : '/dynamic-fares/add' }
  }

  closeAuthorizationModal(obj) {
    if (obj) {
      if (obj.isCloseModal) this.permissionObj = undefined;
      if (obj.isAuthorized) {
        this.router.navigateByUrl(obj.url, {state: {isAuthorized: true}});
      }
    }
  }

}
