import { environment } from "src/environments/environment";
export class urls {
    static BASE_URL = environment.BASE_URL;
    static SERVER_API_URL = urls.BASE_URL + 'api/'

    static SIGNIN_URL = 'auth/signin';
    static FORGOT_URL = 'auth/forgot';
    static CHANGE_PASSWORD_URL = 'users/password';
    static CHANGE_PROFILE_IMAGE = 'users/picture';
    static PROFILE_URL = 'users/me';
    static VERIFY_PASSWORD = 'users/accounts';

    static COMPANIES = 'companies';
    static VEHICLES = 'vehicles';
    static BROKERS = 'brokers';
    static STAFF = 'companystaffs';
    static FLEETS = 'fleets';
    static FLEETS_BY_LOCATION = 'fleets/locations';

    static FARES = 'fares';
    static PASSWORD = 'users/password';
    static USERS = 'users';
    static USER_PICTURE = 'users/picture';
    static DRIVERS = 'drivers';
    static DRIVERS_ADDITIONAL_INFO = 'driveradditionalinfos';
    static DRIVER_ADDONS = 'addons';
    static DRIVER_ADDONS_TRANSACTIONS = 'addonstransactions';
    static RIDES = "rides"
    static GET_FAREESTIMATION = "fares/estimation"
    static PASSENGERS = "passengers"
    static SUPPORT = 'queries'
    static COUNT_QUERIES = 'countqueries'
    static REPORTS = 'rides/reports'
    static KPI_REPORTS = 'rides/kpis/report'
    static BUSSINESS_REPORTS = 'rides/businessreport'
    static SEARCH = 'rides/search'
    static NOTIFICATIONS_URL = 'notifications';
    static AUTH_RESET_URL = 'auth/reset/';
    static DRIVER_ASSIGN = 'assignDriver';
    static WALLETS = 'wallets';
    static WALLET_TRANSACTIONS = 'wallettransactions';
    static COUNT_RIDES = 'countrides';
    static QUARTERS = 'quarters';
    static OPERATIONS = 'rides/operations';
    static RIDES_OFFERED = 'rides/offered';
    static RIDE_TRAIL = 'rides/trail';
    static COMPANY_CONFIGS = 'companyconfigs';

    static ZONES = 'zones';
    static BILLINGS_REPORTS = 'rides/billing';
    static SOS_NOTIFICATIONS_URL = 'sos';
    static VOUCHERS_URL = 'vouchers';
}
