import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { urls } from 'src/app/utils/url-utils';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  tokenizedReq;

  constructor(private authService: AuthService) { }

  intercept(req, next) {
    const startTime = Date.now();
    const token = this.authService.getToken('token');
    
    if (req.body instanceof FormData) {
       this.tokenizedReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            // 'Content-Type': 'multipart/form-data'
          }
        });
      } else {
      this.tokenizedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      }

    return next.handle(this.tokenizedReq).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            event = event.clone({ body: + this.responselogDetails(JSON.stringify(event.body))});
          }
        },
      ),
      finalize(() => {
        const elapsedTime = Date.now() - startTime;
        const message =
         ' HttpRequest >>>>>>>>\n\n\n'
         + req.method + ' ' + req.urlWithParams +
          + ' in ' + elapsedTime + 'ms' + '\n body: ' + JSON.stringify(req.body) +
          '\n\n\n<<<<<<<< HttpRequest';
        this.logDetails(message);
      })
    );
  }
  private logDetails(msg: string) {
    console.log(msg);
  }
  private responselogDetails(msg: string) {
    console.log(' HttpResponse >>>>>>>>\n\n\n' +
    msg,
     '\n\n\n<<<<<<<< HttpResponse');
  }
}
