import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EarningsService } from '../../kpis/service/earnings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bank-transaction-modal',
  templateUrl: './bank-transaction-modal.component.html',
  styleUrls: ['./bank-transaction-modal.component.css']
})
export class BankTransactionModalComponent implements OnInit {
  @Input() earnings: any;
  @Output() onClose = new EventEmitter<any>();
  bankTransactionId;
  isSubmitted = false;

  constructor(
    private earningService: EarningsService
  ) { }

  ngOnInit() {
  }

  submit() {
    this.isSubmitted = true;
    if (!this.bankTransactionId) {
      return;
    }

    this.askSettlePayments();
  }

  askSettlePayments() {
    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to settle the payments?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: "#F1A303",
    }).then((data) => {
      if (data.isConfirmed) {
        this.settlePayments();
      }
    });
  }

  settlePayments() {
    let payload;
    let rideIds;
    if (this.earnings && this.earnings.details && this.earnings.details.length) {
      rideIds = this.earnings.details.map(t => t._id);
      payload = {
        rideIds,
        bankTransactionId: this.bankTransactionId
      }

      this.earningService
        .settlePayments(payload)
        .subscribe(data => {
          if (data) {
            this.onClose.emit(data);
            // this.getEarnings();
          }
        });
    }
  }

  close() {
    this.onClose.emit(null);
  }

}
