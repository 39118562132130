import { throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { FileSnippet } from "./image-processing-utils";

// Error handling
export function errorHandl(error: HttpErrorResponse) {
  let errorMessage = "";
  // console.log(error.instanceof());
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

    if (error.error) {
      console.log("if error", error);
      if (error.error.message) {
        errorMessage = error.error.message;
      }
    }
  }
  return throwError(errorMessage);
}

// setting form data
// export function setFormData(imgFiles, staffData, dataRef) {
//   const formData = new FormData();
//   Object.keys(imgFiles).forEach(key => {
//           if (imgFiles[key] !== null) {
//               formData.append(key, imgFiles[key], imgFiles[key].name);
//           }
//       });
//   Object.keys(staffData).forEach(key => formData.append(`${dataRef}[${key}]`, staffData[key]));
//   return formData;
// }

export function setOnlyFieldFormData(staffData, dataRef) {
  const formData = new FormData();
  Object.keys(staffData).forEach(key =>
    formData.append(`${dataRef}[${key}]`, staffData[key])
  );
  return formData;
}

// export function setFormData( data, keyName) {
//   const formData: FormData = new FormData();
//   Object.keys(data).forEach((key) => {
//     if (data[key]  instanceof Date) {
//       // Skip this Case
//     }else if (data[key]  instanceof FileSnippet) {
//       console.log("File Data ", data[key]);
//       formData.append(key, data[key].file, data[key].file.name);
//     } else {
//       formData.append(`${keyName}[${key}]`, data[key]);
//     }
//   });
//   return formData;
// }

export function setFormData(data) {
  const formData: FormData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof FileSnippet) {
      formData.append(key, data[key].file, data[key].file.name);
    } else if (data[key] instanceof Array) {
      const arr = data[key];
      if (arr && arr.length) {
        arr.forEach(data => {
          formData.append(key, data);
        });
      }
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
}

// Data Ref. like staff[name] wherein staff is data ref.
export function setFormDataV2(data, dataRef?) {
  const formData: FormData = new FormData();
  Object.keys(data).forEach((key) => {
      const name = dataRef ? `${dataRef}[${key}]` : key;
      const value = data[key];
      if (value instanceof File) {
          formData.append(key, value, value.name);
      } else if (value instanceof Array) {
          if (value.length) {
              value.forEach((v,index) => {
                  if (v instanceof Object) {
                      Object.keys(v).forEach(key => {
                          formData.append(`${name}[${index}][${key}]`, v[key]);
                      });
                  } else {
                      formData.append(name, v);
                  }
              });
          } else {
              formData.append(`${name}[]`, '');
          }
      } else if (value instanceof Object) {
          Object.keys(value).forEach(key1 => {
              formData.append(key + '[' + key1+']', value[key1]);
          });
      }
      else {
          formData.append(name, value);
      }
  });
  return formData;
}

export function downloadImage(uri, name) {
  var link = document.createElement("a");
  console.log(uri, name);
  link.download = name;
  link.href = uri;
  link.click();
}

export function downloadImage2(url, name) {

  // console.log(url, fileName);
  const body =  document.body as HTMLDivElement;
  const linkSource =  url;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.target = '_blank';
  downloadLink.download = name;
  body.appendChild(downloadLink);
  console.log(downloadLink);
  console.log(url);
  console.log(name);
  console.log(downloadLink.click());
  // downloadLink.click();
  // downloadLink.dispatchEvent(new MouseEvent('click'));
  const event = document.createEvent('Event');
  event.initEvent('click', true, true);
  downloadLink.dispatchEvent(event);
  // (window.URL || window.webkitURL).revokeObjectURL(downloadLink.href);

}
