import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { ConfirmedValidator, getUserType } from 'src/app/utils/common-utils';
import { imageFileProcessing } from 'src/app/utils/image-processing-utils';
import Swal from 'sweetalert2';
import { CompanyService } from '../../companies/service/company.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  companyForm: FormGroup;
  isCompanySubmitted = false;

  profileForm: FormGroup;
  isProfileSubmitted = false;

  passwordForm: FormGroup;
  isPasswordSubmitted = false;

  user;
  companyId;
  timeZone;
  userRole;
  isDisabled = true;

  companyImageFile;
  companyImageUrl;

  profileImageFile;
  profileImageUrl;

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private sharedService: SharedDataService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.companyForm = this.fb.group({
      displayName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', Validators.required],
      address: [{ value: '', disabled: this.isDisabled }, Validators.required],
      timeZone: ['', Validators.required],
      newProfilePicture: [''],
      dateFormat: [''],
      timeFormat: [''],
      focalPerson: this.fb.group({
        displayName: [''],
        contactNumber: ['']
      }),
    });

    this.profileForm = this.fb.group({
      displayName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: [''],
      // email: ['', [Validators.required, Validators.email]],
      // newProfilePicture: ['']
    });

    this.passwordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      verifyPassword: ['', Validators.required],
      type: ['']
    }, {
      validator: ConfirmedValidator('newPassword', 'verifyPassword')
    });

    this.getUser();
    // this.getProfileDetails();
  }

  getUser() {
    this.sharedService
      .getUser()
      .subscribe(data => {
        if (data) {
          console.log('Shared User: ', data);
          this.user = data;
          this.userRole = this.user.roles[0];
          // this.isDisabled = this.user.roles[0] === 'cadmin' ? false : true;
          this.isDisabled = true;

          if (this.user.company) {
            this.companyForm.patchValue(this.user.company);
            this.companyImageUrl = this.user.company.profileImageURL;
            this.timeZone = this.user.company.timeZone;
            this.companyId = this.user.company._id;
          }

          this.profileForm.patchValue(data);
          this.profileImageUrl = this.user.profileImageURL;

          this.passwordForm.patchValue({
            type: getUserType(this.user.roles[0])
          });
        }
      })
  }

  // getProfileDetails() {
  //   this.companyService
  //     .getCompanyById(this.companyId)
  //     .subscribe(data => {
  //       if (data) {
  //         this.companyForm.patchValue(data);
  //         this.profileForm.patchValue(data);
  //       }
  //     }, err => {
  //       Swal.fire({
  //         title: "Alert",
  //         html: 'Operation failed',
  //         icon: "error",
  //         confirmButtonColor: '#F1A303'
  //       }).then((data) => {
  //       });
  //     });
  // }

  submitCompanyInformation() {
    this.isCompanySubmitted = true;

    console.log('Company Form: ', this.companyForm.value);

    if (this.companyForm.invalid) {
      return;
    }

    console.log('Company Form Valid: ', this.companyForm.value);

    // Edit case checks
    if (!this.companyForm.value.newProfilePicture) {
      delete this.companyForm.value.newProfilePicture;
    }

    delete this.companyForm.value.address;
    delete this.companyForm.value.timeZone;

    this.companyService
      .saveCompany(this.companyId, this.companyForm.value)
      .subscribe((data) => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then((data) => {
          });
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  submitProfileInformation() {
    this.isProfileSubmitted = true;

    console.log('Profile Form: ', this.profileForm.value);

    if (this.profileForm.invalid) {
      return;
    }

    console.log('Profile Form Valid: ', this.profileForm.value);

    delete this.profileForm.value.email

    this.authService
      .updateProfile(this.profileForm.value)
      .subscribe(data => {
        if (data) {
          console.log('Update Profile Respone: ', data);
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data =>{
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  submitChangePassword() {
    this.isPasswordSubmitted = true;

    console.log('Password Form: ', this.passwordForm.value);

    if (this.passwordForm.invalid) {
      return;
    }

    console.log('Password Form Valid: ', this.passwordForm.value);

    this.authService
      .changePassword(this.passwordForm.value)
      .subscribe(data => {
        if (data) {
          console.log('Change Password Success: ', data);
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data =>{
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  setAddress(place) {
    this.companyForm.patchValue({
      address: place.formatted_address,
      coords: [place.geometry.location.lng(), place.geometry.location.lat()]
    });
  }

  onChangeTimezone(tz) {
    console.log('TimeZone: ', tz);
    this.companyForm.patchValue({
      timeZone: tz
    });
  }

  processImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.companyImageFile = result.file;
        this.companyImageUrl = result.src;
        this.companyForm.patchValue({
          newProfilePicture: this.companyImageFile
        });
      } else {
        this.companyImageFile = null;
        this.companyImageUrl = '';
        this.companyForm.patchValue({
          newProfilePicture: null
        });
      }
    });
  }

  processImageProfile(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.profileImageFile = result.file;
        this.profileImageUrl = result.src;
        this.updateProfileImage();
      } else {
        this.profileImageFile = null;
        this.profileImageUrl = '';
      }
    });
  }

  updateProfileImage() {
    const payload = {
      newProfilePicture: this.profileImageFile
    }
    this.authService
      .updateProfileImage(payload)
      .subscribe(data => {
        if (data) {
          console.log('Profile Image Updated: ', data);
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data =>{
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  get form() {
    return this.companyForm.controls;
  }

  get form2() {
    return this.profileForm.controls;
  }

  get form3() {
    return this.passwordForm.controls;
  }
}
