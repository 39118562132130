import { Component, OnInit } from '@angular/core';
import { WalletService } from '../service/wallet.service';

@Component({
  selector: 'app-wallets',
  templateUrl: './wallets.component.html',
  styleUrls: ['./wallets.component.css']
})
export class WalletsComponent implements OnInit {
  wallets;
  historyModalObj;

  constructor(
    private walletService: WalletService
  ) { }

  ngOnInit() {
    this.getWallets();
  }

  getWallets() {
    this.walletService
    .getWallets()
    .subscribe(data => {
      if (data) {
        this.wallets = data;
      }
    });
  }

  showHistory(wallet) {
    this.historyModalObj = wallet;
  }

}
