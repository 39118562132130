import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { PassengerService } from '../../passengers/service/passenger.service';

@Component({
  selector: 'app-passenger-dropdown',
  templateUrl: './passenger-dropdown.component.html',
  styleUrls: ['./passenger-dropdown.component.css']
})
export class PassengerDropdownComponent implements OnInit {
  @Input() id;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchInput', null) input: ElementRef;

  items: any;
  filteredItems;
  selectedItem;
  inputSearch = '';
  showList = false;
  isResetList = false;

  constructor(
    private passengerService: PassengerService
  ) { }

  ngOnInit() {
    console.log('DD:ngOnInit()');
    this.getPassengers();
  }

  getPassengers() {
    const queryParams = {
      status: "active",
    };
    this.passengerService
      .getPassengers(queryParams)
      .subscribe((data) => {
        if (data) {
          this.items = data;
          this.filteredItems = data;
          if (this.id) {
            this.loadPassenger();
          }
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  onItemSelect(selectedItem) {
    this.showList = false;
    this.selectedItem = selectedItem;
    this.inputSearch = this.selectedItem.displayName;
    this.onSelect.emit(this.selectedItem);
    console.log('DD:Select', this.selectedItem);
  }

  onFocus() {
    console.log('DD:Focus');
    this.showList = true;
  }

  clear() {
    console.log('DD:Clear');
    this.selectedItem = null;
    this.inputSearch = '';
    this.showList = false;
    this.input.nativeElement.focus();
    if (this.id && !this.isResetList) {
      console.log('DD:ResetList');
      this.filteredItems = this.items;
      this.isResetList = true;
    }
  }

  close() {
    console.log('DD:Close');
    this.showList = false;
    this.input.nativeElement.focus();
  }

  loadPassenger() {
    console.log('DD:LoadPassenger')
    this.selectedItem = this.items.find(item => item._id === this.id);
    this.filteredItems = this.items.filter(item => item._id === this.id);
    this.inputSearch = this.selectedItem.displayName;
  }
}
