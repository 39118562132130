import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';
import { DriverService } from '../../drivers/service/driver.service';

@Component({
  selector: 'app-driver-rating-modal',
  templateUrl: './driver-rating-modal.component.html',
  styleUrls: ['./driver-rating-modal.component.css']
})
export class DriverRatingModalComponent implements OnInit {
  @Input() driverId: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  driver;
  driverRatings;
  passengerReviews;
  isResponseReceived = false;
  user;

  constructor(
    private driverService: DriverService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.getDriverRatings();

    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
      } else {
        this.user = null;
      }
    });
  }

  getDriverRatings() {
    this.driverService
    .getDriverRatings(this.driverId)
    .subscribe(data => {
      if (data) {
        this.isResponseReceived = true;
        this.driver = data.driver;
        
        if (data.rating && data.rating.length) {
          this.driverRatings = data.rating[0];
          this.passengerReviews = this.driverRatings.reviews;
          this.passengerReviews.sort((a, b) => <any>new Date(b.createdAt) - <any>new Date(a.createdAt))
        }

        console.log(this.driver, this.driverRatings);
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }

  close() {
    this.onClose.emit(null);
  }

  getPercentage(value, total) {
    const per =  (value/total) * 100;
    return `${per.toFixed(2)}%`;
  }

}
