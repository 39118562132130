import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class PassengerService {

  constructor(private http: HttpClient) { }

  getPassengers(queryParams) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.SERVER_API_URL + urls.PASSENGERS, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getPassengerById(passengerId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.PASSENGERS + `/${passengerId}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  savePassenger(passengerId, payload) {
    const formData = setFormData(payload);

    if (passengerId) {
      // Edit
      return this.http.put<any>(urls.SERVER_API_URL + urls.PASSENGERS +`/${passengerId}`, formData)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      // Add
      return this.http.post<any>(urls.SERVER_API_URL + urls.PASSENGERS, formData)
        .pipe(
          catchError(errorHandl)
        );
    }
  }
}
