import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { errorHandl } from "src/app/utils/network-utils";
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: "root",
})
export class FareService {
  constructor(private http: HttpClient) {}

  getFares(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.FARES, { params })
      .pipe(catchError(errorHandl));
  }

  saveFare(id, payload) {
    if (id) {
      // Edit
      return this.http
        .put<any>(urls.SERVER_API_URL + urls.FARES + `/${id}`, payload)
        .pipe(catchError(errorHandl));
    } else {
      // Add
      return this.http
        .post<any>(urls.SERVER_API_URL + urls.FARES, payload)
        .pipe(catchError(errorHandl));
    }
  }

  saveFareLog(payload) {
      return this.http
        .post<any>('https://logs-rideunitedsd.vercel.app/api/fares-log', payload)
        .pipe(catchError(errorHandl));
  }

  getFareById(id) {
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.FARES + `/${id}`)
      .pipe(catchError(errorHandl));
  }

  getFareDetails(id) {
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.FARES + `/company/${id}`)
      .pipe(catchError(errorHandl));
  }
}
