import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      usernameOrEmail: ['', [Validators.required, Validators.email]],
      type: ['companystaff']
    });
  }

  submit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    console.log('Form: ', this.formGroup.value);

    this.authService
      .forgetPassword(this.formGroup.value)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "We have sent a reset password link. Thank you!",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then((data) => {
            this.router.navigateByUrl("/signin");
          });
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  get form() {
    return this.formGroup.controls;
  }
}
