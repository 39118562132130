import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { RideService } from '../../rides/service/ride.service';

@Component({
  selector: 'app-ride-trail-modal',
  templateUrl: './ride-trail-modal.component.html',
  styleUrls: ['./ride-trail-modal.component.css']
})
export class RideTrailModalComponent implements OnInit {
  @Input() data: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  rideTrail;
  trails;
  user;
  rideId;
  rideEvents;

  constructor(
    private rideService: RideService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
      } else {
        this.user = null;
      }
    });

    this.rideEvents = this.data.events;
    this.rideId = this.data.rideId;
    console.log(this.rideEvents);

    if (this.rideId) {
      this.getTrailDetails();
    }
  }

  getTrailDetails() {
    this.rideService
      .getRideTrail(this.rideId)
      .subscribe(data => {
        if (data) {
          this.rideTrail = data;
          if (this.rideTrail.trail && this.rideTrail.trail.length) {
            let trails = this.rideTrail.trail[0].trails;
            if (trails && trails.length) {
              this.trails = trails.map(trail => {
                // const arr = trail.split(',');
                // return { lat: parseFloat(arr[0]), lng: parseFloat(arr[1])};
                const [latitude, longitude] = trail.split(',');
                return { lat: parseFloat(latitude), lng: parseFloat(longitude)};
              })
            }
          }
          console.log('Ride Trail: ', this.rideTrail);
          console.log('Trails', this.trails);
        }
      });
  }

  close() {
    this.onClose.emit(null);
  }

}
