import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { imageFileProcessing } from 'src/app/utils/image-processing-utils';
import Swal from 'sweetalert2';
import { BrokersService } from '../service/brokers.service';

@Component({
  selector: 'app-add-broker',
  templateUrl: './add-broker.component.html',
  styleUrls: ['./add-broker.component.css']
})
export class AddBrokerComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  brokerId;
  brokerImageFile;
  brokerImageUrl;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private brokersService: BrokersService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.brokerId = this.activatedRoute.snapshot.paramMap.get("id");

    this.formGroup = this.fb.group({
      name: ["", Validators.required],
      status: ["active", Validators.required],
      newProfilePicture: [null, Validators.required],
    });

    if (this.brokerId) {
      this.getVehicle();
    }
  }

  getVehicle() {
    this.brokersService.getBrokerById(this.brokerId).subscribe((data) => {
      if (data) {
        this.brokerImageUrl = data.profileImageURL;
        this.formGroup.patchValue(data);

        this.formGroup.controls.newProfilePicture.clearValidators();
        this.formGroup.controls.newProfilePicture.updateValueAndValidity();
      }
    });
  }

  submit() {
    this.isSubmitted = true;

    console.log("Form Values: ", this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    if (this.brokerId) {
      if (!this.formGroup.value.newProfilePicture) {
        delete this.formGroup.value.newProfilePicture;
      }
    }

    console.log("Valid Form: ", this.formGroup.value);
    this.brokersService
      .saveBroker(this.brokerId, this.formGroup.value)
      .subscribe((data) => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then((data) => {
            this.router.navigateByUrl("/brokers");
          });
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  processImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.brokerImageFile = result.file;
        this.brokerImageUrl = result.src;
        this.formGroup.patchValue({
          newProfilePicture: this.brokerImageFile,
        });
      } else {
        this.clearImage();
      }
    });
  }

  clearImage() {
    this.brokerImageFile = null;
    this.brokerImageUrl = "";
    this.formGroup.patchValue({
      newProfilePicture: null,
    });

    if (this.brokerId) {
      this.formGroup.controls.newProfilePicture.setValidators([
        Validators.required,
      ]);
      this.formGroup.controls.newProfilePicture.updateValueAndValidity();
    }
  }

  get form() {
    return this.formGroup.controls;
  }
}
