import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../service/company.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {
  companyId;
  companyDetails;

  constructor(
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.companyId = this.activatedRoute.snapshot.paramMap.get('id');

    this.getCompanyDetails();
  }

  getCompanyDetails() {
    this.companyService
    .getCompanyById(this.companyId)
    .subscribe(data => {
      if (data) {
        this.companyDetails = data;
      }
    });
  }
}
