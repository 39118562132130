import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  constructor(private http: HttpClient) { }

  getZones() {
    return this.http.get<any>(urls.SERVER_API_URL + urls.ZONES)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveZone(payload) {
    return this.http.post<any>(urls.SERVER_API_URL + urls.ZONES, payload)
      .pipe(
        catchError(errorHandl)
      );
  }
  deleteZone(id) {
    return this.http.post<any>(urls.SERVER_API_URL + urls.ZONES + `/${id}`,{})
      .pipe(
        catchError(errorHandl)
      );
  }
}
