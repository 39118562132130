import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { environment } from "src/environments/environment";
declare var google: any;
@Directive({
  selector: '[google-place]'
})
export class GooglePlaceDirective implements OnInit {

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;
  constructor(elRef: ElementRef, private mapsAPILoader: MapsAPILoader) {
    // elRef will get a reference to the element where
    // the directive is placed
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    const center = { lat: 32.715736, lng: -117.161087 };
    // Create a bounding box with sides ~100km away from the center point
    const defaultBounds = {
      north: center.lat + 1.0,
      south: center.lat - 1.0,
      east: center.lng + 1.0,
      west: center.lng - 1.0,
    };
  const options = {
    bounds: defaultBounds,
    componentRestrictions: { country: 'US'},
    strictBounds: true
    // types: ['(cities)']
    // types: ['geocode']  // 'establishment' / 'address' / 'geocode'
}

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.element,  environment.production ? options : {});

      // Event listener to monitor place changes in the input
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        // Emit the new address object for the updated place
        this.onSelect.emit(autocomplete.getPlace());
      });
    });
  }

}
