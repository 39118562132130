import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { imageFileProcessing } from 'src/app/utils/image-processing-utils';
import Swal from 'sweetalert2';
import { StaffService } from '../../staff/service/staff.service';
import * as moment from "moment-timezone";
import { VoucherService } from '../service/voucher.service';
import { ZonesService } from '../../zones/service/zones.service';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';

@Component({
  selector: 'app-add-voucher',
  templateUrl: './add-voucher.component.html',
  styleUrls: ['./add-voucher.component.css']
})
export class AddVoucherComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  voucherId;
  today;
  user;
  timeZone = "America/New_York";

  zones;
  selectedZones;
  dropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  constructor(
    private voucherService: VoucherService,
    private zonesService: ZonesService,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder,
    private router: Router,
    private staffService: StaffService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.voucherId = this.route.snapshot.paramMap.get('id');
    this.today = moment().set({hours:0,minutes:0,seconds:0}).toISOString();

    this.formGroup = this.fb.group({
      description: ['', Validators.required],
      name: ['', Validators.required],
      discount: ['', Validators.required],
      preFrom: [''],
      from: ['', Validators.required],
      preTill: [''], 
      till: ['', Validators.required],
      quota: ['', Validators.required],
      remaining: ['', Validators.required],
      status: ['active', Validators.required]
    });


    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(data => {
        if (data) {
          this.user = data;
          if (data.company && data.company.timeZone) {
            this.timeZone = data.company.timeZone;
          }
          this.getZones();
        }
      });
  }

  getZones() {
    this.zonesService
      .getZones()
      .subscribe(data => {
        if (data) {
          this.zones = data;
          if (this.voucherId) {
            this.getVoucherDetails();
          }
        }
      });
  }

  getVoucherDetails() {
    this.voucherService
      .getVoucherById(this.voucherId)
      .subscribe(data => {
        if (data) {
          this.formGroup.patchValue({
            ...data,
            preFrom: moment(data.from).tz(this.timeZone),
            preTill: moment(data.till).tz(this.timeZone)
          });
          if (data.zones && data.zones.length) {
            this.loadZones(data.zones);
          }
        }
      });
  }

  onDateChange(evt, control) {
    if (evt) {
      control.setValue(moment(evt).set({ hours: 0, minutes: 0, seconds: 0 }).toISOString());
    }
  }

  loadZones(zones) {
    this.selectedZones = this.zones.filter(zone => zones.includes(zone._id));
  }

  submit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    const payload = this.formGroup.value;

    const zones = this.selectedZones ? this.selectedZones.map(zone => zone._id) : [];
    payload['zones'] = zones;
  

    this.voucherService
      .saveVoucher(this.voucherId, payload)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data => {
            this.router.navigateByUrl('/vouchers');
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
        }).then((data) => {
        });
      });
  }

  get form() {
    return this.formGroup.controls;
  }
}
