import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { OperationsService } from '../service/operations.service';
import * as moment from "moment-timezone";
import { SocketService } from 'src/app/services/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.css']
})
export class OperationsComponent implements OnInit {
  user;
  timeZone = "America/New_York";
  today = moment().tz(this.timeZone).startOf("day");
  scheduleTime = this.today.toISOString();
  selectedRideId;

  assignedRides = [];
  cancelledRides = [];
  finishedRides = [];
  hangingRides = [];
  inprogressRides = [];
  missedRides = [];

  assignDriverModalObj;

  constructor(
    private operationsService: OperationsService,
    private sharedDataService: SharedDataService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    this.socketService
      .addListener(Events.GET_NOTIFICATIONS)
      .subscribe((data) => {
        console.log(Events.GET_NOTIFICATIONS, data);
        if (data.notification) {
          if (data.notification.type === "Ride") {
            this.getTrips();
          }
        }
      });
    this.getUser();
    this.getTrips();
  }

  getUser() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
        this.timeZone = data.company.timeZone;
        this.today = moment().tz(this.timeZone).startOf("day");
        this.scheduleTime = this.today.toISOString();
      }
    });
  }

  getTrips() {
    const queryParams = {
      scheduleTime: this.scheduleTime
    };
    this.operationsService
      .getOperationTrips(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          const rides = data[0];
          this.assignedRides = rides.assigned;
          this.cancelledRides = rides.cancelled;
          this.finishedRides = rides.finished;
          this.hangingRides = [];
          this.missedRides = [];
          this.inprogressRides = rides.inprogress;
          this.getMissedRides(rides.hanging);
        }
      });
  }

  assignRide(ride, section?) {
    this.assignDriverModalObj = {
      ride: ride,
      idx: -1
    };
    if (section) {
      this.assignDriverModalObj['section'] = section;
    }
  }

  fetchETA() {
    
  }

  onCloseAssignModal(evt) {
    this.assignDriverModalObj = null;
    this.getTrips();
  }

  getMissedRides(rides) {
    const now = moment().tz(this.timeZone);

    for (let ride of rides) {
      let minutes = 0;
      if (ride.scheduleTime) {
        const scheduleTime = moment(ride.scheduleTime).tz(this.timeZone);
        minutes = now.diff(scheduleTime, 'minutes');
        ride.mins = minutes
      }

      console.log(minutes);
      if (minutes > 10 ) {
        this.missedRides.push(ride)
      } else {
        this.hangingRides.push(ride)
      }

    }
  }

  openModal(rideId) {
    this.selectedRideId = rideId;
  }

  
  refresh() {
    this.getTrips();
  }
}
