import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { imageFileProcessing } from 'src/app/utils/image-processing-utils';
import Swal from 'sweetalert2';
import { DriverService } from '../service/driver.service';

@Component({
  selector: 'app-add-driver-documents',
  templateUrl: './add-driver-documents.component.html',
  styleUrls: ['./add-driver-documents.component.css']
})
export class AddDriverDocumentsComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  driverId;
  additionalInfo;
  additionalInfoId;

  licenseImageFile;
  licenseImageUrl;

  licenseBackImageFile;
  licenseBackImageUrl;

  taxiCabImageFile;
  taxiCabImageUrl;

  taxiCabBackImageFile;
  taxiCabBackImageUrl;

  sheriffLicenseImageFile;
  sheriffLicenseImageUrl;

  sheriffLicenseBackImageFile;
  sheriffLicenseBackImageUrl;

  vehicleRegistraionImageFile;
  vehicleRegistraionImageUrl;
  
  bankVoidedChequeImageFile;
  bankVoidedChequeImageUrl;

  today;

  constructor(
    private fb: FormBuilder,
    private driverService: DriverService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.driverId = this.activatedRoute.snapshot.paramMap.get('id');
    this.today = moment().set({hours:0,minutes:0,seconds:0}).toISOString();

    this.formGroup = this.fb.group({
      licenseNumber: ['', Validators.required],
      licenseExpiry: ['', Validators.required],
      preLicenseExpiry: [''],
      newLicenseImage: [null, Validators.required],
      newLicenseBackImage: [null, Validators.required],

      taxiCabExpiry: [''],
      preTaxiCabExpiry: [''],
      newTaxiCabImage: [null],
      newTaxiCabBackImage: [null],

      sheriffLicenseNumber: [''],
      sheriffLicenseExpiry: [''],
      preSheriffLicenseExpiry: [''],
      newSheriffLicenseImage: [null],
      newSheriffLicenseBackImage: [null],

      vehicleRegistrationExpiry: ['', Validators.required],
      preVehicleRegistrationExpiry: [''],
      newVehicleRegistrationImage: [null, Validators.required],

      bankRoutingNumber: [''],
      bankAccountNumber: [''],
      newBankVoidedChequeImage: [null],
      
    });

    if (this.driverId) {
      this.getDriverDocuments();
    }
  }

  getDriverDocuments() {
    this.driverService
      .fetchDriverDocuments(this.driverId)
      .subscribe(data => {
        if (data) {
          this.additionalInfoId = data._id;
          this.additionalInfo = data;

          this.licenseImageUrl = data.licenseImageURL;
          this.licenseBackImageUrl = data.licenseImageBackURL;
          this.taxiCabImageUrl = data.taxiCabImageURL;
          this.taxiCabBackImageUrl = data.taxiCabBackImageURL;
          this.sheriffLicenseImageUrl = data.sheriffLicenseImageURL;
          this.sheriffLicenseBackImageUrl = data.sheriffLicenseBackImageURL;
          this.vehicleRegistraionImageUrl = data.vehicleRegistrationImageURL;
          this.bankVoidedChequeImageUrl = data.bankVoidedChequeImageURL;

          this.formGroup.patchValue({
            ...data,
            preLicenseExpiry: data.licenseExpiry,
            preTaxiCabExpiry: data.taxiCabExpiry,
            preSheriffLicenseExpiry: data.sheriffLicenseExpiry,
            preVehicleRegistrationExpiry: data.vehicleRegistrationExpiry
          });

          this.formGroup.controls.newLicenseImage.clearValidators();
          this.formGroup.controls.newLicenseImage.updateValueAndValidity();

          this.formGroup.controls.newLicenseBackImage.clearValidators();
          this.formGroup.controls.newLicenseBackImage.updateValueAndValidity();

          this.formGroup.controls.newVehicleRegistrationImage.clearValidators();
          this.formGroup.controls.newVehicleRegistrationImage.updateValueAndValidity();
        }
      });
  }

  submit() {
    this.isSubmitted = true;

    if (this.additionalInfo ) {
      this.formGroup.controls.preTaxiCabExpiry.clearValidators();
      this.formGroup.controls.preTaxiCabExpiry.updateValueAndValidity();

      this.formGroup.controls.preSheriffLicenseExpiry.clearValidators();
      this.formGroup.controls.preSheriffLicenseExpiry.updateValueAndValidity();
    }

    console.log('Form :', this.formGroup.value);
    console.log('Form Object :', this.formGroup);
    if (this.formGroup.invalid) {
      return;
    }

    console.log('Valid Form: ', this.formGroup.value);

    if (this.additionalInfoId) {
      
      if (!this.formGroup.value.newLicenseImage) {
        delete this.formGroup.value.newLicenseImage;
      }

      if (!this.formGroup.value.newLicenseBackImage) {
        delete this.formGroup.value.newLicenseBackImage;
      }

      // Optional (Taxi Card Front)
      if (this.additionalInfo.taxiCabImageURL) {
        // Image already exists

        // CASE: NO CHANGE
        if (this.taxiCabImageUrl && !this.formGroup.value.newTaxiCabImage) {
          delete this.formGroup.value.newTaxiCabImage;
        }

        // CASE: DELETE 
        if (!this.taxiCabImageUrl && !this.formGroup.value.newTaxiCabImage) {
          this.formGroup.addControl('taxiCabImageURL', new FormControl(''));
        }
      } else {
        // Image doesn't exist
        // CASE: NO CHANGE
        if (!this.formGroup.value.newTaxiCabImage) {
          delete this.formGroup.value.newTaxiCabImage;
        }
      }

      // Optional (Taxi Card Back)
      if (this.additionalInfo.taxiCabBackImageURL) {
        // Image already exists

        // CASE: NO CHANGE
        if (this.taxiCabBackImageUrl && !this.formGroup.value.newTaxiCabBackImage) {
          delete this.formGroup.value.newTaxiCabBackImage;
        }

        // CASE: DELETE 
        if (!this.taxiCabBackImageUrl && !this.formGroup.value.newTaxiCabBackImage) {
          this.formGroup.addControl('taxiCabBackImageURL', new FormControl(''));
        }
      } else {
        // Image doesn't exist
        // CASE: NO CHANGE
        if (!this.formGroup.value.newTaxiCabBackImage) {
          delete this.formGroup.value.newTaxiCabBackImage;
        }
      }

      // Optional (Sheriff License Front)
      if (this.additionalInfo.sheriffLicenseImageURL) {
        // Image already exists

        // CASE: NO CHANGE
        if (this.sheriffLicenseImageUrl && !this.formGroup.value.newSheriffLicenseImage) {
          delete this.formGroup.value.newSheriffLicenseImage;
        }

        // CASE: DELETE 
        if (!this.sheriffLicenseImageUrl && !this.formGroup.value.newSheriffLicenseImage) {
          this.formGroup.addControl('sheriffLicenseImageURL', new FormControl(''));
        }
      } else {
        // Image doesn't exist
        // CASE: NO CHANGE
        if (!this.formGroup.value.newSheriffLicenseImage) {
          delete this.formGroup.value.newSheriffLicenseImage;
        }
      }

      // Optional (Sheriff License Back)
      if (this.additionalInfo.sheriffLicenseBackImageURL) {
        // Image already exists

        // CASE: NO CHANGE
        if (this.sheriffLicenseBackImageUrl && !this.formGroup.value.newSheriffLicenseBackImage) {
          delete this.formGroup.value.newSheriffLicenseBackImage;
        }

        // CASE: DELETE 
        if (!this.sheriffLicenseBackImageUrl && !this.formGroup.value.newSheriffLicenseBackImage) {
          this.formGroup.addControl('sheriffLicenseBackImageURL', new FormControl(''));
        }
      } else {
        // Image doesn't exist
        // CASE: NO CHANGE
        if (!this.formGroup.value.newSheriffLicenseBackImage) {
          delete this.formGroup.value.newSheriffLicenseBackImage;
        }
      }

      if (!this.formGroup.value.newVehicleRegistrationImage) {
        delete this.formGroup.value.newVehicleRegistrationImage;
      }


      // Optional (Bank Voided Cheque ImageURL)
      if (this.additionalInfo.bankVoidedChequeImageURL) {
        // Image already exists

        // CASE: NO CHANGE
        if (this.bankVoidedChequeImageUrl && !this.formGroup.value.newBankVoidedChequeImageURL) {
          delete this.formGroup.value.newBankVoidedChequeImageURL;
        }

        // CASE: DELETE 
        if (!this.bankVoidedChequeImageUrl && !this.formGroup.value.newBankVoidedChequeImageURL) {
          this.formGroup.addControl('bankVoidedChequeImageURL', new FormControl(''));
        }
      } else {
        // Image doesn't exist
        // CASE: NO CHANGE
        if (!this.formGroup.value.newBankVoidedChequeImageURL) {
          delete this.formGroup.value.newBankVoidedChequeImageURL;
        }
      }

    } else {
      this.formGroup.value.driver = this.driverId;
    }

    // Deleting Pre variables
    delete this.formGroup.value.preLicenseExpiry;
    delete this.formGroup.value.preTaxiCabExpiry
    delete this.formGroup.value.preSheriffLicenseExpiry
    delete this.formGroup.value.preVehicleRegistrationExpiry

    this.driverService
      .saveDriverDocuments(this.additionalInfoId, this.formGroup.value)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data => {
            this.router.navigateByUrl('/drivers');
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
        }).then((data) => {
        });
      });
  }

  // License Image Front
  processLicenseImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.licenseImageFile = result.file;
        this.licenseImageUrl = result.src;
        this.formGroup.patchValue({
          newLicenseImage: this.licenseImageFile
        });
      } else {
        this.clearLicenseImage();
      }
    });
  }

  clearLicenseImage() {
    this.licenseImageFile = null;
    this.licenseImageUrl = '';
    this.formGroup.patchValue({
      newLicenseImage: null
    });

    if (this.additionalInfoId) {
      this.formGroup.controls.newLicenseImage.setValidators([Validators.required]);
      this.formGroup.controls.newLicenseImage.updateValueAndValidity();
    }
  }

  // License Image Back
  processLicenseBackImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.licenseBackImageFile = result.file;
        this.licenseBackImageUrl = result.src;
        this.formGroup.patchValue({
          newLicenseBackImage: this.licenseBackImageFile
        });
      } else {
        this.clearLicenseBackImage();
      }
    });
  }

  clearLicenseBackImage() {
    this.licenseBackImageFile = null;
    this.licenseBackImageUrl = '';
    this.formGroup.patchValue({
      newLicenseBackImage: null
    });

    if (this.additionalInfoId) {
      this.formGroup.controls.newLicenseBackImage.setValidators([Validators.required]);
      this.formGroup.controls.newLicenseBackImage.updateValueAndValidity();
    }
  }

  // Taxi Cab Image Front
  processTaxiCabImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.taxiCabImageFile = result.file;
        this.taxiCabImageUrl = result.src;
        this.formGroup.patchValue({
          newTaxiCabImage: this.taxiCabImageFile
        });
      } else {
        this.clearTaxiCabImage();
      }
    });
  }

  clearTaxiCabImage() {
    this.taxiCabImageFile = null;
    this.taxiCabImageUrl = '';
    this.formGroup.patchValue({
      newTaxiCabImage: null
    });
  }

  // Taxi Cab Image Back
  processTaxiCabBackImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.taxiCabBackImageFile = result.file;
        this.taxiCabBackImageUrl = result.src;
        this.formGroup.patchValue({
          newTaxiCabBackImage: this.taxiCabBackImageFile
        });
      } else {
        this.clearTaxiCabBackImage();
      }
    });
  }

  clearTaxiCabBackImage() {
    this.taxiCabBackImageFile = null;
    this.taxiCabBackImageUrl = '';
    this.formGroup.patchValue({
      newTaxiCabBackImage: null
    });
  }

  // Sheriff License Image Front
  processSheriffLicenseImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.sheriffLicenseImageFile = result.file;
        this.sheriffLicenseImageUrl = result.src;
        this.formGroup.patchValue({
          newSheriffLicenseImage: this.sheriffLicenseImageFile
        });
      } else {
        this.clearSheriffLicenseImage();
      }
    });
  }

  clearSheriffLicenseImage() {
    this.sheriffLicenseImageFile = null;
    this.sheriffLicenseImageUrl = '';
    this.formGroup.patchValue({
      newSheriffLicenseImage: null
    });
  }

  // Bank Voided Cheque Image Front
  processBankVoidedChequeImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.bankVoidedChequeImageFile = result.file;
        this.bankVoidedChequeImageUrl = result.src;
        this.formGroup.patchValue({
          newBankVoidedChequeImage: this.bankVoidedChequeImageFile
        });
      } else {
        this.clearBankVoidedChequeImage();
      }
    });
  }

  clearBankVoidedChequeImage() {
    this.bankVoidedChequeImageFile = null;
    this.bankVoidedChequeImageUrl = '';
    this.formGroup.patchValue({
      newBankVoidedChequeImage: null
    });
  }

  // Sheriff License Image Back
  processSheriffLicenseBackImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.sheriffLicenseBackImageFile = result.file;
        this.sheriffLicenseBackImageUrl = result.src;
        this.formGroup.patchValue({
          newSheriffLicenseBackImage: this.sheriffLicenseBackImageFile
        });
      } else {
        this.clearSheriffLicenseBackImage();
      }
    });
  }

  clearSheriffLicenseBackImage() {
    this.sheriffLicenseBackImageFile = null;
    this.sheriffLicenseBackImageUrl = '';
    this.formGroup.patchValue({
      newSheriffLicenseBackImage: null
    });
  }

  // Vehicle Registration Image
  processVehicleRegistrationImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.vehicleRegistraionImageFile = result.file;
        this.vehicleRegistraionImageUrl = result.src;
        this.formGroup.patchValue({
          newVehicleRegistrationImage: this.vehicleRegistraionImageFile
        });
      } else {
        this.clearVehicleRegistrationImage();
      }
    });
  }

  clearVehicleRegistrationImage() {
    this.vehicleRegistraionImageFile = null;
    this.vehicleRegistraionImageUrl = '';
    this.formGroup.patchValue({
      newVehicleRegistrationImage: null
    });

    if (this.additionalInfoId) {
      this.formGroup.controls.newVehicleRegistrationImage.setValidators([Validators.required]);
      this.formGroup.controls.newVehicleRegistrationImage.updateValueAndValidity();
    }
  }

  onDateChange(evt, control) {
    if(evt){
      control.setValue(moment(evt).set({hours:0,minutes:0,seconds:0}).toISOString());
    }
  }

  isDatePassed(date) {
    const isPassed = moment(date).isBefore(this.today);
    // console.log(date, this.today, isPassed);
    return isPassed;
  }

  clearTaxiCardExpiry() {
    this.formGroup.patchValue({
      preTaxiCabExpiry: '',
      taxiCabExpiry: ''
    });
  }

  clearSheriffLicenseExpiry() {
    this.formGroup.patchValue({
      preSheriffLicenseExpiry: '',
      sheriffLicenseExpiry: ''
    });
  }

  get form() {
    return this.formGroup.controls;
  }
}
