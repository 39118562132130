import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-details-modal',
  templateUrl: './transaction-details-modal.component.html',
  styleUrls: ['./transaction-details-modal.component.css']
})
export class TransactionDetailsModalComponent implements OnInit {
  fares;
  search;
  constructor() { }

  ngOnInit() {
  }

  close() {
  }

}
