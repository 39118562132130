import { Component, OnInit } from '@angular/core';
import { BrokersService } from '../service/brokers.service';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-brokers',
  templateUrl: './brokers.component.html',
  styleUrls: ['./brokers.component.css']
})
export class BrokersComponent implements OnInit {
  dropDownFilter = "active";
  search;
  brokers;
  user;

  constructor(  private sharedDataService: SharedDataService,
    private brokersService: BrokersService) { }

  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        console.log('Shared Data: ', data);
        this.user = data;
      }
    });
    this.getBrokers();
  }
  dropDownFilterChanged(event) {
    this.getBrokers();
  }

  getBrokers() {
    const queryParams = {
      status: this.dropDownFilter
    }
    this.brokersService
      .getBrokers(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.brokers = data;
        }else{
          this.brokers = [];
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

}
