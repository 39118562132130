import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { imageFileProcessing } from 'src/app/utils/image-processing-utils';
import Swal from 'sweetalert2';
import { PassengerService } from '../service/passenger.service';

@Component({
  selector: 'app-add-passenger',
  templateUrl: './add-passenger.component.html',
  styleUrls: ['./add-passenger.component.css']
})
export class AddPassengerComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  
  passengerId;

  profileImageFile;
  profileImageUrl;
  isPasswordShow = false;
  isShowPhoneFormats = false;
  phoneFormats = [
    '2124567890',
    '212-456-7890',
    '(212)456-7890',
    '(212)-456-7890',
    '212.456.7890',
    '212 456 7890',
    // '+12124567890',// maut par jati hai es format ko
    '+1 212.456.7890',
    // '+212-456-7890', // maut
    // '1-212-456-7890' // maut
  ]

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private passengerService: PassengerService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.passengerId = this.activatedRoute.snapshot.paramMap.get('id');

    this.formGroup = this.fb.group({
      displayName: ['', Validators.required],
      contactNumber: ['', [Validators.pattern(/^(\+\d{1,3}\s?)?(\(\d{3}\)|\d{3})[- .]?\d{3}[- .]?\d{4}$/),Validators.required]],

      email: ['', [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],

      address: ['', Validators.required],
      coords: [null, Validators.required], // This makes sure that the address being selected is valid.

      status: ['active', Validators.required],

      newProfilePicture: [null, Validators.required],
      deviceKey: ['000000000000000'],
      deviceType: ['web'],
      type: ['passenger', Validators.required]
    });

    if (this.passengerId) {
      this.getPassenger();
    }
  }

  getPassenger() {
    this.passengerService
      .getPassengerById(this.passengerId)
      .subscribe(data => {
        if (data) {
          delete data.password;
          this.profileImageUrl = data.profileImageURL;
          this.formGroup.patchValue({
            ...data,
            coords: [0,0]
          });

          // Password
          this.formGroup.controls.password.clearValidators();
          this.formGroup.controls.password.updateValueAndValidity();

          // Profile Image
          this.formGroup.controls.newProfilePicture.clearValidators();
          this.formGroup.controls.newProfilePicture.updateValueAndValidity();
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  submit() {
    this.isSubmitted = true;

    console.log('Form :', this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    console.log('Valid Form: ', this.formGroup.value);

    // Edit case checks
    if (this.passengerId) {
      if (!this.formGroup.value.password) {
        delete this.formGroup.value.password;
      }

      if (!this.formGroup.value.newProfilePicture) {
        delete this.formGroup.value.newProfilePicture;
      }
    }

    this.passengerService
      .savePassenger(this.passengerId, this.formGroup.value)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data =>{
            this.router.navigateByUrl('/passengers');
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
        }).then((data) => {
        });
      });
  }

  processProfileImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.profileImageFile = result.file;
        this.profileImageUrl = result.src;
        this.formGroup.patchValue({
          newProfilePicture: this.profileImageFile
        });
      } else {
        this.profileImageFile = null;
        this.profileImageUrl = '';
        this.formGroup.patchValue({
          newProfilePicture: null
        });
      }
    });
  }

  setAddress(place) {
    this.formGroup.patchValue({
      address: place.formatted_address,
      coords: [place.geometry.location.lng(), place.geometry.location.lat()]
    });
  }

  // onTypeChange(type) {
  //   console.log('OnChange Type: ', type);
  //   if (type === 'guest') {
  //     this.isGuestSelected = true;
  //     this.formGroup.controls.email.clearValidators();
  //     this.formGroup.controls.email.updateValueAndValidity();

  //     this.formGroup.controls.password.clearValidators();
  //     this.formGroup.controls.password.updateValueAndValidity();
  //   } else {
  //     this.isGuestSelected = false;

  //     this.formGroup.controls.email.setValidators([Validators.required]);
  //     this.formGroup.controls.email.updateValueAndValidity();

  //     this.formGroup.controls.password.setValidators([Validators.required]);
  //     this.formGroup.controls.password.updateValueAndValidity();
  //   }
  // }

  get form() {
    return this.formGroup.controls;
  }

}
