import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(private http: HttpClient) { }

  getVouchers(queryParams) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.SERVER_API_URL + urls.VOUCHERS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getVoucherById(voucherId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.VOUCHERS_URL + `/${voucherId}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveVoucher(voucherId, payload) {
    if (voucherId) {
      // Edit
      return this.http.put<any>(urls.SERVER_API_URL + urls.VOUCHERS_URL +`/${voucherId}`, payload)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      // Add
      return this.http.post<any>(urls.SERVER_API_URL + urls.VOUCHERS_URL, payload)
        .pipe(
          catchError(errorHandl)
        );
    }
  }
}
