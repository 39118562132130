import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { imageFileProcessing } from 'src/app/utils/image-processing-utils';
import Swal from 'sweetalert2';
import { StaffService } from '../service/staff.service';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.css']
})
export class AddStaffComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  staffId;
  imageFile;
  imageUrl;
  isPasswordShow = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private staffService: StaffService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.staffId = this.route.snapshot.paramMap.get('id');

    this.formGroup = this.fb.group({
      displayName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      roles: ['cadmin', Validators.required],
      status: ['active', Validators.required],
      newProfilePicture: [null, Validators.required]
    });

    if (this.staffId) {
      this.getStaff();
    }
  }

  getStaff() {
    this.staffService
      .getStaffById(this.staffId)
      .subscribe(data => {
        if (data) {
          delete data.password;
          this.imageUrl = data.profileImageURL;
          this.formGroup.patchValue(data);
          console.log('Data Patched: ', this.formGroup.value);
          
          // Password
          this.formGroup.controls.password.clearValidators();
          this.formGroup.controls.password.updateValueAndValidity();


          // Profile Image
          this.formGroup.controls.newProfilePicture.clearValidators();
          this.formGroup.controls.newProfilePicture.updateValueAndValidity();

        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  submit() {
    this.isSubmitted = true;
    console.log('Form: ', this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    this.formGroup.patchValue({
      roles: [this.formGroup.value.roles]
    });

    console.log('Valid Form: ', this.formGroup.value);

    // Edit case checks
    if (this.staffId) {
      if (!this.formGroup.value.password) {
        delete this.formGroup.value.password;
      }

      if (!this.formGroup.value.newProfilePicture) {
        delete this.formGroup.value.newProfilePicture;
      }
    }

    this.staffService
      .saveStaff(this.staffId, this.formGroup.value)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data =>{
            this.router.navigateByUrl('/staff');
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
        }).then((data) => {
        });
      });
  }

  processImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      if (result) {
        this.imageFile = result.file;
        this.imageUrl = result.src;
        this.formGroup.patchValue({
          newProfilePicture: this.imageFile
        });
      } else {
        this.clearImage();
      }
    });
  }

  clearImage() {
    this.imageFile = null;
    this.imageUrl = '';
    this.formGroup.patchValue({
      newProfilePicture: null
    });
  }

  get form() {
    return this.formGroup.controls;
  }
}
