import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment-timezone";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { exportToCsv, getRideStatusLabel, splitString } from "src/app/utils/common-utils";
import Swal from "sweetalert2";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";
import { RideService } from '../../rides/service/ride.service';
import { EarningsService } from '../service/earnings.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TimezonePipe } from 'src/app/pipes/timezone-pipe/timezone.pipe';
import { TitleCasePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-business-report',
  templateUrl: './business-report.component.html',
  styleUrls: ['./business-report.component.css']
})
export class BusinessReportComponent implements OnInit {
  search;
  rides;
  timeZone = "America/New_York";
  user;
  preDateRange = [
    moment().tz(this.timeZone).startOf('day').toISOString(),
    moment().tz(this.timeZone).endOf('day').toISOString()
  ];
  dateRange = [
    moment().tz(this.timeZone).startOf('day').toISOString(),
    moment().tz(this.timeZone).endOf('day').toISOString()
  ];

  constructor(
    private earningService: EarningsService,
    private sharedDataService: SharedDataService,
    private ngxLoader: NgxUiLoaderService,
    private datePipe: TimezonePipe,
    private titlecasePipe: TitleCasePipe,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
        this.timeZone = data.company.timeZone;
        this.preDateRange = [
          moment().tz(this.timeZone).startOf('day').toISOString(),
          moment().tz(this.timeZone).endOf('day').toISOString()
        ];
        this.dateRange = [
          moment().tz(this.timeZone).startOf('day').toISOString(),
          moment().tz(this.timeZone).endOf('day').toISOString()
        ];
        this.getBusinessReport();
      }
    });
  }

  getBusinessReport() {
    this.rides = null;

    const queryParams = {
      startDate: this.dateRange[0],
      endDate: this.dateRange[1]
    };

    this.earningService
      .getBusinessReport(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.rides = data;
          // this.rides.sort((a, b) => <any>new Date(b.scheduleTime) - <any>new Date(a.scheduleTime));
        }
      },
        (err) => {
          Swal.fire({
            title: "Alert",
            html: "Operation failed",
            icon: "error",
            confirmButtonColor: "#F1A303",
          }).then((data) => { });
        }
      );
  }

  onSearchDateChange(evt) {
    this.dateRange = [moment(evt[0]).tz(this.timeZone, true).toISOString(), moment(evt[1]).tz(this.timeZone, true).toISOString()];
    this.getBusinessReport();
  }

  getStatusLabel(status) {
    return getRideStatusLabel(status)
  }

  getSplittedString(str) {
    return splitString(str, '-');
  }

  async downloadCsvReport() {
    this.ngxLoader.start();
    const payload = [
      [
        "Ride Id",

        "Passenger Name",
        "Passenger Contact",
        "Passenger Email",

        "Driver Name",
        "Driver Contact",
        "Driver Email",

        "Schedule Time",
        "Pickup",
        "Drop Off",
        "Miles",

        "Passenger Wait Time",
        "Passenger Wait Time Fare",
        "Ride Miles",
        "Ride Mins",
        "Per Min Fare",
        "Extra BaseMile Fare",
        "Ride Fee",
        "Total",
        "Driver Earning",
        "Air Port",
        "Toll Fare",
        "Tip",
        "Discount",
        "Processing Fee",
        "Service Fee",
        "UTWSD Fee",
        "Tolls",

        "Status",
        "Created By"
      ]
    ];

    this.rides.forEach(ride => {
      let rideDetails = ride.rides;
      const passenger = ride.passenger;
      const driver = ride.driver;
      let scheduleTime = '';

      if (this.user && this.user.company) {
        const dateFormat = this.user.company.dateFormat;
        const timeFormat = this.user.company.timeFormat;
        const timeZone = this.user.company.timeZone;
        scheduleTime = this.datePipe.transform(rideDetails.scheduleTime, dateFormat, this.timeZone) + ' ' +
        this.datePipe.transform(rideDetails.scheduleTime, timeFormat, this.timeZone);
      }

      const status = this.titlecasePipe.transform(this.getStatusLabel(rideDetails.status)) + (rideDetails.isRunner ? ' (Runner)' : '');
      let tolls = '';
      if (rideDetails.fare && rideDetails.fare.tolls && rideDetails.fare.tolls.length) {
        rideDetails.fare.tolls.forEach((toll, index) => {
          Object.keys(toll).forEach(type => {
            const str = `[T${index+1}] ${type}: ${toll[type]}`
            if (tolls) {
              tolls += ',' + str;
            } else {
              tolls = str;
            }
          });
        });
      }

      const row = [
        rideDetails.rideId,

        passenger ? passenger.displayName : '',
        passenger ? passenger.contactNumber : '',
        passenger ? passenger.email : '',

        driver ? driver.displayName : '',
        driver ? driver.contactNumber : '',
        driver ? driver.email : '',

        scheduleTime,
        rideDetails.locations && rideDetails.locations.length ? rideDetails.locations[0].address : '',
        rideDetails.locations && rideDetails.locations.length ? rideDetails.locations[1].address : '',
        this.decimalPipe.transform(rideDetails.miles, '1.2-2'),

        this.decimalPipe.transform(rideDetails.fare.passengerWaitTime, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.passengerWaitTimeFare, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.rideMiles, '1.2-2'),
        rideDetails.fare.rideMins,
        this.decimalPipe.transform(rideDetails.fare.perMinFare, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.extraBaseMileFare, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.rideFee, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.total, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.driverEarning, '1.2-2'),
        rideDetails.fare.isAirPort ? 'Yes' : '',
        this.decimalPipe.transform(rideDetails.fare.tollFare, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.tip, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.discount, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.processingFee, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.serviceFee, '1.2-2'),
        this.decimalPipe.transform(rideDetails.fare.utwsdFee, '1.2-2'),
        tolls,
        status,
        this.titlecasePipe.transform(this.getSplittedString(rideDetails.createdBy))
      ];
      payload.push(row);
    });
    exportToCsv(`business-report-${moment(this.dateRange[0]).format("MM-DD-YYYY") + '_' + moment(this.dateRange[1]).format("MM-DD-YYYY")}.csv`, payload);
    this.ngxLoader.stop();
  }
}
