import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private http: HttpClient) { }

  getStaff(queryParams) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.SERVER_API_URL + urls.STAFF, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  saveStaff(staffId, payload) {
    const formData = setFormData(payload);

    if (staffId) {
      // Edit
      return this.http.put<any>(urls.SERVER_API_URL + urls.STAFF +`/${staffId}`, formData)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      // Add
      return this.http.post<any>(urls.SERVER_API_URL + urls.STAFF, formData)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  getStaffById(staffId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.STAFF + `/${staffId}`)
      .pipe(
        catchError(errorHandl)
      );
  }
}
