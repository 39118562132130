import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class EarningsService {

  constructor(private http: HttpClient) { }

  getBusinesReport(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.SERVER_API_URL + urls.BUSSINESS_REPORTS, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getBillingsReport(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.SERVER_API_URL + urls.BILLINGS_REPORTS, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getEarnings(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.SERVER_API_URL + urls.REPORTS, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getWallets() {
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.WALLETS)
      .pipe(catchError(errorHandl));
  }

  getWalletHistory(id) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.WALLET_TRANSACTIONS + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  settlePayments(payload) {
    return this.http.post<any>(urls.SERVER_API_URL + urls.BILLINGS_REPORTS, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getBusinessReport(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.SERVER_API_URL + urls.KPI_REPORTS, { params })
      .pipe(
        catchError(errorHandl)
      );
  }
}
