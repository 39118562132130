import { Component, OnInit } from '@angular/core';
import { getRoles } from 'src/app/utils/common-utils';
import Swal from 'sweetalert2';
import { VoucherService } from '../service/voucher.service';
import { StaffService } from '../../staff/service/staff.service';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { ZonesService } from '../../zones/service/zones.service';

@Component({
  selector: 'app-vouchers-list',
  templateUrl: './vouchers-list.component.html',
  styleUrls: ['./vouchers-list.component.css']
})
export class VouchersListComponent implements OnInit {
  search;
  vouchers = [];
  dropDownFilter = "active";
  user;
  zonesById;

  constructor(
    private voucherService: VoucherService,
    private zonesService: ZonesService, 
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.getUser()
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(data => {
        if (data && data.company) {
          this.user = data;
          this.getZones();
          this.getVouchers();
        }
      });
  }

  getZones() {
    this.zonesService
      .getZones()
      .subscribe(data => {
        if (data && data.length) {
          this.zonesById = data.reduce((obj, item) => (obj[item._id] = item, obj) ,{});
          console.log('ZonesById:', this.zonesById);
        }
      });
  }

  getVouchers() {
    const queryParams = {
      status: this.dropDownFilter
    }
    this.voucherService
      .getVouchers(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.vouchers = data;
        }
      });
  }

  getZonesLabels(zones) {
    return zones.map(zone => this.zonesById[zone].name)
  }

  onStatusChange(event) {
    this.getVouchers();
  }
}
