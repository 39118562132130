import { Component, OnInit } from '@angular/core';
import { EarningsService } from '../service/earnings.service';
import * as moment from "moment-timezone";
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';
import { DriverService } from '../../drivers/service/driver.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { singleDriverEarning } from 'src/app/utils/report/driver-earning';
import { generatePdf, getRideStatusLabel, splitString } from "src/app/utils/common-utils";

@Component({
  selector: 'app-billings',
  templateUrl: './billings.component.html',
  styleUrls: ['./billings.component.css']
})
export class BillingsComponent implements OnInit {
  earnings;
  rides;
  timeZone = "America/New_York";
  today = moment().tz(this.timeZone).startOf("day");
  startDate = this.today.toISOString();
  user;
  drivers = [];
  selectedDriver;
  preDateRange = [
    moment().tz(this.timeZone).startOf('day').toISOString(),
    moment().tz(this.timeZone).endOf('day').toISOString()
  ];
  dateRange = [
    moment().tz(this.timeZone).startOf('day').toISOString(),
    moment().tz(this.timeZone).endOf('day').toISOString()
  ];

  i = 0;
  j = 0;
  fromDate = moment().tz(this.timeZone, true).startOf('isoWeek');
  toDate = moment().tz(this.timeZone, true).endOf('isoWeek');
  isSettlementDay = false;
  bankTransactionModalObj;
  search;
  
  constructor(
    private earningService: EarningsService,
    private driverService: DriverService,
    private sharedDataService: SharedDataService,
    private ngxLoader: NgxUiLoaderService,
  ) { }

  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
        this.timeZone = data.company.timeZone;
        this.today = moment().tz(this.timeZone).startOf("day");
        this.startDate = this.today.toISOString();
        this.currentWeek();
      }
    });

    this.getDrivers();
  }

  getEarnings() {
    this.earnings = null;
    this.rides = null;

    let queryParams = {
      startDate: this.fromDate.toISOString(),
      endDate: this.toDate.toISOString()
    };

    if (this.selectedDriver) {
      queryParams["driver"] = this.selectedDriver._id;
    }

    this.earningService.getBillingsReport(queryParams).subscribe(
      (data) => {
        if (data && data.length) {
          console.log("Earnings: ", data);
          this.earnings = data[0];
          if (this.earnings.details && this.earnings.details.length) {
            this.rides = this.earnings.details;
            this.rides.sort((a, b) => <any>new Date(b.scheduleTime) - <any>new Date(a.scheduleTime));
          }
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  getDrivers() {
    const queryParams = {
      status: 'active'
    }

    this.driverService
      .getDrivers(queryParams).
      subscribe(data => {
        this.drivers = data;
      });
  }

  onSearchDateChange(evt) {
    console.log(evt);
    console.log(evt[0].toISOString(), evt[1].toISOString());
    // this.startDate = moment(evt).tz(this.timeZone, true).toISOString();
    this.dateRange = [moment(evt[0]).tz(this.timeZone, true).toISOString(), moment(evt[1]).tz(this.timeZone, true).toISOString()];
    console.log(this.dateRange);
    if (this.selectedDriver) {
      this.getEarnings();
    }
  }

  onDriverChange(driver) {
    if (driver) {
      this.selectedDriver = driver;
      this.getEarnings();
    }
  }

  async onReportClick() {
    this.ngxLoader.start();
    console.log(this.selectedDriver);
    console.log(this.startDate);
    console.log(this.earnings);

    const detailsObject = await singleDriverEarning(this.selectedDriver, this.startDate, this.earnings).catch(
      data => {
        this.ngxLoader.stop();
      }
    );
    console.log("data -----> ", JSON.stringify(detailsObject));
    generatePdf(detailsObject);
    this.ngxLoader.stop();
  }

  getSplittedString(str) {
    return splitString(str, '-');
  }
  getStatusLabel(status) {
    return getRideStatusLabel(status)
  }

  currentWeek() {
    this.fromDate = moment().tz(this.timeZone, true).startOf('isoWeek').add(4, 'days');
    this.toDate = moment().tz(this.timeZone, true).endOf('isoWeek').add(4, 'days');
    this.isSettlementDay = moment().tz(this.timeZone, true).isAfter(this.toDate, 'day');
    // this.getWeeklyEarnings();
  }
  lastWeek() {
    this.i -= 1;
    this.j += 1;
    this.fromDate = moment().tz(this.timeZone, true).subtract(this.j, 'weeks').startOf('isoWeek').add(4, 'days');
    this.toDate = moment().tz(this.timeZone, true).subtract(this.j, 'weeks').endOf('isoWeek').add(4, 'days');
    this.getWeeklyEarnings();
  }

  nextWeek() {
    this.i += 1;
    this.j -= 1;
    this.fromDate = moment().tz(this.timeZone, true).add(this.i, 'weeks').startOf('isoWeek').add(4, 'days');
    this.toDate = moment().tz(this.timeZone, true).add(this.i, 'weeks').endOf('isoWeek').add(4, 'days');
    this.getWeeklyEarnings();
  }

  getWeeklyEarnings() {
    // this.dateRange = [this.fromDate.toISOString(), this.toDate.toISOString()];
    // this.isSettlementDay = moment().tz(this.timeZone, true).isSame(this.toDate, 'day');
    this.isSettlementDay = moment().tz(this.timeZone, true).isAfter(this.toDate, 'day');
    if (this.selectedDriver) {
      this.getEarnings();
    }
  }

  askSettlePayments() {
    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to settle the payments?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: "#F1A303",
    }).then((data) => {
      if (data.isConfirmed) {
        this.settlePayments();
      }
    });
  }

  settlePayments() {
    let payload;
    let rideIds;
    if (this.earnings && this.earnings.details && this.earnings.details.length) {
      rideIds = this.earnings.details.map(t => t._id);
      payload = {
        rideIds
      }
      this.earningService
        .settlePayments(payload)
        .subscribe(data => {
          if (data) {
            this.getEarnings();
          }
        });
    }
  }

  transformSign(amount) {
    return Math.abs(amount);
  }

  getSettlementStateMessage(isSettled, amount) {
    amount = Math.abs(amount).toFixed(2);
    let message;
    if (isSettled) {
      message = `Driver paid $${amount} as settlement`;
      if (amount > 0) {
        message = `Ride United paid $${amount} as settlement`;
      }
    } else {
      message = `Driver owes Ride United`;
      if (amount > 0) {
        message = `Ride United owes driver`;
      }
    }
    return message;
  }

  openBankTransactionModal() {
    this.bankTransactionModalObj = this.earnings;
  }

  onCloseTransactionModal(evt) {
    this.bankTransactionModalObj = null;
    if (evt) {
      this.getEarnings();
    }
  }

}

