import { SharedDataService } from "./../../../services/shared-service/shared-data.service";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MenuService } from "./menu-service/menu.service";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { RideService } from "../../rides/service/ride.service";
import * as moment from "moment-timezone";
import { Events } from "src/app/utils/event-utils";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { SupportService } from "../../support/service/support.service";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.css"],
})
export class SideNavComponent implements OnInit {
  menuList;
  menuState;
  user;
  isFirstTime = true;
  showMenu = false;
  timeZone = "America/New_York";
  today = moment().tz(this.timeZone).startOf("day");
  scheduleTime = this.today.toISOString();
  rideCounts;
  queryCounts;

  constructor(
    public sanitizer: DomSanitizer,
    private menuService: MenuService,
    private sharedDataService: SharedDataService,
    private router: Router,
    private rideService: RideService,
    private supportService: SupportService,
    private socketService: SocketService
  ) {
    this.routerListener();
  }

  routerListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split("/");
        if (this.isFirstTime) {
          this.menuState = url[1];
          if (url.length > 1 && url[1] !== "") {
            this.menuState = url[1] === "home" ? "dashboard" : url[1];
          } else {
            this.menuState = "dashboard";
          }
          this.getUserAndMenu();
        }
        this.isFirstTime = false;
      }
    });
  }

  getUserAndMenu() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
        if (data.company && data.company.timeZone) {
          this.timeZone = data.company.timeZone;
          this.today = moment().tz(this.timeZone).startOf("day");
          this.scheduleTime = this.today.toISOString();
        }
        this.menuList = this.menuService.getMenu(data.roles[0]);
        for (var key of Object.keys(this.menuList)) {
          if (this.menuList[key]["state"] === this.menuState) {
            this.menuList[key]["isActive"] = true;
          }
        }
      } else {
        this.menuList = [];
      }
    });
  }
  ngOnInit() {
    this.getMenuState();
    this.getRideCounts();
    this.getQueriesCountlist();
    this.getSupportUpdate();

    this.socketService
      .addListener(Events.GET_NOTIFICATIONS)
      .subscribe((data) => {
        console.log(Events.GET_NOTIFICATIONS, data);
        if (data.notification && data.notification.type === "Support") {
          this.getQueriesCountlist();
        } else if (data.notification && data.notification.type === "Driver") {
          // will logic add later.
        } else {
          this.getRideCounts();
        }
      });
  }

  getRideCounts() {
    const queryParams = {
      scheduleTime: this.scheduleTime,
    };
    this.rideService.getRideCounts(queryParams).subscribe((data) => {
      console.log("Ride Counts: ", data);
      if (data && data.length) {
        this.rideCounts = data[0];
      }
    });
  }

  getQueriesCountlist() {
    this.supportService.getQueriesCount().subscribe((data) => {
      console.log("Query Counts: ", data);
      if (data && data.length) {
        this.queryCounts = data[0];
      }
    });
  }

  getMenuState() {
    this.sharedDataService.getMenuState().subscribe((data) => {
      this.showMenu = data;
    });
  }

  sortMethod(a, b) {
    return a.value.position > b.value.position ? 1 : -1;
  }

  openSubMenu(menu) {
    console.log(menu.value.state);
    console.log(menu.value.isActive);
    this.menuState = menu.value.state;
    menu.value.isActive = !menu.value.isActive;
  }

  closeMenu() {
    this.sharedDataService.toggleMenu();
  }

  getProfileInf() {
    let profile;
    if (this.user.company) {
      return this.user.company;
    } else {
      return this.user;
    }
  }

  getCountByName(name) {
    return this.rideCounts[name.toLowerCase()];
  }
  getQueryCountByName(name) {
    return this.queryCounts[name.toLowerCase()];
  }

  getSupportUpdate() {
    this.sharedDataService
      .getSupportUpdate()
      .subscribe(data => {
        if (data) {
          this.getQueriesCountlist();
        }
      });
  }
}
