import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { DriverService } from '../../drivers/service/driver.service';

@Component({
  selector: 'app-driver-dropdown',
  templateUrl: './driver-dropdown.component.html',
  styleUrls: ['./driver-dropdown.component.css']
})
export class DriverDropdownComponent implements OnInit {
  items: any
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  selectedItem;
  showList = false;
  inputSearch = '';
  @ViewChild('searchInput', null) input: ElementRef;

  constructor(
    private driverService: DriverService
  ) { }

  ngOnInit() {
    this.getPassengers();
  }

  getPassengers() {
    const queryParams = {
      status: "active",
    };
    this.driverService
      .getDrivers(queryParams)
      .subscribe((data) => {
        if (data) {
          this.items = data;
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  onItemSelect(selectedItem) {
    this.showList = false;
    this.selectedItem = selectedItem;
    this.inputSearch = this.selectedItem.displayName;
    this.onSelect.emit(this.selectedItem);
    console.log(this.selectedItem);
  }

  toggleList() {
    this.showList = true;
  }

  clear() {
    this.selectedItem = null;
    this.inputSearch = '';
    this.showList = false;
    this.input.nativeElement.focus();
  }
}
