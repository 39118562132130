import { TimeagoFormatter } from 'ngx-timeago';
import * as moment from "moment-timezone";

export class CustomFormatter extends TimeagoFormatter {
    format(then: number): string {
        let val = '';
        let timestamp = moment(then);
        let now = moment()
        let duration = moment.duration(now.diff(timestamp));
        // var hours = duration.asHours();
        var mins = duration.asMinutes();
        if (mins > 2) {
            val = moment(then).format('MM/DD/YYYY HHmm');
        } else {
            val = timestamp.fromNow(true);
        }

        return val.toString();
    }
}
