import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class RideService {

  constructor(private http: HttpClient) { }

  getRides(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });

    return this.http.get<any>(urls.SERVER_API_URL + urls.RIDES, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getRideById(rideId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.RIDES + `/${rideId}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  getRideTrail(rideId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.RIDE_TRAIL + `/${rideId}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  getRideDetails(rideId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.RIDES + `/company/${rideId}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveRide(rideId, payload) {
    if (rideId) {
      // Edit
      return this.http.put<any>(urls.SERVER_API_URL + urls.RIDES + `/${rideId}`, payload)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      // Add
      return this.http.post<any>(urls.SERVER_API_URL + urls.RIDES, payload)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  acceptRide(rideId, payload) {
    return this.http.put<any>(urls.SERVER_API_URL + urls.RIDES_OFFERED + `/${rideId}`, payload)
        .pipe(
          catchError(errorHandl)
        );
  }

  getFareEstimation(payload) {
    return this.http.post<any>(urls.SERVER_API_URL + urls.GET_FAREESTIMATION, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  search(payload) {

    return this.http.post<any>(urls.SERVER_API_URL + urls.SEARCH, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  assignDriver(rideId, payload) {
    return this.http.put<any>(urls.SERVER_API_URL + urls.RIDES + `/${rideId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getRideCounts(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.SERVER_API_URL + urls.COUNT_RIDES, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getHangingStackRides(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });

    return this.http.get<any>(urls.SERVER_API_URL + urls.RIDES, { params })
      .pipe(
        catchError(errorHandl)
      );
  }
}
