import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData, setFormDataV2 } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  getCompanies(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.SERVER_API_URL + urls.COMPANIES, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getCompanyById(companyId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.COMPANIES + '/' + companyId)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveCompany(companyId, payload) {
    // const formData = setFormData(payload);
    const formData = setFormDataV2(payload);
    console.log('Company FormData: ', formData);
    if (companyId) {
      return this.http.put<any>(urls.SERVER_API_URL + urls.COMPANIES +`/${companyId}`, formData)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      return this.http.post<any>(urls.SERVER_API_URL + urls.COMPANIES, formData)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  getCompanyConfigurations(companyId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.COMPANY_CONFIGS + `/${companyId}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveCompanyConfigurations(companyId, payload) {
    if (companyId) {
      return this.http.put<any>(urls.SERVER_API_URL + urls.COMPANY_CONFIGS +`/${companyId}`, payload)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      return this.http.post<any>(urls.SERVER_API_URL + urls.COMPANY_CONFIGS, payload)
        .pipe(
          catchError(errorHandl)
        );
    }
  }
}
