import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastService } from './toastService/toast.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';

@Component({
  selector: 'app-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.css']
})
export class ToastMessagesComponent implements OnInit,OnDestroy {

  messages = [];
  subscription: Subscription;
  settingInterval;

  constructor(private toast: ToastService) { }

  ngOnInit() {
   this.toast.getMessages()
     this.subscription =this.toast
     .getMessages()
      .subscribe((messages) => {
        // console.log(messages);
        this.messages  = messages;
      });

      this.settingInterval = setInterval(async () => {
        // console.log(this.messages)
        if(this.messages && this.messages.length){
          const index = this.messages.findIndex(item => !item.isSticky)
          if(index > -1)
            this.toast.dismissMessage(index);
        }
      }, 5000);

  }

  dismiss(index) {
    if (this.messages[index] && this.messages[index].isSticky) {
      this.toast.sendAcknowledgement(this.messages[index]);
    }
    this.toast.dismissMessage(index);
  }

  ngOnDestroy(): void {
    clearInterval(this.settingInterval);
  }
  formatText(text) {
    let finalText = "";
    let firstQoute = false;
    for (let i = 0; i < text.length; i++) {
      if (text.charAt(i) === "'") {
        let tempText = '<span class="font-semibold text-gray-900">';
        if (firstQoute) {
          tempText = "</span>";
          firstQoute = false;
        } else {
          firstQoute = true;
        }
        tempText += text.charAt(i);
        finalText += tempText;
      } else {
        finalText += text.charAt(i);
      }
    }

    return finalText;
  }


}
