import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { getRideStatusLabel, googleImageURL, splitString } from 'src/app/utils/common-utils';
import Swal from 'sweetalert2';
import { RideService } from '../../rides/service/ride.service';

@Component({
  selector: 'app-ride-details-modal',
  templateUrl: './ride-details-modal.component.html',
  styleUrls: ['./ride-details-modal.component.css']
})
export class RideDetailsModalComponent implements OnInit {
  @Input() rideId: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  ride;
  user;
  lat = 0;
  lng = 0;
  breakdownTotal;
  adjustmentFare = 0;
  rideEvents;
  rideTrailObj;

  constructor(
    private rideService: RideService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {

    this.sharedDataService.getUser().subscribe((data) => {
      console.log("getUser ---> ", data);
      if (data) {
        this.user = data;
      } else {
        this.user = null;
      }
    });
    this.getRideDetails();
  }

  getRideDetails() {
    this.rideService
      .getRideDetails(this.rideId)
      .subscribe(data => {
        if (data) {
          this.ride = data;
          if (this.ride.events && this.ride.events.length) {
            this.rideEvents = this.ride.events.reverse();
          }
          if (this.ride.breakdown && this.ride.breakdown.length) {
            this.breakdownTotal = this.ride.breakdown.shift();
            this.calculateAdjustment();
            // console.log('Shift ', this.breakdownTotal, this.ride.breakdown);
          }
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  googleImageUrl(event) {
    const {latitude, longitude, imageURL} = event;
    if (imageURL){
      return imageURL;
    }
    return googleImageURL(latitude, longitude);
  }
  getStatusLabel(status) {
    return getRideStatusLabel(status)
  }

  calculateAdjustment() {
    const total = (this.breakdownTotal && this.breakdownTotal.value) ? parseFloat(this.breakdownTotal.value) : 0;
    const breakdown = this.ride.breakdown;
    let breakdownTotal = 0;

    if (breakdown && breakdown.length) {
      breakdown.forEach(fare => {
        breakdownTotal = breakdownTotal + parseFloat(fare.value)
      });
    }

    this.adjustmentFare = Math.abs(total - breakdownTotal);
    console.log('Adjustment Fare ', this.adjustmentFare);
  }

  getCreatedBy(str) {
    return splitString(str,'-');
  }

  viewRideTrail() {
    if (!this.rideId) return;
    const data = {
      events: this.rideEvents,
      rideId: this.rideId
    }
    this.rideTrailObj = data;
  }

  closeTrailModal() {
    this.rideTrailObj = null;
  }

  close() {
    if (this.rideTrailObj) return;
    this.onClose.emit(null);
  }
}
