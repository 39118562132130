import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { CompanyService } from '../service/company.service';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.css']
})
export class CompaniesListComponent implements OnInit {
  companies;
  dropDownFilter = "active";
  constructor(
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    const queryParams = {
      status: 'active'
    }
    this.companyService
      .getCompanies(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.companies = data;
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      })
  }
  dropDownFilterChanged(event) {
  }

}
