import { Component, OnInit } from '@angular/core';
import { getRoles } from 'src/app/utils/common-utils';
import Swal from 'sweetalert2';
import { StaffService } from '../service/staff.service';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.css']
})
export class StaffListComponent implements OnInit {
  search;
  staffList;
  dropDownFilter = "active";

  constructor(
    private staffService: StaffService
  ) { }

  ngOnInit() {
    this.getStaff();
  }

  getStaff() {
    const queryParams = {
      status: this.dropDownFilter
    }
    this.staffService
      .getStaff(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.staffList = data;
        }else{
          this.staffList = [];
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  getRoles(roles) {
    return getRoles(roles);
  }

  dropDownFilterChanged(event) {
    console.log('----> ', this.dropDownFilter);
    this.getStaff();
  }
}
