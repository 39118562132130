import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleService } from '../../vehicles/service/vehicle.service';
import { FareService } from '../service/fare.service';
import Swal from 'sweetalert2'
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';

@Component({
  selector: 'app-add-dynamic-fare',
  templateUrl: './add-dynamic-fare.component.html',
  styleUrls: ['./add-dynamic-fare.component.css']
})
export class AddDynamicFareComponent implements OnInit {
  vehicles;
  formGroup: FormGroup;
  isSubmitted = false;
  fareId;
  oldFare;
  updatedFare;
  user;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private vehicleService: VehicleService,
    private fareService: FareService,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (!(state && state.isAuthorized)) {
      this.router.navigateByUrl("/dynamic-fares");
    }
  }

  ngOnInit() {
    this.fareId = this.activatedRoute.snapshot.paramMap.get('id');

    this.formGroup = this.fb.group({
      vehicle: ['', Validators.required],
      fare: [0, Validators.required],
      for: [0, [Validators.required]],
      perMile: [0, Validators.required],
      perMin: [0, Validators.required],
      cancelRate: [0, Validators.required],
      insuranceFee: [0, Validators.required],
      serviceFee: [0, Validators.required],
      airportFee: [0, Validators.required],
      freeWaitMinsQuota: [0, Validators.required],
      minimumFee: [0, Validators.required],
      utwsdFee: [0, Validators.required],
      isPercentage: [false],
      percentageValue: [""],
      status:['active'],
      type: ['normal']
    });

    this.formGroup.get('isPercentage').valueChanges.subscribe(val => {
      if (val) {
        this.formGroup.get('percentageValue').setValidators(Validators.required)
      } else {
        this.formGroup.get('percentageValue').clearValidators()
      }
      this.formGroup.get('percentageValue').updateValueAndValidity()
    });


    this.getUser();
    this.getVehicles();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe((data) => {
        if (data) {
          this.user = data;
        }
      });
  }

  submit() {
    this.isSubmitted = true;

    console.log('Form: ', this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    console.log('Valid Form: ', this.formGroup.value);

    const payload = {
      ...this.formGroup.value,
      baseFare: {
        fare: this.formGroup.value.fare,
        for: this.formGroup.value.for,
      },
    }
    this.fareService
      .saveFare(this.fareId, payload)
      .subscribe(data => {
        if (data) {
          this.updatedFare = data;
          if (environment.production) {
            this.saveFareLog();
          }
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data => {
            this.router.navigateByUrl('/dynamic-fares');
          })

        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });

  }

  get form() {
    return this.formGroup.controls;
  }

  getVehicles() {
    const queryParams = {
      status: 'active'
    }
    this.vehicleService
      .getVehicles(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.vehicles = data;
          if (this.fareId) {
            this.getFareDetails();
          }
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  getFareDetails() {
    this.fareService
      .getFareById(this.fareId)
      .subscribe(data => {
        if (data) {
          this.oldFare = data;
          this.formGroup.patchValue({
            ...data,
            fare: data.baseFare.fare,
            for: data.baseFare.for
          });
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  saveFareLog() {
    console.log(this.oldFare, this.updatedFare);
    delete this.oldFare.updatedAt;
    delete this.updatedFare.updatedAt;
    if (JSON.stringify(this.oldFare) !== JSON.stringify(this.updatedFare)) {
      const vehicle = this.vehicles.find(v => v._id === this.updatedFare.vehicle);
      this.updatedFare.vehicle = vehicle && vehicle.name ? vehicle.name : 'N/A';
      this.updatedFare['username'] = this.user && this.user.displayName ? this.user.displayName : 'N/A';
      this.fareService
        .saveFareLog(this.updatedFare)
        .subscribe(data => {
          if (data) {
            console.log('Log Saved: ', data);
          }
        });
    }
  }

  onServiceFeePerc(event){
    console.log(event.target.value, typeof event.target.value )
    const isServiceFeePercentage = event.target.value === "true"? true: false;
    this.formGroup.patchValue({
      isPercentage: isServiceFeePercentage
    });
    if (isServiceFeePercentage) {
      this.formGroup.patchValue({
        utwsdFee: 50,
        serviceFee: 50
      });
    } else {
      this.formGroup.patchValue({
        utwsdFee: 0.5,
        serviceFee: 1
      });
    }
  }

  onChangeUTWSDFee(value) {
    if (!this.formGroup.value.isPercentage) {
      return;
    }
    console.log('onChangeUTWSDFee ', isNaN(value));
    if (isNaN(value)) {
      return;
    }
    this.formGroup.patchValue({
      serviceFee: 100 - value
    });
  }

  onChangeServiceFee(value) {
    if (!this.formGroup.value.isPercentage) {
      return;
    }
    console.log('onChangeServiceFee ', value);
    if (isNaN(value)) {
      return;
    }
    this.formGroup.patchValue({
      utwsdFee: 100 - value
    });
  }
}
