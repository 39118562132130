import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { downloadImage, downloadImage2 } from "src/app/utils/network-utils";
import { DriverService } from "../../drivers/service/driver.service";

@Component({
  selector: "app-driver-modal",
  templateUrl: "./driver-modal.component.html",
  styleUrls: ["./driver-modal.component.css"],
})
export class DriverModalComponent implements OnInit {
  @Output() onClose = new EventEmitter<string>();
  drivers;
  search;
  
  constructor(private driverService: DriverService) {}

  ngOnInit() {
    this.getDrivers();
  }

  getDrivers() {
    const payload = {
      status: "active",
      noFleet: true,
    };
    this.driverService.getDrivers(payload).subscribe((data) => {
      if (data) {
        this.drivers = data;
      }
    });
  }



}
