import { AgmMap } from '@agm/core';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { mapStyles } from 'src/app/utils/common-utils';
import { ZonesService } from './service/zones.service';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';
declare var google: any;

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.css']
})
export class ZonesComponent implements OnInit, AfterViewInit {
  @ViewChild("agmMap", { static: true }) agmElement: AgmMap;

  agmMap;
  origin = { lat: 32.7157, lng: 117.1611 };
  styles = mapStyles();
  search;
  zones;
  selectedZone;
  zoneName;
  polygon: any;
  selectedPath = [];

  managerOptions = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: ['polygon'],
    },
    polygonOptions: {
      draggable: true,
      editable: true,
    },
    drawingMode: 'polygon',
  };

  options: any = {
    lat: 33.5362475,
    lng: -111.9267386,
    zoom: 10,
    fillColor: '#DC143C',
    draggable: true,
    editable: true,
    visible: true,
  };

  constructor(private zonesService: ZonesService, private sharedDataService: SharedDataService) { }

  ngOnInit() {
    this.getZones();
  }

  ngAfterViewInit() {
    // console.log(this.agmElement);
    this.agmElement.mapReady.subscribe((map) => {
      this.agmMap = map;
    });
  }

  getZones() {
    this.zonesService.getZones().subscribe(data => {
      this.zones = data;
    })

  }

  polygonCreated($event) {
    if (this.polygon) {
      this.polygon.setMap(null);
    }
    this.polygon = $event;
    this.addPolygonChangeEvent(this.polygon);
    google.maps.event.addListener(
      this.polygon,
      'coordinates_changed',
      function (index, obj) {
        // Polygon object: yourPolygon
        console.log('coordinates_changed');
      }
    );
  }

  addPolygonChangeEvent(polygon) {
    var me = polygon,
      isBeingDragged = false,
      triggerCoordinatesChanged = function () {
        // Broadcast normalized event
        google.maps.event.trigger(me, 'coordinates_changed');
      };

    // If  the overlay is being dragged, set_at gets called repeatedly,
    // so either we can debounce that or igore while dragging,
    // ignoring is more efficient
    google.maps.event.addListener(me, 'dragstart', function () {
      isBeingDragged = true;
    });

    // If the overlay is dragged
    google.maps.event.addListener(me, 'dragend', function () {
      triggerCoordinatesChanged();
      isBeingDragged = false;
    });

    // Or vertices are added to any of the possible paths, or deleted
    var paths = me.getPaths();
    paths.forEach(function (path, i) {
      google.maps.event.addListener(path, 'insert_at', function () {
        triggerCoordinatesChanged();
      });
      google.maps.event.addListener(path, 'set_at', function () {
        if (!isBeingDragged) {
          triggerCoordinatesChanged();
        }
      });
      google.maps.event.addListener(path, 'remove_at', function () {
        triggerCoordinatesChanged();
      });
    });
  }

  saveZone() {
    if (this.zoneName.length <= 0)
      return;

    const coordinates = [];
    this.polygon.getPaths().forEach((path, i) => {
      path.forEach((point, i) => {
        coordinates.push([point.lng(), point.lat()]);
      });
    });

    if (coordinates.length === 0)
      return;

    coordinates.push(coordinates[0])

    const payload = {
      name: this.zoneName,
      coordinates: [coordinates],
    };

    this.zonesService.saveZone(
      payload
    ).subscribe(data => {
      this.selectedZone = null;
      this.selectedPath = [];
      this.zoneName = '';
      if (this.polygon) {
        this.polygon.setMap(null);
        this.polygon = null;
      }
      this.getZones();
    })


  }

  selectZone(zone) {
    this.selectedZone = zone;
    this.polygoneToLitteral();
  }

  polygoneToLitteral() {
    this.selectedPath = [];
    let bounds = new google.maps.LatLngBounds();
    this.selectedZone.geo.coordinates[0].forEach(element => {
      this.selectedPath.push({ lat: element[1], lng: element[0] })

      bounds.extend(
        new google.maps.LatLng(
          element[1], element[0])
      );

    });

    this.agmMap.setCenter(bounds.getCenter());
    this.agmMap.fitBounds(bounds);
  }

  deleteZone(id) {

    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to delete this zone?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: "#F1A303",
    }).then((data) => {
      if (data.isConfirmed) {
        this.deleteZoneById(id)
      }
    });

  }

  deleteZoneById(id) {
    this.zonesService.deleteZone(
      id
    ).subscribe(data => {
      console.log(data)
      this.selectedZone = null;
      this.selectedPath = [];
      this.zoneName = '';
      if (this.polygon) {
        this.polygon.setMap(null);
        this.polygon = null;
      }
      this.getZones();
    })
  }

  clearMap() {
    if (this.polygon) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
  }
}
