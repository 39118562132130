import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddCompanyComponent } from "./components/companies/add-company/add-company.component";
import { CompaniesListComponent } from "./components/companies/companies-list/companies-list.component";
import { DashboardComponent } from "./components/rides/dashboard/dashboard.component";
import { DriversListComponent } from "./components/drivers/drivers-list/drivers-list.component";
import { AddStaffComponent } from "./components/staff/add-staff/add-staff.component";
import { StaffListComponent } from "./components/staff/staff-list/staff-list.component";
import { AuthorizationGuard } from "./services/auth-guard/authorization-guard.service";
import { AddDriverComponent } from './components/drivers/add-driver/add-driver.component';
import { SigninComponent } from "./components/shared/signin/signin.component";
import { ForgotPasswordComponent } from "./components/shared/forgot-password/forgot-password.component";
import { ProfileComponent } from "./components/shared/profile/profile.component";
import { FleetsListComponent } from "./components/fleets/fleets-list/fleets-list.component";
import { AddFleetComponent } from "./components/fleets/add-fleet/add-fleet.component";
import { FleetDetailsComponent } from "./components/fleets/fleet-details/fleet-details.component";
import { CompanyDetailsComponent } from "./components/companies/company-details/company-details.component";
import { DriverDetailsComponent } from "./components/drivers/driver-details/driver-details.component";
import { VehiclesListComponent } from "./components/vehicles/vehicles-list/vehicles-list.component";
import { AddVehicleComponent } from "./components/vehicles/add-vehicle/add-vehicle.component";
import { DynamicFaresComponent } from "./components/fares/dynamic-fares/dynamic-fares.component";
import { DriverBroadcastComponent } from "./components/driver-broadcast/driver-broadcast.component";
import { SupportComponent } from "./components/support/support.component";
import { KpisComponent } from "./components/kpis/kpis.component";
import { HelpingMaterialComponent } from "./components/helping-material/helping-material.component";
import { HomeComponent } from "./components/home/home.component";
import { AddDynamicFareComponent } from "./components/fares/add-dynamic-fare/add-dynamic-fare.component";
import { DynamicFareDetailsComponent } from "./components/fares/dynamic-fare-details/dynamic-fare-details.component";
import { AddDriverDocumentsComponent } from "./components/drivers/add-driver-documents/add-driver-documents.component";
import { NewRideComponent } from "./components/rides/new-ride/new-ride.component";
import { UnassignedComponent } from "./components/rides/unassigned/unassigned.component";
import { PassengerListComponent } from "./components/passengers/passenger-list/passenger-list.component";
import { AddPassengerComponent } from "./components/passengers/add-passenger/add-passenger.component";
import { SupportListComponent } from "./components/support/support-list/support-list.component";
import { AddSupportComponent } from "./components/support/add-support/add-support.component";
import { SupportChatComponent } from "./components/support/support-chat/support-chat.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { AdminDashboardComponent } from "./components/admin-dashboard/admin-dashboard.component";
import { OverviewComponent } from "./components/kpis/overview/overview.component";
import { EarningsComponent } from "./components/kpis/earnings/earnings.component";
import { DriverWalletComponent } from "./components/kpis/driver-wallet/driver-wallet.component";
import { WalletsComponent } from "./components/wallets/wallets/wallets.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { SubscriptionsComponent } from "./components/drivers/subscriptions/subscriptions.component";
import { QuartersListComponent } from "./components/quarters/quarters-list/quarters-list.component";
import { AddQuarterComponent } from "./components/quarters/add-quarter/add-quarter.component";
import { OperationsComponent } from "./components/operations/operations/operations.component";
import { AddCompanyConfigurationsComponent } from "./components/companies/add-company-configurations/add-company-configurations.component";
import { ZonesComponent } from "./components/zones/zones.component";
import { BillingsComponent } from "./components/kpis/billings/billings.component";
import { BrokersComponent } from "./components/brokers/brokers-list/brokers.component";
import { AddBrokerComponent } from "./components/brokers/add-broker/add-broker.component";
import { VouchersListComponent } from "./components/vouchers/vouchers-list/vouchers-list.component";
import { AddVoucherComponent } from "./components/vouchers/add-voucher/add-voucher.component";
import { HangingStackComponent } from "./components/rides/hanging-stack/hanging-stack.component";
import { BusinessReportComponent } from "./components/kpis/business-report/business-report.component";

const routes: Routes = [
  {
    path: "reset-password/:token/:type",
    component: ResetPasswordComponent,
  },
  {
    path: "signin",
    component: SigninComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "dashboard",
    component: AdminDashboardComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  { path: 'rides', redirectTo: 'rides/dashboard', pathMatch: 'full' },
  {
    path: "rides/dashboard",
    component: DashboardComponent,    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  // {
  //   path: "rides/offered",
  //   component: UnassignedComponent,    canActivate: [AuthorizationGuard],
  //   data: {
  //     role: [ "cuser", "cadmin"],
  //   },
  // },
  {
    path: "rides/hanging",
    component: UnassignedComponent,    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "rides/hanging-stack",
    component: HangingStackComponent,    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "rides/assigned",
    component: UnassignedComponent,    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "rides/upcoming",
    component: UnassignedComponent,    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "rides/all",
    component: UnassignedComponent,    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "rides/cancelled",
    component: UnassignedComponent,    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "rides/inprogress",
    component: UnassignedComponent,    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "rides/finished",
    component: UnassignedComponent,    canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "rides/new-ride",
    component: NewRideComponent, canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "rides/:id/edit",
    component: NewRideComponent, canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "operations",
    component: OperationsComponent, canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  
  // {
  //   path: "rides/:id/details",
  //   component: RideDetailsComponent, canActivate: [AuthorizationGuard],
  //   data: {
  //     role: [ "cuser", "cadmin"],
  //   },
  // },
  {
    path: "passengers",
    component: PassengerListComponent, canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "passengers/add",
    component: AddPassengerComponent, canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "passengers/:id/edit",
    component: AddPassengerComponent, canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "cuser", "cadmin"],
    },
  },
  {
    path: "companies",
    component: CompaniesListComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "companies/add",
    component: AddCompanyComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "companies/:id/edit",
    component: AddCompanyComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "companies/:id/details",
    component: CompanyDetailsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "configurations",
    component: AddCompanyConfigurationsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["cuser", "cadmin"],
    },
  },

  {
    path: "drivers",
    component: DriversListComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "drivers/add",
    component: AddDriverComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "drivers/:id/edit",
    component: AddDriverComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "drivers/:id/details",
    component: DriverDetailsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "drivers/:id/docs",
    component: AddDriverDocumentsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "drivers/:id/subscriptions",
    component: SubscriptionsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "staff",
    component: StaffListComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "staff/add",
    component: AddStaffComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "staff/:id/edit",
    component: AddStaffComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "fleet",
    component: FleetsListComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "fleet/add",
    component: AddFleetComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "fleet/:id/edit",
    component: AddFleetComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "fleet/:id/details",
    component: FleetDetailsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "brokers",
    component: BrokersComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "cuser", "cadmin"],
    },
  },
  {
    path: "brokers/add",
    component: AddBrokerComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "brokers/:id/edit",
    component: AddBrokerComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "vehicles",
    component: VehiclesListComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "cuser", "cadmin"],
    },
  },
  {
    path: "vehicles/add",
    component: AddVehicleComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "vehicles/:id/edit",
    component: AddVehicleComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "dynamic-fares",
    component: DynamicFaresComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin", "rsa"],
    },
  },
  {
    path: "dynamic-fares/add",
    component: AddDynamicFareComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "rsa"],
    },
  },
  {
    path: "dynamic-fares/:id/edit",
    component: AddDynamicFareComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "rsa"],
    },
  },
  {
    path: "dynamic-fares/:id/details",
    component: DynamicFareDetailsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin","rsa"],
    },
  },
  {
    path: "zones",
    component: ZonesComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "broadcast",
    component: DriverBroadcastComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "support/driver",
    component: SupportListComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "support/passenger",
    component: SupportListComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "support/add",
    component: AddSupportComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "support/:id/edit",
    component: AddSupportComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "support/:id/chat",
    component: SupportChatComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "kpis/overview",
    component: OverviewComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "kpis/earnings",
    component: EarningsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "kpis/wallets",
    component: DriverWalletComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "kpis/billings",
    component: BillingsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "kpis/business-report",
    component: BusinessReportComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "wallets",
    component: WalletsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "helping-material",
    component: HelpingMaterialComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "notifications",
    component: NotificationsComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "quarters",
    component: QuartersListComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "quarters/add",
    component: AddQuarterComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },
  {
    path: "quarters/:id/edit",
    component: AddQuarterComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: ["admin"],
    },
  },

  {
    path: "vouchers",
    component: VouchersListComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "vouchers/add",
    component: AddVoucherComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
  {
    path: "vouchers/:id/edit",
    component: AddVoucherComponent,
        canActivate: [AuthorizationGuard],
    data: {
      role: [ "cuser", "cadmin"],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
