import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment-timezone";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { getRideStatusLabel, splitString } from "src/app/utils/common-utils";
import Swal from "sweetalert2";
import { RideService } from "../service/ride.service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";

@Component({
  selector: "app-unassigned",
  templateUrl: "./unassigned.component.html",
  styleUrls: ["./unassigned.component.css"],
})
export class UnassignedComponent implements OnInit {
  search;
  rides;
  status;
  selectedRideId;
  timeZone = "America/New_York";
  today = moment().tz(this.timeZone).startOf("day");
  scheduleTime = this.today.toISOString();
  user;
  assignDriverModalObj;



  constructor(
    private rideService: RideService,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private socketService: SocketService
  ) { }


  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
        this.timeZone = data.company.timeZone;
        this.today = moment().tz(this.timeZone).startOf("day");
        this.scheduleTime = this.today.toISOString();
      }
    });


    this.socketService
      .addListener(Events.GET_NOTIFICATIONS)
      .subscribe((data) => {
        console.log(Events.GET_NOTIFICATIONS, data);
        if (data.notification && data.notification.type === "Support") {
        } else if (data.notification && data.notification.type === "Driver") {
          // will logic add later.
        } else {
          const status = this.activatedRoute.snapshot.url[1].path;


          if (['inprogress'].includes(status)) {
            if (['onway', 'arrived', 'started', 'completed'].includes(data.notification.status)) {
              this.getRides();
              return;
            }

          } else if (['hanging'].includes(status)) {

            if (['hanging'].includes(data.notification.status)) {
              this.getRides();
              return;
            }

          } else if (['offered'].includes(status)) {

            if (['offered'].includes(data.notification.status)) {
              this.getRides();
              return;
            }
          } else if (['assigned'].includes(status)) {

            if (['assigned'].includes(data.notification.status)) {
              this.getRides();
              return;
            }

          } else if (['cancelled'].includes(status)) {

            if (['cancelled'].includes(data.notification.status)) {
              this.getRides();
              return;
            }
          } else if (['finished'].includes(status)) {

            if (['finished'].includes(data.notification.status)) {
              this.getRides();
              return;
            }
          }

          const indexOfRide = this.rides.findIndex(item => item._id === data.notification.relation);
          if (indexOfRide > -1) {
            this.rides.splice(indexOfRide, 1);
          }

        }
      });


    this.getRides();
  }

  getRides() {
    this.status = this.activatedRoute.snapshot.url[1].path;

    const queryParams = {
      status: this.status,
      scheduleTime: this.scheduleTime,
    };
    this.rideService.getRides(queryParams).subscribe(
      (data) => {
        if (data && data.length) {
          this.rides = data;
          this.rides.sort((a, b) => <any>new Date(b.scheduleTime) - <any>new Date(a.scheduleTime)); // DESC
        } else {
          this.rides = [];
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  openModal(rideId) {
    this.selectedRideId = rideId;
  }

  onSearchDateChange(evt) {
    this.scheduleTime = moment(evt).tz(this.timeZone, true).toISOString();
    this.getRides();
  }

  assign(ride, idx) {
    this.assignDriverModalObj = {
      ride: ride,
      idx: idx
    };
  }

  onCloseAssignModal(obj) {
    console.log('OnClose: ', obj);
    this.assignDriverModalObj = null;
    if (obj) {
      let idx = obj.idx;
      let driver = obj.driver;
      if (driver) {
        this.rides[idx].driver = {
          contactNumber: driver.contactNumber,
          displayName: driver.displayName,
          email: driver.email,
          profileImageURL: driver.profileImageURL,
          _id: driver._id
        };
      } else {
        this.rides.splice(idx, 1);
      };
    }
  }

  getStatusLabel(status) {
    return getRideStatusLabel(status)
  }

  getSplittedString(str) {
    return splitString(str, '-');
  }

  askAcceptRide(ride) {
    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to accept the ride?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: "#F1A303",
    }).then((data) => {
      if (data.isConfirmed) {
        this.acceptRide(ride);
      }
    });
  }

  acceptRide(ride) {
    // fareestimation 
    let payload = {
      vehicles: [ride.vehicle._id],
      locations: ride.locations
    };
    this.rideService.getFareEstimation(payload).subscribe(
      (data) => {
        console.log('Fare: ', data);
        if (data && data.fareResponse && data.fareResponse.length) {
          this.acceptRideRequest(ride, data.fareResponse[0]);
        } else {
          Swal.fire({
            title: "Alert",
            html: "Fare estimation failed",
            icon: "error",
            confirmButtonColor: "#F1A303",
          }).then((data) => { });
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  acceptRideRequest(ride, fare) {
    let payload = {
      fare: fare,
      status: 'hanging'
    };

    this.rideService
      .acceptRide(ride._id, payload)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data => {
          });
          this.getRides();
        }
      })
  }

  askRejectRide(ride) {
    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to reject the ride?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: "#F1A303",
    }).then((data) => {
      if (data.isConfirmed) {
        this.rejectRide(ride);
      }
    });
  }

  rejectRide(ride) {
    let payload = {
      company: null,
      status: 'staging'
    };

    this.rideService
      .acceptRide(ride._id, payload)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data => {
          });
          this.getRides();
        }
      })
  }
}

