import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from 'src/app/utils/url-utils';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData } from 'src/app/utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class QuarterService {

  constructor(private http: HttpClient) { }

  getQuarters(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.SERVER_API_URL + urls.QUARTERS, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getQuarterById(quarterId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.QUARTERS + '/' + quarterId)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveQuarter(quarterId, payload) {
    const formData = setFormData(payload);
    console.log('Quarter FormData: ', formData);
    if (quarterId) {
      return this.http.put<any>(urls.SERVER_API_URL + urls.QUARTERS +`/${quarterId}`, formData)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      return this.http.post<any>(urls.SERVER_API_URL + urls.QUARTERS, formData)
        .pipe(
          catchError(errorHandl)
        );
    }
  }
}
