import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { FareService } from '../service/fare.service';

@Component({
  selector: 'app-dynamic-fare-details',
  templateUrl: './dynamic-fare-details.component.html',
  styleUrls: ['./dynamic-fare-details.component.css']
})
export class DynamicFareDetailsComponent implements OnInit {
  fare;
  fareId;

  constructor(
    private fareService: FareService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.fareId = this.activatedRoute.snapshot.paramMap.get('id');

    this.fareService
    .getFareDetails(this.fareId)
    .subscribe(data => {
      if (data) {
        this.fare = data;
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }

}
