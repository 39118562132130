import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';
import { CompanyService } from '../service/company.service';

@Component({
  selector: 'app-add-company-configurations',
  templateUrl: './add-company-configurations.component.html',
  styleUrls: ['./add-company-configurations.component.css']
})
export class AddCompanyConfigurationsComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  companyId;
  configs;

  constructor(
    private companyService: CompanyService,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      hideHomeDigitalMeter: [false, Validators.required],
      hideHomeFareCalculator: [false, Validators.required],
      hideFareEstimates: [false, Validators.required],
      hideInitialWaitTime: [false, Validators.required],
      hideUpfront: [false, Validators.required],
      hideEnrouteMeter: [false, Validators.required],
      hideTolls: [false, Validators.required],
      hideInvoice: [false, Validators.required],
      hideRunner: [false, Validators.required],
      hideHistoryFares: [false, Validators.required],
      hideWallet: [false, Validators.required]
    });

    this.getUser();
    this.getConfigurations();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(data => {
        if (data && data.company) {
          this.companyId = data.company._id;
        }
      });
  }

  getConfigurations() {
    this.companyService
      .getCompanyConfigurations(this.companyId)
      .subscribe(data => {
        if (data) {
          this.configs = data;
          this.formGroup.patchValue(this.configs);
        }
      })
  }

  submit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      console.log("Invalid Form: ", this.formGroup.value);
      return;
    }

    console.log("Form: ", this.formGroup.value);

    this.companyService
      .saveCompanyConfigurations(this.configs ? this.companyId : null, this.formGroup.value)
      .subscribe(data => {
        if (data) {
          this.configs = data;
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then((data) => {
          });
        }
      });
  }

  toggle(control: AbstractControl) {
    control.setValue(!control.value);
  }

  get form() {
    return this.formGroup.controls;
  }
}
