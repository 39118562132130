import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { WalletService } from '../../wallets/service/wallet.service';

@Component({
  selector: 'app-wallet-history-modal',
  templateUrl: './wallet-history-modal.component.html',
  styleUrls: ['./wallet-history-modal.component.css']
})
export class WalletHistoryModalComponent implements OnInit {
  @Input() data: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  walletId;
  driverHistory;
  user;

  constructor(
    private walletService: WalletService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.walletId = this.data._id;
    this.getHistory();
    this.getUser();
  }

  getUser() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
      } else {
        this.user = null;
      }
    });
  }

  getHistory() {
    this.walletService
    .getWalletHistory(this.walletId)
    .subscribe(data => {
      if (data && data.length) {
        this.driverHistory = data;
      }
    });
  }

  close() {
    this.onClose.emit(null);
  }
}
