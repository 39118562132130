import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';
import { DriverService } from '../service/driver.service';
import * as moment from "moment-timezone";

enum FORM {
  EQUIPMENT = 'equipment',
  MOBILE_DATA = 'data'
}

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent implements OnInit {
  equipmentFeeForm: FormGroup;
  mobileDataForm: FormGroup;

  driverId;
  equipmentFeeId;
  mobileDataFeeId;

  equipmentFee;
  mobileDataFee;

  isSubmittedEquipmentFeeForm = false;
  isSubmittedMobileDataForm = false;

  // Togglers
  isEquipmentFeeEnabled = false;
  isMobileDataFeeEnabled = false;

  transactionHistory;
  user;
  today;
  isEditableNextDueDate = false;
  isEditablePaidAmount = false;
  timeZone;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private driverService: DriverService,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.driverId = this.activatedRoute.snapshot.paramMap.get('id');
    // this.today = moment().set({hours:0,minutes:0,seconds:0}).toISOString();

    this.equipmentFeeForm = this.fb.group({
      amount: [0, Validators.required],
      perMonth: [0, Validators.required],
      description: [''],
      status: ['active'],
      type: [FORM.EQUIPMENT],
      nextDue: [''],
      newNextDueDate: [''],
      preNextDueDate: [''],
      paidAmount: [0],
      newPaidAmount: [0]
    });

    this.mobileDataForm = this.fb.group({
      amount: [0, Validators.required],
      perMonth: [0, Validators.required],
      description: [''],
      status: ['active'],
      type: [FORM.MOBILE_DATA]
    });

    this.getUser();
    this.getSubscriptions();
    this.getBillingHistory();
  }

  getUser() {
    this.sharedDataService
    .getUser()
    .subscribe((data) => {
      if (data) {
        this.user = data;
        if (data.company && data.company.timeZone) {
          this.timeZone = data.company.timeZone;
          this.today = moment().tz(this.timeZone);
        }
      } else {
        this.user = null;
      }
    });
  }

  getSubscriptions() {
    const queryParams = {
      driver: this.driverId
    }

    this.driverService
      .getDriverSubscriptions(queryParams)
      .subscribe(subscriptions => {
        console.log('Driver Subscriptions: ', subscriptions);
        if (subscriptions && subscriptions.length) {
          // Equipment Fee
          this.equipmentFee = subscriptions.find(s => s.type === FORM.EQUIPMENT);
          if (this.equipmentFee) {
            this.equipmentFeeForm.patchValue({
              ...this.equipmentFee,
              preNextDueDate: this.equipmentFee.nextDue,
              newPaidAmount: this.equipmentFee.paidAmount
            });
            this.equipmentFeeId = this.equipmentFee._id;
            this.isEquipmentFeeEnabled = this.equipmentFee.status === 'active' ? true : false;
          }

          // Mobile Data
          this.mobileDataFee = subscriptions.find(s => s.type === FORM.MOBILE_DATA);
          if (this.mobileDataFee) {
            this.mobileDataForm.patchValue(this.mobileDataFee);
            this.mobileDataFeeId = this.mobileDataFee._id;
            this.isMobileDataFeeEnabled = this.mobileDataFee.status === 'active' ? true : false;
          }
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  submitEquipmentFeeForm() {
    this.isSubmittedEquipmentFeeForm = true;

    console.log('Form :', this.equipmentFeeForm.value);

    if (this.equipmentFeeForm.invalid) {
      return;
    }

    this.equipmentFeeForm.value.driver = this.driverId;

    console.log('Valid Form: ', this.equipmentFeeForm.value);

    this.saveDriverSubscription(this.equipmentFeeId, this.equipmentFeeForm.value, FORM.EQUIPMENT);
  }

  submitMobileDataForm() {
    this.isSubmittedMobileDataForm = true;

    console.log('Form :', this.mobileDataForm.value);

    if (this.mobileDataForm.invalid) {
      return;
    }

    this.mobileDataForm.value.driver = this.driverId;

    console.log('Valid Form: ', this.mobileDataForm.value);

    this.saveDriverSubscription(this.mobileDataFeeId, this.mobileDataForm.value, FORM.MOBILE_DATA);
  }

  onChangeEquipmentToggler() {
    if (!this.equipmentFeeId) {
      return;
    }
    this.isEquipmentFeeEnabled = !this.isEquipmentFeeEnabled;
    const status = this.isEquipmentFeeEnabled ? 'active' : 'inactive';
    const payload = {
      status: status
    }
    this.equipmentFeeForm.patchValue({
      status: status
    });

    this.saveDriverSubscription(this.equipmentFeeId, payload, FORM.EQUIPMENT);
  }

  onChangeMobileDataToggler() {
    if (!this.mobileDataFeeId) {
      return;
    }
    this.isMobileDataFeeEnabled = !this.isMobileDataFeeEnabled;
    const status = this.isMobileDataFeeEnabled ? 'active' : 'inactive';
    const payload = {
      status: status
    }

    this.mobileDataForm.patchValue({
      status: status
    });

    this.saveDriverSubscription(this.mobileDataFeeId, payload, FORM.MOBILE_DATA);
  }

  saveDriverSubscription(id, payload, form) {
    //Check for Paid Amount that it should not exceed total equipment cost.
    if (form === FORM.EQUIPMENT) {
      if (this.equipmentFeeForm.value.paidAmount > this.equipmentFeeForm.value.amount) {
        Swal.fire({
          title: "Alert",
          html: "The amount you entered exceeds the total equipment cost.",
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
        return false;
      }
    }

    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to make changes?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: "#F1A303",
    }).then((data) => {
      if (data.isConfirmed) {
        this.driverService
          .saveDriverSubscriptions(id, payload)
          .subscribe(data => {
            if (data) {
              if (form === FORM.EQUIPMENT) {
                console.log('UPDATED EQUIPMENT');
                this.equipmentFee = data;
                this.equipmentFeeId = data._id;
                this.isEquipmentFeeEnabled = data.status === 'active' ? true : false;
                if (this.isEditablePaidAmount) {
                  this.isEditablePaidAmount = false;
                }
              } else if (form === FORM.MOBILE_DATA) {
                console.log('UPDATED MOBILE_DATA');
                this.mobileDataFee = data;
                this.mobileDataFeeId = data._id;
                this.isMobileDataFeeEnabled = data.status === 'active' ? true : false;
              }

              Swal.fire({
                title: "Alert",
                html: "Operation successful",
                icon: "success",
                confirmButtonColor: '#F1A303'
              }).then(data => {
                // this.router.navigateByUrl('/subscriptions');
              })
            }
          }, err => {
            Swal.fire({
              title: "Alert",
              html: err,
              icon: "error",
              confirmButtonColor: '#F1A303'
            }).then((data) => {
            });
          });
      } else {
        if (form === FORM.EQUIPMENT) {
          if (this.equipmentFee) {
            const status = this.equipmentFee.status === 'active' ? true : false;
            if (this.isEquipmentFeeEnabled !== status) {
              this.isEquipmentFeeEnabled = !this.isEquipmentFeeEnabled;
              this.equipmentFeeForm.patchValue({
                status: this.isEquipmentFeeEnabled ? 'active' : 'inactive'
              });
            }
          }
        }
        if (form === FORM.MOBILE_DATA) {
          if (this.mobileDataFee) {
            const status = this.mobileDataFee.status === 'active' ? true : false;
            if (this.isMobileDataFeeEnabled !== status) {
              this.isMobileDataFeeEnabled = !this.isMobileDataFeeEnabled;
              this.mobileDataForm.patchValue({
                status: this.isMobileDataFeeEnabled ? 'active' : 'inactive'
              });
            }
          }
        }
      }
    });

  }

  getBillingHistory() {
    const queryParams = {
      driver: this.driverId
    }
    this.driverService
    .getDriverBillingHistory(queryParams)
    .subscribe(data => {
      if (data && data.length) {
        this.transactionHistory = data;
      }
    })
  }

  getStatus(status) {
    return status.replace(/_/g, ' ');
  }

  onEditNextDueDate() {
    this.isEditableNextDueDate = true;
    this.equipmentFeeForm.patchValue({
      preNextDueDate: this.equipmentFee.nextDue
    });
  }

  onDateChange(evt) {
    if(evt){
      this.equipmentFeeForm.patchValue({
        newNextDueDate: moment(evt).tz(this.timeZone,true).toISOString()
      });
    }
  }

  onSaveNextDueDate() {
    this.isEditableNextDueDate = false;
    if (this.equipmentFeeForm.value.newNextDueDate) {
      this.equipmentFeeForm.patchValue({
        nextDue: this.equipmentFeeForm.value.newNextDueDate
      });
      if (this.equipmentFee) {
        this.equipmentFee.nextDue = this.equipmentFeeForm.value.newNextDueDate;
      }
    }
  }

  onEditPaidAmount() {
    this.isEditablePaidAmount = true;
    this.equipmentFeeForm.patchValue({
      newPaidAmount: this.equipmentFee.paidAmount
    });
  }

  onSavePaidAmount() {
    if (this.equipmentFeeForm.value.newPaidAmount > this.equipmentFeeForm.value.amount) {
      Swal.fire({
        title: "Alert",
        html: "The amount you entered exceeds the total equipment cost.",
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    } else {
      this.isEditablePaidAmount = false;
      this.equipmentFeeForm.patchValue({
        paidAmount: this.equipmentFeeForm.value.newPaidAmount
      });

      // Update the view
      this.equipmentFee.paidAmount = this.equipmentFeeForm.value.newPaidAmount;
    }
  }

  get form() {
    return this.equipmentFeeForm.controls;
  }

  get form2() {
    return this.mobileDataForm.controls;
  }
}
