import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { ToastService } from "src/app/services/toast-messages/toastService/toast.service";
import { getRoles } from "src/app/utils/common-utils";
import { Events } from "src/app/utils/event-utils";
import Swal from "sweetalert2";
import * as moment from "moment-timezone";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild("audioOption", { static: true }) audioPlayerRef: ElementRef;
  @ViewChild("sosAudioOption", { static: true }) sosAudioPlayerRef: ElementRef;
  isProfileOpen = false;
  isNotifications = false;
  isNewNotification = false;
  user;
  notifications = [];
  userRole = "";
  dateTimeAgo;
  timeZone;
  intervalId;
  sosNotifications = [];
  driverNotifications = [];
  supportNotifications = [];
  otherNotifications = [];
  isShowDriverNotifications = false;
  isShowSupportNotifications = false;
  isShowOtherNotifications = false;
  isNewDriverNotification = false;
  isNewSupportNotification = false;
  isNewOtherNotification = false;
  isShowSOSNotifications = false;
  isNewSOSNotification = false;
  isSound = true;
  selectedRideId;

  constructor(
    private auth: AuthService,
    private sharedDataService: SharedDataService,
    private socketService: SocketService,
    private router: Router,
    private toast: ToastService
  ) { }

  ngOnInit() {
    window.addEventListener('storage', this.storageListener);

    // Setting the sound state if there is any value present in localstorage
    if (localStorage.getItem("sound")) {
      this.isSound = JSON.parse(localStorage.getItem("sound"));
    }

    this.intervalId = setInterval(() => {
      this.dateTimeAgo = moment().toISOString();
    }, 1000);

    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        // console.log("data ---> ", data);
        this.user = data;
        if (this.user.roles && this.user.roles.length) {
          this.userRole = this.getRoleType(this.user.roles[0]);
          if (data.company) {
            this.timeZone = data.company.timeZone;
          }
          if (this.user.roles[0] !== "admin") {
            this.getAllNotifications();
            this.getSOSNotification();
          }
        }
      } else {
        this.user = null;
      }
    });

    this.socketService
      .addListener(Events.GET_NOTIFICATIONS)
      .subscribe((data) => {
        console.log('Socket Data: ', data);
        console.log(Events.GET_NOTIFICATIONS, data);
        if (data.notification) {
          // this.isNewNotification = true;
          this.onAudioPlay();
          this.toast.sendMessage(data.notification.text, "info");
          // this.notifications.unshift(data.notification);

          if (data.notification.type === "Driver") {
            this.isNewDriverNotification = true;
            this.driverNotifications.unshift(data.notification);
          } else if (data.notification.type === "Support") {
            this.isNewSupportNotification = true;
            this.supportNotifications.unshift(data.notification);
          } else {
            this.isNewOtherNotification = true;
            this.otherNotifications.unshift(data.notification);
          }
        } else if (data.sos) {
          this.sosOnAudioPlay();
          this.toast.sendMessage(data.sos.text, "info", true, data.sos);
          this.isNewSOSNotification = true;
          this.sosNotifications.unshift(data.sos);
        } else {
          Swal.fire({
            title: "Alert",
            html: data.message,
            icon: "warning",
            confirmButtonColor: "#F1A303",
          }).then((data) => { });
        }
      });


    this.toast.getAcknowledgement()
      .subscribe((notification) => {
        this.sosNotifications.forEach(item => {
          if (notification.extras && notification.extras._id === item._id) {
            item.isRead = true;
            this.acknowledgementSOSToServe(item);
          }
        });
      });
  }

  storageListener = (event) => {
    if (event.key === 'sound') {
      if (event.newValue) {
        this.isSound = JSON.parse(event.newValue);
      }
    }

    if (event.key === 'token') {
      if (!event.newValue) {
        this.silentLogout();
      }
    }
  }

  logout() {
    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to log out?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: "#F1A303",
    }).then((data) => {
      if (data.isConfirmed) {
        this.silentLogout();
      }
    });
  }

  silentLogout() {
    this.socketService.disconnectSocket();
    this.auth.removeToken();
    this.sharedDataService.saveUser(null);
    this.router.navigateByUrl("/");
  }

  openProfile() {
    this.isProfileOpen = !this.isProfileOpen;
    // this.isNotifications = false;
    this.isShowDriverNotifications = false;
    this.isShowSupportNotifications = false;
    this.isShowOtherNotifications = false;
  }

  openNotifications(type) {
    if (type === "driver") {
      this.isShowDriverNotifications = true;
      this.isNewDriverNotification = false;
    } else if (type === "support") {
      this.isShowSupportNotifications = true;
      this.isNewSupportNotification = false;
    } else if (type === "sos") {
      this.isShowSOSNotifications = true;
      this.isNewSOSNotification = false;
    } else {
      this.isShowOtherNotifications = true;
      this.isNewOtherNotification = false;
    }
    // this.isNotifications = !this.isNotifications;
    this.isProfileOpen = false;
    // this.isNewNotification = false;
  }
  getRoleType(roles) {
    return getRoles(roles);
  }

  sosOnAudioPlay() {
      this.sosAudioPlayerRef.nativeElement.play();
  }

  onAudioPlay() {
    if (this.isSound) {
      this.audioPlayerRef.nativeElement.play();
    }
  }

  getAllNotifications() {
    this.auth.fetchNotifications().subscribe(
      (data) => {
        if (data) {
          this.notifications = data;
          this.driverNotifications = [];
          this.supportNotifications = [];
          this.otherNotifications = [];
          this.notifications.forEach((notification) => {
            if (notification.type === "Driver") {
              this.driverNotifications.push(notification);
            } else if (notification.type === "Support") {
              this.supportNotifications.push(notification);
            } else {
              this.otherNotifications.push(notification);
            }
          });
          // console.log('Driver N: ', this.driverNotifications);
          // console.log('Other N: ', this.otherNotifications);
        } else {
          this.notifications = null;
          this.driverNotifications = null;
          this.supportNotifications = null;
          this.otherNotifications = null;
        }
      }
      // ,
      // (err) => {
      //   Swal.fire({
      //     title: "Alert",
      //     html: "Operation failed",
      //     icon: "error",
      //     confirmButtonColor: "#F1A303",
      //   }).then((data) => {});
      // }
    );
  }

  getSOSNotification() {
    this.auth
      .fetchSOSNotifications()
      .subscribe(data => {
        if (data && data.sos && data.sos.length) {
          this.sosNotifications = data.sos;
          this.sosNotifications.forEach(item => {
            // isRead: false (SOS is not read) - (Make the alert sticky)
            // isRead: true (SOS is read)
            if (!item.isRead) {
              this.toast.sendMessage(item.text, "info", true, item);
            }
          });
        }
      });
  }


  showMenu() {
    this.sharedDataService.toggleMenu();
  }
  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  formatText(text) {
    let finalText = "";
    let firstQoute = false;
    for (let i = 0; i < text.length; i++) {
      if (text.charAt(i) === "'") {
        let tempText = '<span class="font-semibold text-gray-900">';
        if (firstQoute) {
          tempText = "</span>";
          firstQoute = false;
        } else {
          firstQoute = true;
        }
        tempText += text.charAt(i);
        finalText += tempText;
      } else {
        finalText += text.charAt(i);
      }
    }
    // console.log('formatText',finalText)
    return finalText;
  }

  toggleSound() {
    this.isSound = !this.isSound;
    localStorage.setItem("sound", JSON.stringify(this.isSound));
  }

  viewAllNotifications() {
    this.isShowDriverNotifications = false;
    this.isShowSupportNotifications = false;
    this.isShowOtherNotifications = false;
    this.isShowSOSNotifications = false;
    this.router.navigateByUrl('/notifications');
  }


  acknowledgementSOSToServe(item) {
    this.auth
      .updateSOSNotification(item._id)
      .subscribe(data => {
        if (data) {
        }
      });
  }

  onClickSOSNotification(notification) {
    if (notification.ride) {
      this.selectedRideId = notification.ride;
    }
  }
}
