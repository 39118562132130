import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment-timezone";
import Swal from "sweetalert2";
import { DriverService } from "../../drivers/service/driver.service";
import { PassengerService } from "../../passengers/service/passenger.service";
import { VehicleService } from "../../vehicles/service/vehicle.service";
import { RideService } from "../service/ride.service";
import * as _ from "lodash";
import { FleetService } from "../../fleets/service/fleet.service";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { ZonesService } from "../../zones/service/zones.service";
declare var google: any;

@Component({
  selector: "app-new-ride",
  templateUrl: "./new-ride.component.html",
  styleUrls: ["./new-ride.component.css"],
})
export class NewRideComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  rideId;
  drivers;
  fleets;
  vehicles;
  locations;
  passengers;
  timeZone = "America/New_York";
  today = moment().tz(this.timeZone);
  showAddPassenger = false;
  isScheduleTimeDisabled = false;
  fareEstimation;
  tripType = 'normal';
  faresByVehicleKey;

  constructor(
    private zonesService: ZonesService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private rideService: RideService,
    private driverService: DriverService,
    private vehicleService: VehicleService,
    private fleetService: FleetService,
    private passengerService: PassengerService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.timeZone = data.company.timeZone;
        this.today = moment().tz(this.timeZone);
      }
    });

    this.rideId = this.activatedRoute.snapshot.paramMap.get("id");

    this.formGroup = this.fb.group({
      preScheduleTime: [this.today],
      scheduleTime: [this.today.toISOString(), Validators.required],
      // fleet: [null],
      vehicle: ["", Validators.required],
      mode: ["now"],
      pickupAddress: ["", Validators.required],
      pickupCoords: [null, Validators.required],
      dropoffAddress: ["", Validators.required],
      dropoffCoords: [null, Validators.required],
      locations: [null],
      passengers: ["1", Validators.required],
      passenger: [null, Validators.required],
      createdBy: ["company-portal"],
      specialRate: [""]
    });

    // this.getDrivers();
    // this.getFleets();
    this.getPassengers();
    this.getVehicles();
  }

  getFleets(vehicle) {
    this.fleets = null;

    const payload = {
      status: "active",
      vehicle,
      purpose: "createRide",
    };
    this.fleetService.getFleets(payload).subscribe(
      (data) => {
        console.log("data", data);
        if (data) {
          this.fleets = data;
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  getDrivers() {
    const payload = {
      status: "active",
    };
    this.driverService.getDrivers(payload).subscribe(
      (data) => {
        if (data) {
          this.drivers = data;
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  getVehicles() {
    const payload = {
      status: "active",
    };
    this.vehicleService.getVehicles(payload).subscribe(
      (data) => {
        if (data && data.length) {
          this.vehicles = data;
          if (!this.rideId) {
            this.formGroup.patchValue({
              vehicle: this.vehicles[0]._id,
            });
            this.getFleets(this.vehicles[0]._id);
          } else {
            this.getRideDetails();
          }
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  getRideDetails() {
    this.rideService.getRideById(this.rideId).subscribe(
      (data) => {
        if (data) {
          this.formGroup.patchValue({
            ...data,
            pickupAddress: data.locations[0].address,
            pickupCoords: [
              data.locations[0].latitude,
              data.locations[0].longitude,
            ],

            dropoffAddress: data.locations[1].address,
            dropoffCoords: [
              data.locations[1].latitude,
              data.locations[1].longitude,
            ],
            preScheduleTime: moment(data.scheduleTime).tz(this.timeZone),
          });
          this.isScheduleTimeDisabled = data.mode === "now" ? true : false;
          // if (this.rideId) {
          this.getFareEstimation();
          this.getFleets(data.vehicle);
          // }
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  async submit() {
    this.isSubmitted = true;

    console.log("Form Values: ", this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    this.setLocations();
    this.deleteUnusedKeys();

    console.log("Valid Form: ", this.formGroup.value);

    let payload = {
      ...this.formGroup.value,
      // fare: this.fareEstimation.fareResponse,
      latitude: this.formGroup.value.locations[0].latitude,
      longitude: this.formGroup.value.locations[0].longitude,
      userType: "companystaff",
      fare: this.fareEstimation.fareResponse.find(
        (data) => data.vehicle === this.formGroup.value.vehicle
      ),
      passenger: this.passengers.find(
        (data) => data._id === this.formGroup.value.passenger
      ),
      zone:undefined
    };

    if (payload.mode === "now") {
      delete payload.specialRate;
    }

    //check zone of trip pickup
    const allZones = await this.zonesService.getZones().toPromise();
    if (allZones && allZones.length) {

      let point = new google.maps.LatLng(payload.locations[0].latitude, payload.locations[0].longitude);

      for (let item of allZones) {
        const zone = [];
        // for (let item2 of item.geo.coordinates[0]) 
        // const coordinates = [...item['geo']['coordinates'][0]];

        for (let item2 of item.geo.coordinates[0]) {
          zone.push(new google.maps.LatLng(item2[1], item2[0]));
        }

        const polygon = new google.maps.Polygon({ paths: zone });
        const resultPath = google.maps.geometry.poly.containsLocation(
          point,
          polygon
        )

        if (resultPath) {
          payload = { ...payload, zone: item['_id'] }
          break;
        }

      }

    }


    this.rideService
      .saveRide(this.rideId, payload)
      .subscribe(
        (data) => {
          if (data) {
            Swal.fire({
              title: "Alert",
              html: "Operation successful",
              icon: "success",
              confirmButtonColor: "#F1A303",
            }).then((data) => {
              this.router.navigateByUrl("/rides/hanging");
            });
          }
        },
        (err) => {
          Swal.fire({
            title: "Alert",
            html: "Operation failed",
            icon: "error",
            confirmButtonColor: "#F1A303",
          }).then((data) => { });
        }
      );
  }

  deleteUnusedKeys() {
    delete this.formGroup.value.pickupAddress;
    delete this.formGroup.value.pickupCoords;
    delete this.formGroup.value.dropoffAddress;
    delete this.formGroup.value.dropoffCoords;
    delete this.formGroup.value.preScheduleTime;
  }

  setLocations() {
    this.formGroup.patchValue({
      locations: [
        {
          address: this.formGroup.value.pickupAddress,
          latitude: this.formGroup.value.pickupCoords[0],
          longitude: this.formGroup.value.pickupCoords[1],
        },
        {
          address: this.formGroup.value.dropoffAddress,
          latitude: this.formGroup.value.dropoffCoords[0],
          longitude: this.formGroup.value.dropoffCoords[1],
        },
      ],
    });
  }

  setPickUpAddress(place) {
    this.formGroup.patchValue({
      pickupAddress: place.formatted_address,
      pickupCoords: [
        place.geometry.location.lat(),
        place.geometry.location.lng(),
      ],
    });
    this.getFareEstimation();
  }

  setDropOffAddress(place) {
    this.formGroup.patchValue({
      dropoffAddress: place.formatted_address,
      dropoffCoords: [
        place.geometry.location.lat(),
        place.geometry.location.lng(),
      ],
    });
    this.getFareEstimation();
  }

  onScheduleDateChange(evt) {
    console.log(moment(evt).tz(this.timeZone, true).toISOString());
    this.formGroup.patchValue({
      scheduleTime: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  onSelectVehicle(vehicleId) {
    if(!this.faresByVehicleKey[vehicleId]) {
      return;
    }
    this.formGroup.patchValue({
      vehicle: vehicleId,
      fleet: null,
    });

    // this.getFleets(vehicleId);
  }

  getPassengers() {
    const queryParams = {
      status: "active",
    };
    this.passengerService.getPassengers(queryParams).subscribe(
      (data) => {
        if (data) {
          this.passengers = data;
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  get form() {
    return this.formGroup.controls;
  }

  toggleMode(b) {
    console.log("toggleMode", this.today.toISOString())
    this.formGroup.patchValue({
      mode: b ? "later" : "now",
      preScheduleTime: this.today,
      scheduleTime: this.today.toISOString(),
      specialRate: ""
    });

    if (this.formGroup.controls.mode.value === "later") {
      this.isScheduleTimeDisabled = false;
    } else {
      this.isScheduleTimeDisabled = true;
    }
  }

  openAddPassengerSlider() {
    this.showAddPassenger = true;
  }

  closeAddPassengerSlider(evt) {
    console.log("closing slider: ", evt);
    if (evt && evt.passenger) {
      this.formGroup.patchValue({
        passenger: evt.passenger._id,
      });
      this.getPassengers();
    }
    this.showAddPassenger = false;
  }

  getFareEstimation() {
    this.fareEstimation = null;
    if (
      !this.formGroup.value.dropoffCoords ||
      !this.formGroup.value.pickupCoords
    )
      return;

    const formValues = this.formGroup.value;
    const payload = {
      vehicles: _.map(this.vehicles, "_id"),
      locations: [
        {
          address: formValues.pickupAddress,
          latitude: formValues.pickupCoords[0],
          longitude: formValues.pickupCoords[1],
        },
        {
          address: formValues.dropoffAddress,
          latitude: formValues.dropoffCoords[0],
          longitude: formValues.dropoffCoords[1],
        },
      ],
      type: this.tripType
    };
    this.rideService.getFareEstimation(payload).subscribe(
      (data) => {
        if (data) {
          if (data.fareResponse) {
            this.faresByVehicleKey = data.fareResponse.reduce((obj, item) => (obj[item.vehicle] = item, obj) ,{});
            const vehicle = this.vehicles.find(v => this.faresByVehicleKey[v._id]);
            if (vehicle) {
              this.formGroup.patchValue({
                vehicle: vehicle._id,
              });
            } else {
              this.formGroup.patchValue({
                vehicle: '',
              });
            }
            console.log('TST: ', this.faresByVehicleKey);
          }
          this.fareEstimation = data;
        } else {
          this.fareEstimation = null;
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => { });
      }
    );
  }

  getFareByVehicleType(vehicleId) {
    if (this.fareEstimation && this.fareEstimation.fareResponse) {
      const fare = this.fareEstimation.fareResponse.find(
        (x) => x.vehicle === vehicleId
      );
      return fare;
    }
  }

  setTripType(type) {
    this.tripType = type;
    console.log(this.tripType);
    this.getFareEstimation();
  }
}
