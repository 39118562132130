import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { imageFileProcessing } from 'src/app/utils/image-processing-utils';
import Swal from 'sweetalert2';
import { DriverService } from '../service/driver.service';

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.css']
})
export class AddDriverComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  isPasswordShow = false;
  driverId;

  profileImageFile;
  profileImageUrl;
  today;
  showPerDayInsurance = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private driverService: DriverService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.driverId = this.activatedRoute.snapshot.paramMap.get('id');
    this.today = moment().set({hours:0,minutes:0,seconds:0}).toISOString();

    this.formGroup = this.fb.group({
      displayName: ['', Validators.required],
      contactNumber: ['', Validators.required],

      email: ['', [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],

      address: ['', Validators.required],
      coords: [null, Validators.required], // This makes sure that the address being selected is valid.

      status: ['active', Validators.required],

      newProfilePicture: [null, Validators.required],
      deviceKey: ['000000000000000'],
      deviceType: ['web'],
      squarelocationId: ['', Validators.required],

      dob: ['', Validators.required],
      preDob: [''],
      perDayInsurance: [0],
      shift: this.fb.group({
        preFrom: [''],
        preTo: [''],
        from: [''],
        to: ['']
      })
      // shift: this.fb.group({
      //   preFrom: [moment().startOf('day').add(9, 'hours')],
      //   preTo: [moment().startOf('day').add(18, 'hours')],
      //   from: [moment().startOf('day').add(9, 'hours').format('HH:mm'), Validators.required],
      //   to: [moment().startOf('day').add(18, 'hours').format('HH:mm'), Validators.required]
      // })
      // hasPaymentMethod: [false, Validators.required],
      // hasInsured: [false, Validators.required]
    });

    if (this.driverId) {
      this.getDriver();
    }
  }

  getDriver() {
    this.driverService
      .getDriverById(this.driverId)
      .subscribe(data => {
        if (data) {
          delete data.password;
          this.profileImageUrl = data.profileImageURL;
          this.showPerDayInsurance = data.hasInsured;
          this.formGroup.patchValue({
            ...data,
            coords: [0, 0],
            preDob: data.dob ? data.dob : '',
          });

          // Password
          this.formGroup.controls.password.clearValidators();
          this.formGroup.controls.password.updateValueAndValidity();

          // Profile Image
          this.formGroup.controls.newProfilePicture.clearValidators();
          this.formGroup.controls.newProfilePicture.updateValueAndValidity();

          if (data.shift && data.shift.from && data.shift.to) {
            const from = data.shift.from.split(':');
            const to = data.shift.to.split(':');

            this.formGroup.patchValue({
              shift: {
                preFrom: moment().startOf('day').set({hour: from[0], minute: from[1]}),
                preTo: moment().startOf('day').set({hour: to[0], minute: to[1]}),
                from: data.shift.from,
                to: data.shift.to
              }
            });
          }
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  submit() {
    this.isSubmitted = true;

    console.log('Form :', this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    console.log('Valid Form: ', this.formGroup.value);

    // Edit case checks
    if (this.driverId) {
      if (!this.formGroup.value.password) {
        delete this.formGroup.value.password;
      }

      if (!this.formGroup.value.newProfilePicture) {
        delete this.formGroup.value.newProfilePicture;
      }
    }

    let payload = {
      ...this.formGroup.value
    }

    delete payload.shift.preFrom;
    delete payload.shift.preTo;

    this.driverService
      .saveDriver(this.driverId, payload)
      .subscribe(data => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
            confirmButtonColor: '#F1A303'
          }).then(data => {
            this.router.navigateByUrl('/drivers');
          })
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  processProfileImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Image Processing Result: ', result);
      if (result) {
        this.profileImageFile = result.file;
        this.profileImageUrl = result.src;
        this.formGroup.patchValue({
          newProfilePicture: this.profileImageFile
        });
      } else {
        this.profileImageFile = null;
        this.profileImageUrl = '';
        this.formGroup.patchValue({
          newProfilePicture: null
        });
      }
    });
  }

  setAddress(place) {
    this.formGroup.patchValue({
      address: place.formatted_address,
      coords: [place.geometry.location.lng(), place.geometry.location.lat()]
    });
  }

  onDateChange(evt, control) {
    if (evt) {
      control.setValue(moment(evt).set({hours:0,minutes:0,seconds:0}).toISOString());
    }
  }

  onInsuranceChange(value) {
    if (value === "true") {
      this.showPerDayInsurance = true;
      this.formGroup.controls.perDayInsurance.setValidators([Validators.required]);
      this.formGroup.controls.perDayInsurance.updateValueAndValidity();
    } else {
      this.showPerDayInsurance = false;
      this.formGroup.patchValue({
        perDayInsurance: 0
      });
      this.formGroup.controls.perDayInsurance.clearValidators();
      this.formGroup.controls.perDayInsurance.updateValueAndValidity();
    }
  }

  onShiftChange(evt, control: AbstractControl) {
    if (evt) {
      control.setValue(moment(evt).format('HH:mm'));
    }
  }

  get form() {
    return this.formGroup.controls;
  }
}
