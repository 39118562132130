import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { exportToCsv } from "src/app/utils/common-utils";
import Swal from "sweetalert2";
import { DriverService } from "../service/driver.service";
@Component({
  selector: "app-drivers-list",
  templateUrl: "./drivers-list.component.html",
  styleUrls: ["./drivers-list.component.css"],
})
export class DriversListComponent implements OnInit {
  search;
  drivers;
  dropDownFilter = "active";
  driverId;

  stepFilter = "";
  accountFilter = "";
  paymentFilter = "";
  filteredDrivers;

  filters = {
    step: [1, 2, 3],
    account: ["incomplete", "inreview", "approved"],
    payment: ["Y", "N"],
  };

  constructor(
    private driverService: DriverService,
    private ngxLoader: NgxUiLoaderService
  ) {}

  ngOnInit() {
    this.getDrivers();
  }

  getDrivers() {
    const queryParams = {
      status: this.dropDownFilter,
    };
    this.driverService.getDrivers(queryParams).subscribe(
      (data) => {
        if (data) {
          this.filteredDrivers = data;
          this.drivers = data;
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => {});
      }
    );
  }
  dropDownFilterChanged(event) {
    this.getDrivers();
    this.onFilterChange();
  }

  openRatingModal(driverId) {
    this.driverId = driverId;
  }

  closeRatingModal() {
    this.driverId = null;
  }

  applyFilters(filters) {
    console.log("Filters: ", filters);
  }

  async downloadReport() {
    // exportToCsv('export.csv', [
    //   ['Driver Name', 'DOB', 'Contact No', 'Email', 'Address', 'Status', 'License Image(Yes/No)', 'License No.', 'License Expiry', 'Taxi Card Image(Yes/No)', 'Taxi Card Expiry', 'Sheriff License Image(Yes/No)', 'Sheriff License Expiry', 'Vehicle Reg. Image(Yes/No)', 'Vehicle Reg. Expiry', 'Vehicle Type', 'Make Model & Year', 'Color', 'VIN', 'Plate No.', 'Plate State', 'QR Code(Link)'],
    // ])
    this.ngxLoader.start();
    const payload = [
      [
        "Driver Name",
        "DOB",
        "Contact No",
        "Email",
        "Address",
        "Status",

        "Onboarding Status(Current)",
        "Account",
        "Credit/Debit Card(Yes/No)",

        "License Image",
        "License No.",
        "License Expiry",
        // "Taxi Card Image",
        // "Taxi Card Expiry",
        "Sheriff License Image",
        "Sheriff License Expiry",
        "Vehicle Reg. Image",
        "Vehicle Reg. Expiry",

        "Vehicle Type",
        "Make Model & Year",
        "Color",
        "VIN",
        "Plate No.",
        "Plate State",
        // "QR Code(Link)",
      ],
    ];

    for (let i = 0; i < this.drivers.length; i++) {
      const driverDetails = await this.driverService
        .getDriverDetails(this.drivers[i]._id)
        .toPromise();
      const driverPayload = [
        this.drivers[i].displayName,
        this.drivers[i].dob
          ? moment(this.drivers[i].dob).format("MM/DD/YYYY")
          : "n/a",
        this.drivers[i].contactNumber,
        this.drivers[i].email,
        this.drivers[i].address,
        this.drivers[i].status,

        this.getStatusName(this.drivers[i].step),
        this.drivers[i].account,
        this.drivers[i].hasPaymentMethod ? "yes" : "no",

        driverDetails.driveradditionalinfo &&  driverDetails.driveradditionalinfo.licenseImageURL,
        driverDetails.driveradditionalinfo &&  driverDetails.driveradditionalinfo.licenseNumber,
        driverDetails.driveradditionalinfo &&  driverDetails.driveradditionalinfo.licenseExpiry
          ? moment(driverDetails.driveradditionalinfo.licenseExpiry).format(
              "MM/DD/YYYY"
            )
          : "n/a",

          // driverDetails.driveradditionalinfo &&   driverDetails.driveradditionalinfo.taxiCabImageURL,
          // driverDetails.driveradditionalinfo &&  driverDetails.driveradditionalinfo.taxiCabExpiry
          // ? moment(driverDetails.driveradditionalinfo.taxiCabExpiry).format(
          //     "MM/DD/YYYY"
          //   )
          // : "n/a",

          driverDetails.driveradditionalinfo &&  driverDetails.driveradditionalinfo.sheriffLicenseImageURL,
          driverDetails.driveradditionalinfo &&  driverDetails.driveradditionalinfo.sheriffLicenseExpiry
          ? moment(
              driverDetails.driveradditionalinfo.sheriffLicenseExpiry
            ).format("MM/DD/YYYY")
          : "n/a",
          driverDetails.driveradditionalinfo &&    driverDetails.driveradditionalinfo.vehicleRegistrationImageURL,
          driverDetails.driveradditionalinfo &&   driverDetails.driveradditionalinfo.vehicleRegistrationExpiry
          ? moment(
              driverDetails.driveradditionalinfo.vehicleRegistrationExpiry
            ).format("MM/DD/YYYY")
          : "n/a",

          driverDetails.fleet &&  driverDetails.fleet.vehicle.name,
          driverDetails.fleet && `${driverDetails.fleet.make} ${driverDetails.fleet.model} ${driverDetails.fleet.year}`,
          driverDetails.fleet && driverDetails.fleet.color,
          driverDetails.fleet && driverDetails.fleet.vin,
          driverDetails.fleet && driverDetails.fleet.plateNumber,
          driverDetails.fleet && driverDetails.fleet.plateState,
        //  driverDetails.fleet && driverDetails.fleet.qrcodeImageURL,
      ];
      payload.push(driverPayload);
    }

    exportToCsv(`drivers-list-${ moment().format("MM-DD-YYYY")}.csv`, payload);
    this.ngxLoader.stop();
  }

  onFilterChange() {
    console.log(this.stepFilter, this.accountFilter, this.paymentFilter);
    this.drivers = this.filteredDrivers.filter((driver) => {
      return (
        driver.step == (this.stepFilter ? this.stepFilter : driver.step) &&
        driver.account ==
          (this.accountFilter ? this.accountFilter : driver.account) &&
        driver.hasPaymentMethod ==
          (this.paymentFilter
            ? JSON.parse(this.paymentFilter)
            : driver.hasPaymentMethod)
      );
    });
  }

  getStatusName(step) {
    switch (step) {
      case 1:
        return "Basic Signup Done";
      case 2:
        return "Documents Submitted";
      case 3:
        return "Vehicle Info submitted";
    }
  }

  formatTime(time) {
    const t = time.split(':');
    return moment().set({hour: t[0], minute: t[1]}).format('hh:mm A');
  }
}
