import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { FleetService } from '../service/fleet.service';

@Component({
  selector: 'app-fleet-details',
  templateUrl: './fleet-details.component.html',
  styleUrls: ['./fleet-details.component.css']
})
export class FleetDetailsComponent implements OnInit {
  fleet;
  fleetId;
  imgModalPayload

  constructor(
    private fleetService: FleetService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.fleetId = this.activatedRoute.snapshot.paramMap.get('id');

    this.fleetService
      .getFleetDetails(this.fleetId)
      .subscribe(data => {
        this.fleet = data;
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

}
