import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import { EarningsService } from '../service/earnings.service';

@Component({
  selector: 'app-driver-wallet',
  templateUrl: './driver-wallet.component.html',
  styleUrls: ['./driver-wallet.component.css']
})
export class DriverWalletComponent implements OnInit {
  search;
  wallets;
  transactions;
  user;
  selectedDriverWallet;
  transactionDetails;

  constructor(
    private earningsService: EarningsService,
    private sharedDataService: SharedDataService 
  ) { }

  ngOnInit() {
    this.getWallets();
    this.getUser();
  }

  getUser() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        this.user = data;
      } else {
        this.user = null;
      }
    });
  }

  getWallets() {
    this.earningsService
    .getWallets()
    .subscribe(data => {
      if (data) {
        if (data.wallets) {
          this.wallets = data.wallets;
        }
      }
    });
  }

  showTransactions(wallet) {
    this.selectedDriverWallet = wallet;
    this.earningsService
    .getWalletHistory(wallet._id)
    .subscribe(data => {
      if (data && data.length) {
        this.transactions = data;
      } else {
        this.transactions = null;
      }
    });
  }

  showTransactionDetails(transaction) {
    console.log(transaction);
    this.transactionDetails = transaction;
  }

  getStatus(status) {
    return status.replace(/_/g, ' ');
  }

}
