import { FormGroup } from "@angular/forms";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// var pdfjsLib = window["pdfjs-dist/build/pdf"];
// // The workerSrc property shall be specified.
// pdfjsLib.GlobalWorkerOptions.workerSrc =
//   "//mozilla.github.io/pdf.js/build/pdf.worker.js";
  
export function getRoles(roles) {
  let label;
  if (roles === "cadmin" || roles === "admin") {
    label = "Admin";
  } else if (roles === "cuser") {
    label = "User";
  } else if (roles === "rsa") {
    label = "RSA"
  }
  return label;
}

export function ConfirmedValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function getUserType(role) {
  let type = "admin";
  if (role === "cadmin" || role === "cuser") {
    type = "companystaff";
  }
  return type;
}

export function getFleetStatusImage(status) {
  let type = "assets/images/offline.png";
  if (status === "online") {
    type = "assets/images/online.gif";
  } else if (status === "busy") {
    type = "assets/images/busy.gif";
  } else if (status === "offline") {
    type = "assets/images/offline.png";
  }
  return type;
}

export function getFleetStatusColor(status) {
  let className = "offline-status";
  if (status === "online") {
    className = "online-status";
  } else if (status === "busy") {
    className = "busy-status";
  } else if (status === "offline") {
    className = "offline-status";
  }
  return className;
}

export function getFleetStatusColorRipple(status) {
  let className = "offline-status-ripple";
  if (status === "online") {
    className = "online-status-ripple";
  } else if (status === "busy") {
    className = "busy-status-ripple";
  } else if (status === "offline") {
    className = "offline-status-ripple";
  }
  return className;
}

export function getFleetIcons(iconName: string) {
  let type = "assets/images/markers/default.png";
  if (iconName === "deluxe") {
    type = "assets/images/markers/deluxe.png";
  } else if (iconName === "standard") {
    type = "assets/images/markers/standard.png";
  } else if (iconName === "van") {
    type = "assets/images/markers/van.png";
  }
  return type;
}

export function mapStyles() {
  return [
    {
      featureType: "administrative",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "landscape",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#84afa3",
        },
        {
          lightness: 52,
        },
      ],
    },
    {
      stylers: [
        {
          saturation: -17,
        },
        {
          gamma: 0.36,
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#3f518c",
        },
      ],
    },
  ];
}

export function googleImageURL(latitude, longitude) {
  return `https://maps.googleapis.com/maps/api/staticmap?markers=icon:https://nemtpanel.com/modules/companies/client/img/buttons/normal.png%7C${latitude},${longitude}&size=160x170&key=AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA`;
}
export function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

export function getBase64(file) {
  console.log("getBase64 --> ", file);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    console.log(reader.result);
    this.resume.profilePic = reader.result as string;
  };
  reader.onerror = (error) => {
    console.log("Error: ", error);
  };
}

export async function urlToBase64(url) {
  console.log("Downloading image...");
  var res = await fetch(url, {
    method: "GET",
    headers: { "Cache-Control": "no-cache" },
  });
  var blob = await res.blob();

  const result = await new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        resolve(reader.result);
      },
      false
    );

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  });

  return result;
}

// export function pdfToIMG(code, documentDefinition) {

//   const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
//   pdfDocGenerator.getBase64((data) => {
//     const pdfData = atob(data);
//     // Using DocumentInitParameters object to load binary data.
//     var loadingTask = pdfjsLib.getDocument({ data: pdfData });
//     loadingTask.promise.then(
//       function (pdf) {
//         console.log("PDF loaded");
//         // Fetch the first page
//         var pageNumber = 1;
//         pdf.getPage(pageNumber).then(function (page) {
//           console.log("Page loaded");

//           var scale = 1.5;
//           var viewport = page.getViewport({ scale: scale });

//           // Prepare canvas using PDF page dimensions
//           var canvas = document.createElement("canvas"); //document.getElementById('the-canvas');
//           var context = canvas.getContext("2d");
//           canvas.height = viewport.height;
//           canvas.width = viewport.width;

//           // Render PDF page into canvas context
//           var renderContext = {
//             canvasContext: context,
//             viewport: viewport,
//           };
//           var renderTask = page.render(renderContext);
//           renderTask.promise.then(function () {
//             console.log("Page rendered", canvas.toDataURL("image/png"));
//             var a = document.createElement("a");
//             a.href = canvas.toDataURL("image/png"); //Image Base64 Goes here
//             a.download = `${code}-qrcode.png`; //File name Here
//             a.click();
//           });
//         });
//       },
//       function (reason) {
//         // PDF loading error
//         console.error(reason);
//       }
//     );
//   });
// }
export function generatePdf(documentDefinition, action = "download") {
  //   import pdfMake from 'pdfmake/build/pdfmake';
  // import pdfFonts from 'pdfmake/build/vfs_fonts';
  switch (action) {
    case "open":
      pdfMake.createPdf(documentDefinition).open();
      break;
    case "print":
      pdfMake.createPdf(documentDefinition).print();
      break;
    case "download":
      pdfMake.createPdf(documentDefinition).download();
      break;

    default:
      pdfMake.createPdf(documentDefinition).open();
      break;
  }
}

export function titleCase(str) {
  return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export function getRideStatusLabel(status) {
  let label = "";
  if (status === "arrived") {
    label = "On Scene";
  } else {
    label = status;
  }
  return label;
}

export function capitalize(str) {
  let newString = '';
  if (str) {
    newString = str.charAt(0).toUpperCase() + str.slice(1);
  }
  return newString;
}

export function splitString(str, separator) {
  let newString = '';
  if (str && separator) {
    const arr = str.split(separator);
    newString = arr.join(' ');
  }
  return newString;
}

export function exportToCsv(filename, rows) {
  var processRow = function (row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null || row[j] ===undefined || row[j] === ""  ? 'n/a' : row[j].toString();
          if (row[j] instanceof Date) {
              innerValue = row[j].toLocaleString();
          };
          var result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0)
              result = '"' + result + '"';
          if (j > 0)
              finalVal += ',';
          finalVal += result;
      }
      // console.log(finalVal);
      return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
 
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      
  }
}
