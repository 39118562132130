import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.css"],
})
export class SigninComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  isPasswordShow = false;
  showToggler = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private sharedService: SharedDataService,
    private router: Router,
    private socket: SocketService
  ) {}

  ngOnInit() {
    window.addEventListener("keyup", this.initializeTimer);

    this.formGroup = this.fb.group({
      usernameOrEmail: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      type: ["companystaff"],
    });
  }

  initializeTimer = (e) => {
    console.log(e);
    if (
      e.altKey &&
      (e.key.toLowerCase() === "v" || e.key.toLowerCase() === "√")
    ) {
      this.showToggler = !this.showToggler;
    }
  };

  submit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    this.authService.signIn(this.formGroup.value).subscribe(
      (data) => {
        if (data) {
          this.authService.setToken(data.token);
          this.sharedService.saveUser(data.user);
          this.socket.connectSocket();
          this.router.navigateByUrl("/");
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
        }).then((data) => {});
      }
    );
  }

  toggleRole() {
    this.formGroup.patchValue({
      type: this.formGroup.value.type === "admin" ? "companystaff" : "admin",
    });
  }

  get form() {
    return this.formGroup.controls;
  }

  ngOnDestroy() {
    window.removeEventListener("keyup", this.initializeTimer);
  }
}
