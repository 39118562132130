import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-service/shared-data.service';
import Swal from 'sweetalert2';
import { VehicleService } from '../service/vehicle.service';
import { CompanyService } from '../../companies/service/company.service';

@Component({
  selector: 'app-vehicles-list',
  templateUrl: './vehicles-list.component.html',
  styleUrls: ['./vehicles-list.component.css']
})
export class VehiclesListComponent implements OnInit {
  search;
  vehicles;
  dropDownFilter = "active";
  user;
  companies;

  constructor(
    private vehicleService: VehicleService,
    private sharedDataService: SharedDataService,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.sharedDataService.getUser().subscribe((data) => {
      if (data) {
        console.log('Shared Data: ', data);
        this.user = data;
      }
    });
    this.getCompanies();
  }

  getCompanies() {
    const queryParams = {
      status: 'active'
    }

    this.companyService
      .getCompanies(queryParams)
      .subscribe(data => {
        this.getVehicles();
        if (data && data.length) {
          this.companies = data;
        }
      });
  }

  dropDownFilterChanged(event) {
    this.getVehicles();
  }

  getVehicles() {
    const queryParams = {
      status: this.dropDownFilter
    }
    this.vehicleService
      .getVehicles(queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.vehicles = data;
          this.vehicles = this.vehicles.map(vehicle => {
            const companies = vehicle.companies;
            const filteredCompanies = (companies && companies.length) ? this.companies.filter(c => companies.includes(c._id)) : [];
            return { ...vehicle, labels: filteredCompanies };
          });
          console.log('Vehicles: ', this.vehicles);
        }else{
          this.vehicles = [];
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }
}
