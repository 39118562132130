import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { PassengerService } from '../service/passenger.service';

@Component({
  selector: 'app-passenger-list',
  templateUrl: './passenger-list.component.html',
  styleUrls: ['./passenger-list.component.css']
})
export class PassengerListComponent implements OnInit {
  search;
  dropDownFilter = "active";
  passengers;

  constructor(
    private passengerService: PassengerService
  ) { }

  ngOnInit() {
    this.getPassengers();
  }

  getPassengers() {
    const queryParams = {
      status: this.dropDownFilter
    }
    this.passengerService
      .getPassengers(queryParams)
      .subscribe(data => {
        if (data) {
          this.passengers = data;
        }
      }, err => {
        Swal.fire({
          title: "Alert",
          html: 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      });
  }

  dropDownFilterChanged(event) {
    this.getPassengers();
  }
}
