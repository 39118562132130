import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
// import { generatePdf, pdfToIMG } from "src/app/utils/common-utils";
import { singleQRCodeImage } from "src/app/utils/report/qr-code-image";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { FleetService } from "../service/fleet.service";

@Component({
  selector: "app-fleets-list",
  templateUrl: "./fleets-list.component.html",
  styleUrls: ["./fleets-list.component.css"],
})
export class FleetsListComponent implements OnInit {
  search;
  imgModalPayload;
  selectedFleet;
  fleets;
  dropDownFilter = "active";
  
  constructor(    private ngxLoader: NgxUiLoaderService,private fleetService: FleetService) {}

  ngOnInit() {
    this.getFleets();
  }

  dropDownFilterChanged(event) {
    this.getFleets();
  }

  getFleets() {
    const queryParams = {
      status: this.dropDownFilter,
    };
    this.fleetService.getFleets(queryParams).subscribe((data) => {
      if (data && data.length) {
        this.fleets = data;
      } else {
        this.fleets = [];
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }

  onSelectedFleet(fleet) {
    if (fleet.driver) {
      this.unAssignFleet(fleet._id);
    } else {
      this.selectedFleet = fleet;
    }
  }
  closeDriverModal(driver) {
    if (driver) {
      const payload = { driver: driver._id };
      this.updateFleet(this.selectedFleet._id, payload, driver);
    } else {
      this.selectedFleet = null;
    }
  }

  updateFleet(id, payload, driver) {
    this.fleetService.saveFleet(id, payload).subscribe(
      (data) => {
        const fleetIndex = this.fleets.map((f) => f._id).indexOf(id);
        this.fleets[fleetIndex].driver = driver;
        this.selectedFleet = null;
      },
      (err) => {
        console.log('error', err);
        this.selectedFleet = null;
        Swal.fire({
          title: "Alert",
          html: err ? err : 'Operation failed',
          icon: "error",
          confirmButtonColor: '#F1A303'
        }).then((data) => {
        });
      }
    );
  }

  unAssignFleet(id) {
    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to unassign driver?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: '#F1A303',
      icon: "question",
    }).then((data) => {
      if (data.isConfirmed) {
        const payload = { driver: null };
        this.updateFleet(id, payload, null);
      }
    }, err => {
      Swal.fire({
        title: "Alert",
        html: 'Operation failed',
        icon: "error",
        confirmButtonColor: '#F1A303'
      }).then((data) => {
      });
    });
  }
  async downloadQR(code){
 
      // this.ngxLoader.start();
      // const detailsObject = await singleQRCodeImage(`${environment.BASE_QR_URL}${code}`).catch(
      //   data=>{
      //     this.ngxLoader.stop();
      //   }
      // );
      // console.log("data -----> ",JSON.stringify(detailsObject));
      //  pdfToIMG(code, detailsObject);
      // //  generatePdf(detailsObject);
      // this.ngxLoader.stop();
    }
  
}
