import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-driver-filters-modal',
  templateUrl: './driver-filters-modal.component.html',
  styleUrls: ['./driver-filters-modal.component.css']
})
export class DriverFiltersModalComponent implements OnInit {
  @Input() filters: any;
  @Output() onApply: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  close() {
  }

  applyFilters() {
    const filters = {};
    this.onApply.emit(filters);
  }

}
