import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerVisibilityService } from "ng-http-loader";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { generatePdf } from "src/app/utils/common-utils";
import { singleDriver } from "src/app/utils/report/driver-details";
import Swal from "sweetalert2";
import { FleetService } from "../../fleets/service/fleet.service";
import { DriverService } from "../service/driver.service";

@Component({
  selector: "app-driver-details",
  templateUrl: "./driver-details.component.html",
  styleUrls: ["./driver-details.component.css"],
})
export class DriverDetailsComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  driver;
  driverAdditionalInfo;
  driverId;
  imgModalPayload;
  driverFleetInfo;
  user;

  constructor(
    private fb: FormBuilder,
    private driverService: DriverService,
    private fleetService: FleetService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.driverId = this.activatedRoute.snapshot.paramMap.get("id");

    this.sharedDataService.getUser().subscribe((data) => {
      console.log("getUser ---> ", data);
      if (data) {
        this.user = data;
      } else {
        this.user = null;
      }
    });

    this.formGroup = this.fb.group({
      issues: ["", Validators.required],
      status: ["inactive"],
    });

    this.getDriverDetails();
    this.getDriverAdditionalInfo();
    this.getDriverFleetDetails();
  }

  getDriverDetails() {
    this.driverService.getDriverById(this.driverId).subscribe(
      (data) => {
        if (data) {
          this.driver = data;
          this.formGroup.patchValue({
            issues: data.issues,
          });
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => {});
      }
    );
  }

  getDriverAdditionalInfo() {
    this.driverService.fetchDriverDocuments(this.driverId).subscribe((data) => {
      if (data) {
        this.driverAdditionalInfo = data;
      }
    });
  }

  getDriverFleetDetails() {
    const queryParams = {
      driver: this.driverId,
    };

    this.fleetService.getDriverFleet(queryParams).subscribe(
      (data) => {
        if (data) {
          this.driverFleetInfo = data;
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => {});
      }
    );
  }

  markApprove() {
    const payload = {
      account: "approved",
      issues: "",
    };

    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to approve profile?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: "#F1A303",
    }).then((data) => {
      if (data.isConfirmed) {
        this.driverService
          .updateAccountStatus(this.driverId, payload)
          .subscribe(
            (data) => {
              this.router.navigateByUrl("/drivers");
            },
            (err) => {
              Swal.fire({
                title: "Alert",
                html: err,
                icon: "error",
              }).then((data) => {});
            }
          );
      }
    });
  }

  markIncomplete() {
    this.isSubmitted = true;

    console.log("Form :", this.formGroup.value);

    if (this.formGroup.invalid) {
      return;
    }

    console.log("Valid Form: ", this.formGroup.value);

    const payload = {
      account: "incomplete",
      issues: this.formGroup.controls.issues.value,
    };

    Swal.fire({
      title: "Alert",
      html: "Are you sure you want to mark the profile incomplete?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      icon: "question",
      confirmButtonColor: "#F1A303",
    }).then((data) => {
      if (data.isConfirmed) {
        this.driverService
          .updateAccountStatus(this.driverId, payload)
          .subscribe(
            (data) => {
              this.router.navigateByUrl("/drivers");
            },
            (err) => {
              Swal.fire({
                title: "Alert",
                html: err,
                icon: "error",
              }).then((data) => {});
            }
          );
      }
    });
  }

  get form() {
    return this.formGroup.controls;
  }


 async  onReportClick() {
    this.ngxLoader.start();
    const detailsObject = await singleDriver(this.driver,this.driverAdditionalInfo,this.driverFleetInfo).catch(
      data=>{
        this.ngxLoader.stop();
      }
    );
    console.log("data -----> ",JSON.stringify(detailsObject));
    generatePdf(detailsObject);
    this.ngxLoader.stop();
  }
}
