import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class FleetService {

  constructor(private http: HttpClient) { }

  getFleets(queryParams) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.SERVER_API_URL + urls.FLEETS, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getFleetsByLocations() {
    return this.http.get<any>(urls.SERVER_API_URL + urls.FLEETS_BY_LOCATION)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveFleet(fleetId, payload) {
    // const formData = setFormData(payload);

    if (fleetId) {
      // Edit
      return this.http.put<any>(urls.SERVER_API_URL + urls.FLEETS +`/${fleetId}`, payload)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      // Add
      return this.http.post<any>(urls.SERVER_API_URL + urls.FLEETS, payload)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  getFleetById(fleetId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.FLEETS + `/${fleetId}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  getFleetDetails(fleetId) {
    return this.http.get<any>(urls.SERVER_API_URL + urls.FLEETS + `/company/${fleetId}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  getDriverFleet(queryParams) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.SERVER_API_URL + urls.FLEETS + `/driver`, { params })
      .pipe(
        catchError(errorHandl)
      );
  }
}
