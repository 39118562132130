import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { downloadImage, downloadImage2 } from 'src/app/utils/network-utils';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.css']
})
export class ImageModalComponent implements OnInit {
  @Input() payload;
  @Output() onClose = new EventEmitter<string>();
  isImageLoaded = false;

  constructor() { }

  ngOnInit() {
    console.log('url',this.payload);
  }

  download() {
    if (this.payload && this.payload.url) {
      downloadImage2(this.payload.url, '');
    }
  }

  onImageLoad() {
    this.isImageLoaded = true;
  }
}
